interface AuthenticationData {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}

const SESSION_PREFIX = 'storageKeySessionPrefix';

export const storeAuthenticationData = (keySessionPrefix: string): void => {
  localStorage.setItem(SESSION_PREFIX, keySessionPrefix);
};

export const clearAuthenticationData = () => {
  localStorage.removeItem(SESSION_PREFIX);
};

export const retrieveAuthenticationData = (): AuthenticationData => {
  const keySessionPrefix = localStorage.getItem(SESSION_PREFIX);
  const idToken = localStorage.getItem(`${keySessionPrefix}.idToken`);
  const accessToken = localStorage.getItem(`${keySessionPrefix}.accessToken`);
  const refreshToken = localStorage.getItem(`${keySessionPrefix}.refreshToken`);

  return {
    idToken: idToken || '',
    accessToken: accessToken || '',
    refreshToken: refreshToken || ''
  };
};
