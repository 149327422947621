import { PaginatedListInterface } from '../utils/types/common';
import { Pagination } from '../utils/types/pagination';
import { UserInterface } from '../utils/types/user';
import ApiService from './api';

export default class BaseUsersService extends ApiService {
  async getBaseUsersPaginated(
    role: string,
    pagination: Pagination
  ): Promise<PaginatedListInterface<UserInterface>> {
    const { data } = await this.axios.get(role, {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  }

  getUser = async (role: string, id: string) => {
    const { data } = await this.axios.get(`${role}/${id}`);
    return data;
  };

  deleteUser = async (id: string, role: string) => {
    await this.axios.delete(`${role}/${id}`);
  };
}
