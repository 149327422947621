import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-square-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-square-up.svg';

const Grid = ({ rows, columns, loading }: any) => {
  return (
    <DataGrid
      loading={loading || false}
      rows={rows || []}
      columns={columns}
      disableRowSelectionOnClick
      pageSizeOptions={[10, 25, 50, 100]}
      getRowHeight={() => 'auto'}
      pagination={undefined}
      columnHeaderHeight={73}
      autoHeight={true}
      sx={style}
      slots={{
        columnSortedDescendingIcon: ArrowDownIcon,
        columnSortedAscendingIcon: ArrowUpIcon
      }}
    />
  );
};

export default Grid;

const style = {
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
  boxShadow: 0,
  borderRadius: 2,
  backgroundColor: '#FFFFFF',
  borderLeft: '24px solid #FFFFFF',
  borderRight: '24px solid #FFFFFF'
};
