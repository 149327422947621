import { FC } from 'react';
import Grid from '@mui/material/Grid';
import YmsLogo from '../../../assets/images/yms-logo.svg';
import './Header.scss';

const Header: FC<{}> = () => {
  return (
    <Grid className="header-wrapper">
      <img src={YmsLogo} alt="logo" />
    </Grid>
  );
};

export default Header;
