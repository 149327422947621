import Joi from 'joi';

export const documentSchema = Joi.object({
  name: Joi.string().required(),
  expiration_date: Joi.string().allow(null),
  storeable_asset_id: Joi.string().required(),
  vessel_id: Joi.string().required()
}).options({
  abortEarly: false
});
