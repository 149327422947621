import { FC } from 'react';
import ListAdmins from './ListAdmins';
// import ListSignUpRequests from './ListSignUpRequests'
import './Admins.scss';

const Admins: FC = () => {
  return (
    <>
      <ListAdmins />

      {/* <ListSignUpRequests /> */}
    </>
  );
};

export default Admins;
