import { FC } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/images/search-normal.svg';
import { IconButton, InputAdornment, TextField } from '@mui/material';

interface InputTextSearchArgs {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputTextSearch: FC<InputTextSearchArgs> = ({ value, placeholder, onChange }) => {
  const startAdornment = (
    <InputAdornment position="start">
      <IconButton edge="start">{<SearchIcon />}</IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      type="text"
      size="small"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      sx={style}
      InputProps={{ startAdornment }}
    />
  );
};

export default InputTextSearch;

const style = { width: '100%', minWidth: '290px' };
