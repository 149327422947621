import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GridHeader from '../../components/grid-header/GridHeader';
import { VesselContext } from '../../context/vesselContext';
import { defaultPagination } from '../../utils/constants/defaults';
import paths from '../../utils/constants/paths';
import { debounce } from '../../utils/lib';
import getVesselsGridColumns from './ListGridColumns';
import VesselForm from './VesselForm';
import './Vessels.scss';
import { Pagination } from '../../utils/types/pagination';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../components/mrt-data-table';

const defaultAttributesParams = ['id', 'active', 'hullNumber', 'name'];
const defaultOrderParams = ['createdAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  order: defaultOrderParams,
  join: ['owner']
};

const Vessels: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { listVesselsPaginated, state } = useContext(VesselContext);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [paginationParams, setPaginationParams] = useState<Pagination>(defaultPaginationParams);

  useEffect(() => {
    document.body.style.backgroundColor = '#E7E7E9';

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (state.create.done) listVesselsPaginated(paginationParams);
  }, [state.create]);

  const onEditVessel = (vessel: any) => {
    navigate({
      pathname: paths.VESSEL_SECTIONS.absolutePath,
      search: `id=${vessel.id}`
    });
  };

  const columns = getVesselsGridColumns(onEditVessel);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsFormOpen(isOpen);
  };

  useEffect(() => {
    listVessels(paginationParams, isFormOpen);
  }, [paginationParams, isFormOpen]);

  const handleSearch = debounce((inputVal) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      search: `name:${inputVal},hullNumber:${inputVal}`
    }));
  }, 500)

  const listVessels = useCallback((paginationParams: Pagination, isFormOpen: boolean) => {
    if (!isFormOpen) listVesselsPaginated(paginationParams);
  }, [paginationParams, isFormOpen]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  const handlerCloseForm = async () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <GridHeader
        searchValue={''}
        title={'Vessels list'}
        buttonText={'New vessel'}
        placeholder={'Search vessel'}
        onChangeSearch={handleSearch}
        onClick={toggleDrawer(!isFormOpen)}
      />

      <MrtDataTable
        isLoading={state?.list?.loading}
        isError={Boolean(state?.list?.error)}
        columns={columns}
        rows={state?.list?.data?.results}
        rowCount={state?.list?.data?.pagination?.total}
        initialPagination={{
          pageIndex: paginationParams.page - 1,
          pageSize: paginationParams.perPage
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
      />

      <SwipeableDrawer
        anchor={'bottom'}
        open={isFormOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={swipeableDrawerStyle}
      >
        {isFormOpen && <VesselForm onClickClose={handlerCloseForm} />}
      </SwipeableDrawer>
    </>
  );
};

export default Vessels;

const swipeableDrawerStyle = {
  maxHeight: 'calc(100% - 64px)',
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px 8px 0 0'
  }
};
