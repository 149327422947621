import moment from 'moment';
import NumberSlot from '../../components/number-slot/NumberSlot';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import { UserInterface } from '../../utils/types/user';
import IconButton from '@mui/material/IconButton';
import { MRT_ColumnDef } from 'material-react-table';
import { VesselInterface } from '../../utils/types/vessel';
import StatusSlot from '../../components/number-slot/StatusSlot';

type SlotColorsMap = {
  [key: string]: {
    backgroundSlot: string;
    backgroundValue: string;
  };
};

const listAdminsGridColumns = (
  onEditUser: (user: UserInterface) => void,
  onDeleteUser: (user: UserInterface) => void
): Array<MRT_ColumnDef<UserInterface>> => [
    {
      accessorKey: 'createdAt',
      header: 'Date',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'email',
      header: 'Email'
    },
    {
      accessorKey: 'phone',
      header: 'Phone'
    },
    {
      accessorKey: 'assignedVessels',
      header: 'Vessels Assigned',
      enableSorting: false,
      Cell: ({ cell }) => {
        const vessels = cell.getValue<VesselInterface[]>().length;

        return <NumberSlot value={`${vessels}`} />
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => {
        const statusesWithSlot = ['DISABLED'];
        const value = cell.getValue<string>();

        const slotColorsMap: SlotColorsMap = {
          DISABLED: {
            backgroundSlot: '#D4D9E6',
            backgroundValue: '#273F82'
          }
        };

        if (statusesWithSlot.includes(value)) {
          return (
            <StatusSlot
              value={value.replace('_', ' ')}
              backgroundSlot={slotColorsMap[value].backgroundSlot}
              backgroundValue={slotColorsMap[value].backgroundValue}
              fontSize="small"
            />
          );
        } else {
          return <></>;
        }
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditUser(cell.row.original)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDeleteUser(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default listAdminsGridColumns;
