import SystemInfoTemplate, {
  CreateSystemInfoTemplateDto,
  UpdateSystemInfoTemplateDto
} from '../../services/systemInfoTemplate';
import { Pagination } from '../../utils/types/pagination';
import SystemInfoTemplateActionsTypes from './types/systemInfoTemplate';
import { errorPayload } from './utils/errorPayload';

export const createSystemInfoTemplate = (dispatch: any) => {
  return async (dto: CreateSystemInfoTemplateDto) => {
    dispatch({ type: SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS });

    try {
      const systemInfoTemplateService = new SystemInfoTemplate();
      await systemInfoTemplateService.create(dto);

      dispatch({ type: SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_ERROR, payload });
    }
  };
};

export const listSystemInfoTemplates = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_IN_PROGRESS });

    try {
      const systemInfoTemplateService = new SystemInfoTemplate();
      const templates = await systemInfoTemplateService.list(pagination);

      dispatch({
        type: SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_SUCCESS,
        payload: templates
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_ERROR, payload });
    }
  };
};

export const viewSystemInfoTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_IN_PROGRESS });

    try {
      const systemInfoTemplateService = new SystemInfoTemplate();
      const template = await systemInfoTemplateService.view(id);

      dispatch({
        type: SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_SUCCESS,
        payload: template
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_ERROR, payload });
    }
  };
};

export const updateSystemInfoTemplate = (dispatch: any) => {
  return async (id: string, dto: UpdateSystemInfoTemplateDto) => {
    dispatch({ type: SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS });

    try {
      const systemInfoTemplateService = new SystemInfoTemplate();
      await systemInfoTemplateService.update(id, dto);

      dispatch({ type: SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_ERROR, payload });
    }
  };
};

export const deleteSystemInfoTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS });

    try {
      const systemInfoTemplateService = new SystemInfoTemplate();
      await systemInfoTemplateService.delete(id);

      dispatch({ type: SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_ERROR, payload });
    }
  };
};

export const resetSystemInfoTemplateState = (dispatch: any) => {
  return async () => {
    dispatch({ type: SystemInfoTemplateActionsTypes.RESET_STATE });
  };
};
