enum BlogsEntryActionTypes {
  CREATE_BLOG_ENTRY_IN_PROGRESS = 'CREATE_BLOG_ENTRY_IN_PROGRESS',
  CREATE_BLOG_ENTRY_SUCCESS = 'CREATE_BLOG_ENTRY_SUCCESS',
  CREATE_BLOG_ENTRY_ERROR = 'CREATE_BLOG_ENTRY_ERROR',

  LIST_BLOG_ENTRIES_IN_PROGRESS = 'LIST_BLOG_ENTRIES_IN_PROGRESS',
  LIST_BLOG_ENTRIES_SUCCESS = 'LIST_BLOG_ENTRIES_SUCCESS',
  LIST_BLOG_ENTRIES_ERROR = 'LIST_BLOG_ENTRIES_ERROR',

  VIEW_BLOG_ENTRY_IN_PROGRESS = 'VIEW_BLOG_ENTRY_IN_PROGRESS',
  VIEW_BLOG_ENTRY_SUCCESS = 'VIEW_BLOG_ENTRY_SUCCESS',
  VIEW_BLOG_ENTRY_ERROR = 'VIEW_BLOG_ENTRY_ERROR',

  UPDATE_BLOG_ENTRY_IN_PROGRESS = 'UPDATE_BLOG_ENTRY_IN_PROGRESS',
  UPDATE_BLOG_ENTRY_SUCCESS = 'UPDATE_BLOG_ENTRY_SUCCESS',
  UPDATE_BLOG_ENTRY_ERROR = 'UPDATE_BLOG_ENTRY_ERROR',

  DELETE_BLOG_ENTRY_IN_PROGRESS = 'DELETE_BLOG_ENTRY_IN_PROGRESS',
  DELETE_BLOG_ENTRY_SUCCESS = 'DELETE_BLOG_ENTRY_SUCCESS',
  DELETE_BLOG_ENTRY_ERROR = 'DELETE_BLOG_ENTRY_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default BlogsEntryActionTypes;
