import { useCallback, useContext, useEffect, useState } from "react";
import { defaultPagination } from "../../../../utils/constants/defaults";
import { CrewRequestContext } from "../../../../context/crewRequestContext";
import MrtDataTable from "../../../../components/mrt-data-table";
import { getGridColumns } from "./columns";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { Pagination } from "../../../../utils/types/pagination";
import ListGridTitle from "../ListGridTitle";

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: [],
  order: defaultOrderParams,
  perPage: 10,
};

type CancelledCrewRequestsTableProps = {
    vesselId: string;
}

export default function CancelledCrewRequestsTable(props: CancelledCrewRequestsTableProps) {
  const { listCancelledCrewRequests, state: crewRequestState } = useContext(CrewRequestContext);

  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${props.vesselId}`
  });

  const columns = getGridColumns()

  useEffect(() => {
    listCancelledCrewRequestsPaginated(paginationParams);
  }, [paginationParams]);

  const listCancelledCrewRequestsPaginated = useCallback((paginationParams: Pagination) => {
    listCancelledCrewRequests(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <ListGridTitle
        requestsNumber={crewRequestState?.list?.cancelled?.data?.pagination?.total || 0}
        state={'cancelled'}
      />

      <MrtDataTable
        isLoading={crewRequestState.list.cancelled.loading}
        isError={Boolean(crewRequestState.list.cancelled.error)}
        columns={columns}
        rows={crewRequestState?.list?.cancelled?.data?.results}
        rowCount={crewRequestState?.list?.cancelled?.data?.pagination?.total}
        initialPagination={{
          pageIndex: paginationParams.page - 1,
          pageSize: paginationParams.perPage
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
      />
    </>
  )
}
