enum CustomersActionsTypes {
  FETCH_CUSTOMERS_IN_PROGRESS = 'FETCH_CUSTOMERS_IN_PROGRESS',
  FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR',

  LIST_CUSTOMER_SIGN_UP_REQUESTS_IN_PROGRESS = 'LIST_CUSTOMER_SIGN_UP_REQUESTS_IN_PROGRESS',
  LIST_CUSTOMER_SIGN_UP_REQUESTS_SUCCESS = 'LIST_CUSTOMER_SIGN_UP_REQUESTS_SUCCESS',
  LIST_CUSTOMER_SIGN_UP_REQUESTS_ERROR = 'LIST_CUSTOMER_SIGN_UP_REQUESTS_ERROR',

  FETCH_CUSTOMER_IN_PROGRESS = 'FETCH_CUSTOMER_IN_PROGRESS',
  FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS',
  FETCH_CUSTOMER_ERROR = 'FETCH_CUSTOMER_ERROR',

  DELETE_CUSTOMER_IN_PROGRESS = 'DELETE_CUSTOMER_IN_PROGRESS',
  DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR',

  CREATE_CUSTOMER_IN_PROGRESS = 'CREATE_CUSTOMER_IN_PROGRESS',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR',

  APPROVE_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS = 'APPROVE_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS',
  APPROVE_CUSTOMER_SIGN_UP_REQUEST_SUCCESS = 'APPROVE_CUSTOMER_SIGN_UP_REQUEST_SUCCESS',
  APPROVE_CUSTOMER_SIGN_UP_REQUEST_ERROR = 'APPROVE_CUSTOMER_SIGN_UP_REQUEST_ERROR',

  REJECT_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS = 'REJECT_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS',
  REJECT_CUSTOMER_SIGN_UP_REQUEST_SUCCESS = 'REJECT_CUSTOMER_SIGN_UP_REQUEST_SUCCESS',
  REJECT_CUSTOMER_SIGN_UP_REQUEST_ERROR = 'REJECT_CUSTOMER_SIGN_UP_REQUEST_ERROR',

  EDIT_CUSTOMER_IN_PROGRESS = 'EDIT_CUSTOMER_IN_PROGRESS',
  EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_ERROR = 'EDIT_CUSTOMER_ERROR',

  EDIT_CUSTOMER_PASSWORD_IN_PROGRESS = 'EDIT_CUSTOMER_PASSWORD_IN_PROGRESS',
  EDIT_CUSTOMER_PASSWORD_SUCCESS = 'EDIT_CUSTOMER_PASSWORD_SUCCESS',
  EDIT_CUSTOMER_PASSWORD_ERROR = 'EDIT_CUSTOMER_PASSWORD_ERROR',

  RESET_STATE_ERROR = 'RESET_STATE_ERROR'
}

export default CustomersActionsTypes;
