import { ChangeEvent, FC, useState } from 'react';
import { Button, Grid } from '@mui/material';
import InputTextSearch from '../form/InputTextSearch';
import './GridHeader.scss';

type GridHeaderProps = {
  searchValue: string;
  title: string;
  buttonText: string;
  placeholder: string;
  onChangeSearch?: (value: string) => void;
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const GridHeader: FC<GridHeaderProps> = ({
  searchValue: inputValue,
  title,
  buttonText,
  placeholder,
  onChangeSearch,
  onClick
}) => {
  const [searchValue, setSearchValue] = useState(inputValue);
  const handleInputTextSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChangeSearch && onChangeSearch(e.target.value);
  };

  const headerTitle = (
    <Grid item xs={9} sm={10} md={2} lg={3} xl={3.3} marginBottom={'15px'}>
      <span className="table-title">{title}</span>
    </Grid>
  );

  const actions = (
    <>
      <Grid item xs={12} sm={12} md={1} lg={2.2} xl={3.5} />
      <Grid item xs={12} sm={12} md={5.2} lg={3.7} xl={2.5} marginBottom={'15px'}>
        <InputTextSearch
          value={searchValue}
          placeholder={placeholder}
          onChange={handleInputTextSearchChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2.7} lg={1.8} xl={1.3}>
        <Button variant="contained" type="button" onClick={onClick} style={buttonStyle}>
          {buttonText}
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'self-start'}
      padding={'41px 0 36px 0'}
    >
      {headerTitle}
      {actions}
    </Grid>
  );
};

export default GridHeader;

const buttonStyle = { width: '100%' };
