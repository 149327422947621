import { UserInterface } from '../types/user';

export const customerAdapter = (user: any): UserInterface => {
  return {
    id: user.id as string,
    name: user.name as string,
    lastName: user.lastName as string,
    email: user.email as string,
    phone: user.phone as string,
    status: user.status,
    createdAt: user.createdAt,
    ownedVessels: []
  };
};

export const adminAdapter = (user: any): UserInterface => {
  return {
    id: user.id as string,
    name: user.name as string,
    lastName: user.lastName as string,
    email: user.email as string,
    phone: user.phone as string,
    status: user.status,
    createdAt: user.createdAt,
    assignedVessels: user.vessels
  };
};
