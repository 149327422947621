import { Grid } from '@mui/material';
import Box from '@mui/system/Box';
import { forwardRef, useState } from 'react';
import {
  StorableAssetInterface,
  StorableAssetType,
  StorableAssetVisibility
} from '../../utils/types/storableAsset';
import FileUploader from '../fileUploader/FileUploader';
import { InputStorableAssetUpload } from './InputStorableAssetUpload';

interface InputStorableAssetMultipleProps {
  label: string;
  name: string;
  value: string | null;
  vesselId: string;
  assetType: StorableAssetType;
  maxSelectedFiles?: number;
  maxFileSizeInBytes?: number;
  visibility: StorableAssetVisibility;
  validationErrors?: string;
  automaticUpload?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadSuccess?: (storableAsset: StorableAssetInterface) => void;
}

const InputStorableAssetMultiple = forwardRef((props: InputStorableAssetMultipleProps, ref) => {
  const [files, setFiles] = useState<File[]>([]);

  const getAllowedFileTypes = (assetType: StorableAssetType) => {
    const imageTypes = 'image/*';
    const documentTypes = '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt';
    const mediaTypes =
      '.mp4, .mov, .avi, .wmv, .mp3, .wav, .aac, .flac, .ogg, .wma, .m4a, .m4v, .mkv, .webm';

    switch (assetType) {
      case 'vessel-picture':
        return imageTypes;
      case 'documents':
        return documentTypes;
      case 'media':
        return `${imageTypes}, ${mediaTypes}`;
      case 'service-records':
        return documentTypes;
      case 'blog-image':
        return imageTypes;
      default:
        return '*/*';
    }
  };

  const handleChange = (selectedFiles: FileList) => {
    const files = Array.from(selectedFiles);

    setFiles(files);
  };

  const handleDelete = () => {
    setFiles([]);
  };

  return (
    <>
      <FileUploader
        title={props.label}
        accept={getAllowedFileTypes(props.assetType)}
        maxFileSizeInBytes={props.maxFileSizeInBytes}
        validationErrors={props.validationErrors}
        multiple={true}
        maxSelectedFiles={props.maxSelectedFiles}
        onChange={handleChange}
        onDelete={handleDelete}
      />

      <Grid container sx={{ marginBottom: '11px' }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {files.map((file, index) => (
              <InputStorableAssetUpload
                key={index}
                vesselId={props.vesselId}
                file={file}
                assetType={props.assetType}
                visibility={props.visibility}
                onUploadSuccess={(storableAsset: StorableAssetInterface) => {
                  props.onUploadSuccess && props.onUploadSuccess(storableAsset);
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default InputStorableAssetMultiple;
