import { ErrorInterface, PaginatedListInterface } from '../types/common';
import { PopUpArgs } from '../types/popUp';

export const errorInitialState: ErrorInterface = {
  code: '',
  text: ''
};

export const popUpArgsInitialValues: PopUpArgs = {
  variant: 'success',
  title: '',
  description: ''
};

export const getInitialPaginationState = <T>(): PaginatedListInterface<T> => ({
  results: [],
  pagination: {
    total: 0,
    results: {
      page: 0,
      from: 0,
      to: 0
    }
  }
})