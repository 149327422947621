import { FC, useContext, useEffect } from 'react';
import { VesselInterface } from '../../utils/types/vessel';
import { VesselContext } from '../../context/vesselContext';
import InputTags, { InputTagsArgs } from '../form/InputTags';
import { defaultPagination } from '../../utils/constants/defaults';

type Props = {
  title: string;
  value: VesselInterface[];
  onChange: (selected: VesselInterface[]) => void;
};

const paginationParams = {
  ...defaultPagination,
  attributes: ['id', 'active', 'hullNumber', 'name'],
  join: [''],
  perPage: 50,
};

const VesselsTagsInput: FC<Props> = ({ title, onChange, value }) => {
  const { listVesselsPaginated, state } = useContext(VesselContext);

  useEffect(() => {
    listVesselsPaginated(paginationParams);
  }, []);

  const inputTagsArgs: InputTagsArgs<VesselInterface> = {
    title,
    options:
      state?.list?.data?.results.map((r) => ({
        id: r.id,
        label: r.name,
        value: r.id,
        objectRef: r
      })) || [],
    value: value ? value.map((r) => ({ id: r.id, label: r.name, value: r.id, objectRef: r })) : [],
    onChange: (selected) => {
      onChange(selected.map((s) => s.objectRef));
    }
  };

  return <InputTags {...inputTagsArgs} />;
};

export default VesselsTagsInput;
