import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SystemInfoIcon } from '../../../assets/images/system-info-wrapper.svg';
import FieldsFactory from '../../../components/fieldsFactory/FieldsFactory';
import TitleGrid from '../../../components/grid/TitleGrid';
import ServiceActions from '../../../components/service-actions/ServiceActions';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { systemInfoInitialState } from '../../../context/reducers/systemInfoReducer';
import { SystemInfoContext } from '../../../context/systemInfoContext';
import paths from '../../../utils/constants/paths';
import { SystemInfoInterface, SystemInfoTemplateItem } from '../../../utils/types/systemInfo';
import TemplateBox from '../../form-templates/TemplateBox';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { SystemInfoTemplateContext } from '../../../context/systemInfoTemplateContext';
import { VesselContext } from '../../../context/vesselContext';

type onChangeTypes =
  | ChangeEvent<HTMLInputElement>
  | ChangeEventHandler<HTMLInputElement>
  | string
  | null
  | undefined;

const SystemInfoEdit: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const {
    viewSystemInfoTemplate,
    resetSystemInfoTemplateState,
    state: systemInfoTemplateState
  } = useContext(SystemInfoTemplateContext);
  const {
    createSystemInfo,
    viewSystemInfo,
    updateSystemInfo,
    resetSystemInfoState,
    state: systemInfoState
  } = useContext(SystemInfoContext);
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { showAlert } = useContext(NotificationContext);

  const [systemInfoId] = useState<string | null>(query.get('id') || '');
  const [systemInfoTemplateId] = useState<string | null>(query.get('systemInfoTemplateId') || '');
  const [vesselId] = useState<string | null>(query.get('vesselId') || '');
  const [systemInfo, setSystemInfo] = useState<SystemInfoInterface>(systemInfoInitialState);
  const [template, setTemplate] = useState<SystemInfoTemplateItem[]>([]);

  useEffect(() => {
    resetSystemInfoTemplateState();
    resetSystemInfoState();

    if (systemInfoId) viewSystemInfo(systemInfoId);
    if (!vesselState.view.data?.id && vesselId) viewVessel(vesselId);
    if (systemInfoTemplateId) viewSystemInfoTemplate(systemInfoTemplateId);
  }, []);

  useEffect(() => {
    if (systemInfoTemplateState.view.data?.template) {
      const parsedTemplate = JSON.parse(systemInfoTemplateState.view.data?.template || '{}');

      setTemplate(parsedTemplate);
    } else {
      setTemplate([]);
    }
  }, [systemInfoTemplateState.view.data]);

  useEffect(() => {
    if (systemInfoState.view.data?.template) {
      const parsedTemplate = JSON.parse(systemInfoState.view.data?.template || '{}');

      setSystemInfo(systemInfoState.view.data);
      setTemplate(parsedTemplate);
    } else {
      setSystemInfo(systemInfoInitialState);
      setTemplate([]);
    }
  }, [systemInfoState.view.data]);

  useEffect(() => {
    if (systemInfoState.create.done) {
      showAlert({
        variant: 'success',
        title: 'System Info',
        description: 'System Info created!'
      });

      resetSystemInfoState();
      navigate({
        pathname: paths.VESSEL_SYSTEM_INFO_LIST.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (systemInfoState.create.error) {
      showAlert({
        variant: 'error',
        title: 'System Info',
        description: "System Info couldn't be created!"
      });
    }
  }, [systemInfoState.create]);

  useEffect(() => {
    if (systemInfoState.update.done) {
      showAlert({
        variant: 'success',
        title: 'System Info',
        description: 'System Info updated!'
      });

      resetSystemInfoState();
      navigate({
        pathname: paths.VESSEL_SYSTEM_INFO_LIST.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (systemInfoState.update.error) {
      showAlert({
        variant: 'error',
        title: 'System Info',
        description: "System Info couldn't be updated!"
      });
    }
  }, [systemInfoState.update]);

  const changed = (e: onChangeTypes, label: string) => {
    const tempNewSystemInfoDetails = [...template];
    const indexField = template.findIndex((system) => system.label === label);
    let field = tempNewSystemInfoDetails[indexField];

    switch (field?.field.type) {
      case 'text':
        field.field.value = (e as ChangeEvent<HTMLInputElement>).target.value;
        tempNewSystemInfoDetails[indexField] = field;
        setTemplate(tempNewSystemInfoDetails);
        return;
      case 'checkBox':
        field.field.value = (e as ChangeEvent<HTMLInputElement>).target.checked;
        tempNewSystemInfoDetails[indexField] = field;
        setTemplate(tempNewSystemInfoDetails);
        return;
    }
  };

  const handleSubmit = () => {
    if (systemInfoTemplateId)
      createSystemInfo({
        name: systemInfoTemplateState.view.data?.name || '',
        template: JSON.stringify(template),
        vessel_id: vesselId || ''
      });

    if (systemInfoId)
      updateSystemInfo(systemInfoId, {
        name: systemInfo.name,
        template: JSON.stringify(template),
        vessel_id: systemInfo.vessel_id
      });
  };

  const renderDetailsList = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={1}
      >
        {template.map((system, i) => {
          return (
            <Grid item key={i} xs={12} sm={12} md={6} lg={4} xl={3}>
              <FieldsFactory
                type={system.field.type}
                name={system.label}
                disabled={false}
                rest={{
                  onChange: (e: onChangeTypes) => changed(e, system.label),
                  value: system.field.value
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || ''}
            middle={'System Info'}
            current={systemInfoState.view.data?.name || ''}
          />
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={gridContainerStyle}>
        <TemplateBox minHeight={'calc(100vh - 240px)'} paperStyle={paperStyle}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '1100px',
              height: '100%',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div className="system-details-top-container">
                <TitleGrid
                  icon={<SystemInfoIcon className="icon-title" />}
                  title={systemInfoState.view.data?.name || ''}
                />
              </div>
              {renderDetailsList()}
            </div>
            <ServiceActions
              onSave={handleSubmit}
              disabled={systemInfoState.create.loading || systemInfoState.update.loading}
            />
          </Box>
        </TemplateBox>
      </Grid>
    </>
  );
};

export default SystemInfoEdit;

const gridContainerStyle = { marginTop: '71px', marginBottom: '49px' };
const paperStyle = {};
