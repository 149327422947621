import SystemInfoActionsTypes from '../../context/actions/types/systemInfo';
import {
  ActionInterface,
  ActionListInterface,
  ActionViewInterface,
  ErrorInterface,
  PaginatedListInterface
} from './common';

export type SystemInfoFieldsTypes = 'checkBox' | 'text';

export const getSystemInfoFieldsType = () => ['text', 'checkBox'];

export interface SystemInfoInterface {
  id: string;
  name: string;
  template: string;
  state: string;
  vessel_id: string;
  created_by: string;
  updated_by: string;
  createdAt: string;
  updatedAt: string;
}

export interface SystemInfoStateInterface {
  create: ActionInterface;
  list: ActionInterface & ActionListInterface<SystemInfoInterface>;
  view: ActionInterface & ActionViewInterface<SystemInfoInterface>;
  update: ActionInterface;
  delete: ActionInterface;
  complete: ActionInterface;
}

export interface SystemInfoTemplateItem {
  label: string;
  field: {
    type: SystemInfoFieldsTypes;
    value: boolean | string;
  };
}

export type SystemInfoAction = {
  type: SystemInfoActionsTypes;
  payload: PaginatedListInterface<SystemInfoInterface> | SystemInfoInterface | ErrorInterface;
};
