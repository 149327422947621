import { jwtDecode } from 'jwt-decode';
import { AuthUserInterface } from '../context/interfaces/AuthUserInterface';
import { retrieveAuthenticationData } from './storage';
import { CognitoUserJwtPayload } from '../utils/types/cognito';

export default class SessionService {
  static isAuthenticated(): boolean {
    const { idToken } = retrieveAuthenticationData();

    return !!idToken;
  }

  static decodeJwt(jwtToken: string): AuthUserInterface {
    const decodedToken: CognitoUserJwtPayload = jwtDecode(jwtToken);

    return {
      firstName: decodedToken.name,
      lastName: decodedToken.family_name,
      email: decodedToken.email,
      phoneNumber: decodedToken.phone_number,
      roles: decodedToken['cognito:groups']
    };
  }
}
