import { getInitialPaginationState } from '../../utils/constants/states';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  UpcomingServiceInterface,
  UpcomingServicesAction,
  UpcomingServicesItem,
  UpcomingServicesStateInterface
} from '../../utils/types/upcomingService';
import UpcomingServicesTypes from '../actions/types/upcomingService';

const initialPaginationState = getInitialPaginationState<UpcomingServiceInterface>()

export const upcomingServiceItemInitialState: UpcomingServicesItem = {
  field: {
    type: 'text',
    value: ''
  },
  label: ''
};

export const upcomingServiceInitialState: UpcomingServiceInterface = {
  id: '',
  service_date: '',
  reminder_date: '',
  reminder_title: '',
  template: '',
  state: '',
  vessel_id: '',
  created_by: '',
  updated_by: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: UpcomingServicesStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: upcomingServiceInitialState
  },
  delete: actionInitialState,
  update: actionInitialState,
  complete: actionInitialState
};

export const reducer = (
  state = initialState,
  action: UpcomingServicesAction
): UpcomingServicesStateInterface => {
  switch (action.type) {
    case UpcomingServicesTypes.CREATE_UPCOMING_SERVICE_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UpcomingServicesTypes.CREATE_UPCOMING_SERVICE_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UpcomingServicesTypes.CREATE_UPCOMING_SERVICE_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UpcomingServicesTypes.LIST_UPCOMING_SERVICES_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UpcomingServicesTypes.LIST_UPCOMING_SERVICES_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<UpcomingServiceInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UpcomingServicesTypes.LIST_UPCOMING_SERVICES_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UpcomingServicesTypes.VIEW_UPCOMING_SERVICE_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UpcomingServicesTypes.VIEW_UPCOMING_SERVICE_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UpcomingServicesTypes.VIEW_UPCOMING_SERVICE_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UpcomingServicesTypes.UPDATE_UPCOMING_SERVICE_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UpcomingServicesTypes.UPDATE_UPCOMING_SERVICE_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UpcomingServicesTypes.UPDATE_UPCOMING_SERVICE_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UpcomingServicesTypes.DELETE_UPCOMING_SERVICE_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UpcomingServicesTypes.DELETE_UPCOMING_SERVICE_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UpcomingServicesTypes.DELETE_UPCOMING_SERVICE_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UpcomingServicesTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
