import { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/images/search-normal.svg';
import { CustomerContext } from '../../context/customerContext';
import { defaultPagination } from '../../utils/constants/defaults';

export type SelectedOption = { label: string; value: string };

interface AutocompleteArgs {
  name: string;
  value: string | null;
  onChange: (event: SyntheticEvent, value: SelectedOption | null) => void;
  errorSummary: boolean;
}

const paginationParams = {
  ...defaultPagination,
  attributes: ['id', 'name', 'lastName', 'email', 'phone', 'createdAt'],
  perPage: 100,
};

const CustomerInputAutoComplete: FC<AutocompleteArgs> = (props: AutocompleteArgs) => {
  const { state, getCustomersPaginated } = useContext(CustomerContext);
  const [options, setOptions] = useState<SelectedOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectedOption | undefined>(undefined);

  useEffect(() => {
    getCustomersPaginated({
      ...paginationParams,
      perPage: 100
    });
  }, []);

  useEffect(() => {
    if (props.value) {
      const selected = options.find((option) => option.value === props.value);

      if (selected) {
        setSelectedOption(selected);
      } else {
        setSelectedOption(undefined);
      }
    } else {
      setSelectedOption(undefined);
    }
  }, [props.value, options]);

  useEffect(() => {
    let newResults: SelectedOption[] = [];

    state.paginated.results.map((customer) =>
      newResults.push({
        label: `${customer.name} ${customer.lastName} <${customer.email}>`,
        value: customer.id
      })
    );

    setOptions(newResults);
  }, [state.paginated]);

  const handleAutocompleteChange = (_event: React.SyntheticEvent, value: SelectedOption | null) => {
    setSelectedOption(value ? value : undefined);
    props.onChange(_event, value);
  };

  return (
    <FormControl style={{ width: '100%' }} error={props.errorSummary}>
      <FormLabel component="label" className={'regular'}>
        Owner
      </FormLabel>
      <Autocomplete
        disablePortal
        options={options}
        value={selectedOption || null}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleAutocompleteChange}
        popupIcon={<SearchIcon />}
        renderInput={(params) => (
          <TextField
            error={props.errorSummary}
            {...params}
            type="text"
            value={selectedOption?.label || ''}
          />
        )}
      />
      {props.errorSummary && (
        <FormHelperText id="my-helper-text">Owner is not allowed to be empty</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomerInputAutoComplete;
