import { isDocumentType, isImageType, isMediaType } from '../../utils/lib';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CardContent } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useEffect, useState } from 'react';
import StorableAssetService from '../../services/storableAsset';
import { CreatedStorableAssetInterface, StorableAssetInterface, StorableAssetType, StorableAssetVisibility } from '../../utils/types/storableAsset';

function renderDocumentPreview(file: File) {
  return (
    <Card variant="outlined">
      <CardContent sx={{ width: '200px', height: '200px' }}>
        <Typography variant="caption" component="p" textAlign="left">
          <b>File type:</b> {file.type}
        </Typography>
        <Typography variant="caption" component="p" textAlign="left">
          <b>File size (KB | MB):</b> {(file.size / 1000).toFixed(2)} |{' '}
          {(file.size / 1000000).toFixed(2)}
        </Typography>
      </CardContent>
    </Card>
  );
}

function renderImagePreview(fileUrl: string) {
  return (
    <Card variant="outlined">
      <CardMedia sx={{ width: '200px', height: '200px' }} component="img" image={fileUrl} />
    </Card>
  );
}

function renderMediaPreview(fileUrl: string) {
  return (
    <Card variant="outlined">
      <CardMedia
        sx={{ width: '200px', height: '200px' }}
        component="video"
        controls={false}
        src={fileUrl}
      />
    </Card>
  );
}

function renderUploadStatus(text: string, icon: React.ReactNode) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        alignContent: 'center'
      }}>
      <Typography variant="caption" component="p" textAlign="center" fontSize={20} color={'white'}>
        {text} <br />
        {icon}
      </Typography>
    </Box>
  );
}

interface InputStorableAssetUploadProps {
  vesselId: string;
  file: File;
  assetType: StorableAssetType;
  visibility: StorableAssetVisibility;
  onUploadSuccess: (storeableAsset: StorableAssetInterface) => void;
}

const storeableAssetService = new StorableAssetService();

export const InputStorableAssetUpload = (props: InputStorableAssetUploadProps) => {
  const fileUrl = URL.createObjectURL(props.file);
  const isImage = isImageType(props.file.type);
  const isDocument = isDocumentType(props.file.type);
  const isMedia = isMediaType(props.file.type);

  const [createdStoreableAsset, setCreatedStoreableAsset] = useState<CreatedStorableAssetInterface>();
  const [isCreatingStorableAsset, setIsCreatingStorableAsset] = useState(false);
  const [isCreatingStorableAssetError, setIsCreatingStorableAssetError] = useState(false);
  const [isCreatingStorableAssetSuccess, setIsCreatingStorableAssetSuccess] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [isUploadError, setUploadError] = useState(false);
  const [isUploadSuccess, setUploadSuccess] = useState(false);

  const uploadToSignedUrl = () => {
    if (!createdStoreableAsset) return;

    setIsUploading(true);
    setUploadError(false);
    setUploadSuccess(false);

    storeableAssetService
      .uploadToSignedUrl(createdStoreableAsset.uploadUrl, props.file)
      .then((data) => {
        props.onUploadSuccess(createdStoreableAsset.storeableAsset);

        setIsUploading(false);
        setUploadError(false);
        setUploadSuccess(true);
      })
      .catch((error) => {
        console.error('Upload error:', error);

        setIsUploading(false);
        setUploadError(true);
        setUploadSuccess(false);
      });
  };

  const createStorableAsset = () => {
    setIsCreatingStorableAsset(true);
    setIsCreatingStorableAssetError(false);
    setIsCreatingStorableAssetSuccess(false);

    storeableAssetService
      .create({
        vesselId: props.vesselId,
        visibility: props.visibility,
        assetType: props.assetType,
        extension: props.file.name.split('.').pop() || '',
        mimeType: props.file.type,
        size: props.file.size
      })
      .then((data) => {
        setCreatedStoreableAsset(data);
        setIsCreatingStorableAsset(false);
        setIsCreatingStorableAssetError(false);
        setIsCreatingStorableAssetSuccess(true);
      })
      .catch((error) => {
        console.error('Create storable asset error:', error);

        setIsCreatingStorableAsset(false);
        setIsCreatingStorableAssetError(true);
        setIsCreatingStorableAssetSuccess(false);
      });
  };

  useEffect(() => {
    uploadToSignedUrl();
  }, [createdStoreableAsset]);

  useEffect(() => {
    createStorableAsset();
  }, []);

  let renderedPreview = <></>;

  switch (true) {
    case isDocument:
      renderedPreview = renderDocumentPreview(props.file);
      break;
    case isImage:
      renderedPreview = renderImagePreview(fileUrl);
      break;
    case isMedia:
      renderedPreview = renderMediaPreview(fileUrl);
      break;
    default:
      return <></>;
  }

  return (
    <Box sx={{ margin: '5px', position: 'relative' }}>
      {isCreatingStorableAsset && renderUploadStatus('Requesting upload url...', <CloudUploadIcon />)}
      {isCreatingStorableAssetError && renderUploadStatus('Failed requesting upload url', <ErrorIcon />)}

      {isUploading && renderUploadStatus('Uploading...', <CloudUploadIcon />)}
      {isUploadError && renderUploadStatus('Upload failed', <ErrorIcon />)}
      {isUploadSuccess && renderUploadStatus('Upload successful', <CloudDoneIcon />)}

      {renderedPreview}
    </Box>
  );
};
