import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TitlePage from '../../../components/title-page/TitlePage';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import { ReactComponent as TankLevelsIcon } from '../../../assets/images/tank-levels-wrapper.svg';
import TemplateBox from '../../form-templates/TemplateBox';
import paths from '../../../utils/constants/paths';
import { TankLevelInterface } from '../../../utils/types/tankLevel';
import AlertDialog from '../../../components/dialog/AlertDialog';
import { tankLevelInitialState } from '../../../context/reducers/tankLevelReducer';
import { TankLevelContext } from '../../../context/tankLevelContext';
import { TankLevelTemplateContext } from '../../../context/tankLevelTemplateContext';
import VesselEmptyListService from '../VesselEmptyListService';
import getTankLevelGridColumns from './ListGridColumns';
import TitleGrid from '../../../components/grid/TitleGrid';
import LoadTemplateDialog from '../../../components/dialog/LoadTemplateDialog';
import { NotificationContext } from '../../../context/notificationContext';
import { defaultPagination } from '../../../utils/constants/defaults';
import { Pagination } from '../../../utils/types/pagination';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { VesselContext } from '../../../context/vesselContext';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';

const initialValues = {
  ...tankLevelInitialState
};

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: ['creator', 'operator'],
  order: defaultOrderParams
};

const TankLevelsList: FC = () => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { deleteTankLevel, listTankLevels, state: tankLevelState } = useContext(TankLevelContext);
  const { listTankLevelTemplates, state: tankLevelTemplateState } = useContext(TankLevelTemplateContext);
  const { showAlert } = useContext(NotificationContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLoadTemplateDialog, setOpenLoadTemplateDialog] = useState(false);
  const [selectedTankLevel, setSelectedTankLevel] = useState<TankLevelInterface>(initialValues);
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${vesselId}`
  });

  useEffect(() => {
    listTankLevelTemplates({
      ...defaultPagination,
      perPage: 100
    });

    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (tankLevelState.delete.done) {
      listTankLevelsPaginated(paginationParams);

      showAlert({
        variant: 'success',
        title: 'Tank Level',
        description: 'Tank Level deleted!'
      });
    } else if (tankLevelState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Tank Level',
        description: "Tank Level couldn't be deleted!"
      });
    }
  }, [tankLevelState.delete]);

  const onDeleteTankLevel = (tankLevel: TankLevelInterface) => {
    setSelectedTankLevel(tankLevel);
    setOpenDeleteDialog(true);
  };

  const onEditTankLevel = (tankLevel: any) => {
    navigate({
      pathname: paths.VESSEL_TANK_LEVEL_EDIT.absolutePath,
      search: `id=${tankLevel.id}&vesselId=${vesselId}`
    });
  };

  const handleClose = (_event: any, reason: 'escapeKeyDown' | 'backdropClick') => {
    if (reason === 'backdropClick') setOpenLoadTemplateDialog(false);
  };

  const columns = getTankLevelGridColumns(onEditTankLevel, onDeleteTankLevel);

  useEffect(() => {
    listTankLevelsPaginated(paginationParams);
  }, [paginationParams]);

  const listTankLevelsPaginated = useCallback((paginationParams: Pagination) => {
    listTankLevels(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  const handleSelectTemplate = (template: TankLevelInterface) => {
    setOpenLoadTemplateDialog(false);
    navigate({
      pathname: paths.VESSEL_TANK_LEVEL_EDIT.absolutePath,
      search: `tankLevelTemplateId=${template.id}&vesselId=${vesselId}`
    });
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    deleteTankLevel(selectedTankLevel.id);
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb previous={vesselState.view.data?.name || 'Vessel'} current={'Tank Levels'} />
        }
        rightContainer={
          <Button variant="outlined" onClick={() => setOpenLoadTemplateDialog(true)}>
            Load from template
          </Button>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {tankLevelState?.list?.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'Tank Levels'} />
          ) : (
            <>
              <TitleGrid icon={<TankLevelsIcon className="icon-title" />} title={'Tank Levels'} />

              <MrtDataTable
                isLoading={tankLevelState.list.loading}
                isError={Boolean(tankLevelState.list.error)}
                columns={columns}
                rows={tankLevelState?.list?.data?.results}
                rowCount={tankLevelState?.list?.data?.pagination?.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
      {openDeleteDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to delete this record? This action can not be undone
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={handleDelete}
          disableButtons={tankLevelState.list.loading || tankLevelState.delete.loading}
        />
      )}
      {openLoadTemplateDialog && (
        <LoadTemplateDialog
          list={tankLevelTemplateState?.list.data?.results || []}
          open={openLoadTemplateDialog}
          createTemplatePath={paths.TEMPLATE_TANK_LEVELS_FORM.absolutePath}
          onClose={(e, reason) => handleClose(e, reason)}
          onLoad={handleSelectTemplate}
        />
      )}
    </>
  );
};

export default TankLevelsList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
