import AdminsService from '../../services/admins';
import { adminAdapter } from '../../utils/adapters/userAdapter';
import { CreateAdminDto, UpdateAdminDto } from '../../utils/types/admin';
import AdminActionsTypes from './types/admin';
import { errorPayload } from './utils/errorPayload';
import { showNotificationType } from '../../utils/types/notification';
import { Pagination } from '../../utils/types/pagination';
import { SubmittedPasswordForm } from '../../utils/types/user';

export const getAdminsPaginated = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (pagination: Pagination) => {
    dispatch({ type: AdminActionsTypes.FETCH_ADMINS_IN_PROGRESS });

    try {
      const paginatedUsers = await adminsService.list(pagination);

      dispatch({ type: AdminActionsTypes.FETCH_ADMINS_SUCCESS, payload: paginatedUsers });
    } catch (error: any) {
      dispatch({ type: AdminActionsTypes.FETCH_ADMINS_ERROR, payload: errorPayload(error) });
    }
  };
};

export const listSignUpRequests = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (pagination: Pagination) => {
    dispatch({ type: AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_IN_PROGRESS });

    try {
      const paginatedUsers = await adminsService.list({
        ...pagination,
        filter: 'status:PENDING_SIGN_IN_APPROVAL'
      });

      dispatch({
        type: AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_SUCCESS,
        payload: paginatedUsers
      });
    } catch (error: any) {
      dispatch({
        type: AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_ERROR,
        payload: errorPayload(error)
      });
    }
  };
};

export const getAdmin = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (id: string) => {
    dispatch({ type: AdminActionsTypes.FETCH_ADMIN_IN_PROGRESS });

    try {
      const user = await adminsService.findOne(id);

      dispatch({ type: AdminActionsTypes.FETCH_ADMIN_SUCCESS, payload: adminAdapter(user) });
    } catch (error: any) {
      dispatch({ type: AdminActionsTypes.FETCH_ADMIN_ERROR, payload: errorPayload(error) });
    }
  };
};

export const deleteAdmin = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (id: string, role: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.DELETE_ADMIN_IN_PROGRESS });

    try {
      await adminsService.deleteUser(id, role);
      dispatch({ type: AdminActionsTypes.DELETE_ADMIN_SUCCESS });
      showAlert({
        variant: 'success',
        title: 'Admin',
        description: 'Admin deleted!'
      });
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Admin',
        description: "Admin couldn't be deleted!"
      });
      dispatch({ type: AdminActionsTypes.DELETE_ADMIN_ERROR, payload });
    }
  };
};

export const createAdmin = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (form: CreateAdminDto, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.CREATE_ADMIN_IN_PROGRESS });

    try {
      const createdAdmin = await adminsService.create(form);

      if (createdAdmin.id) {
        dispatch({ type: AdminActionsTypes.CREATE_ADMIN_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Admin',
          description: 'Admin created!'
        });
      } else throw { code: 400, message: createdAdmin };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Admin',
        description: "Admin couldn't be created!"
      });
      dispatch({ type: AdminActionsTypes.CREATE_ADMIN_ERROR, payload });
    }
  };
};

export const approveAdminSignUpRequest = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (id: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS });

    try {
      const done = await adminsService.approveSignUpRequest(id);

      if (done) {
        dispatch({ type: AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Success',
          description: 'Sign up request approved'
        });
      } else throw { code: 400, message: done };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Error',
        description: 'Sign up request could not be approved'
      });
      dispatch({ type: AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_ERROR, payload });
    }
  };
};

export const rejectAdminSignUpRequest = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (id: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS });

    try {
      const done = await adminsService.rejectSignUpRequest(id);

      if (done) {
        dispatch({ type: AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Success',
          description: 'Sign up request rejected'
        });
      } else throw { code: 400, message: done };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Error',
        description: 'Sign up request could not be rejected'
      });
      dispatch({ type: AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_ERROR, payload });
    }
  };
};

export const editAdmin = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (form: UpdateAdminDto, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.EDIT_ADMIN_IN_PROGRESS });

    try {
      const editedAdmin = await adminsService.edit(form);

      if (editedAdmin.id) {
        dispatch({ type: AdminActionsTypes.EDIT_ADMIN_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Admin',
          description: 'Admin edited!'
        });
      } else throw { code: 400, message: editedAdmin };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Admin',
        description: "Admin couldn't be edited!"
      });
      dispatch({ type: AdminActionsTypes.EDIT_ADMIN_ERROR, payload });
    }
  };
};

export const updatePassword = (dispatch: any) => {
  const adminsService = new AdminsService();

  return async (form: SubmittedPasswordForm, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: AdminActionsTypes.EDIT_ADMIN_PASSWORD_IN_PROGRESS });

    try {
      const result = await adminsService.updatePassword(form);

      if (result.updated) {
        dispatch({ type: AdminActionsTypes.EDIT_ADMIN_PASSWORD_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Admin',
          description: 'Password updated!'
        });
      } else throw { code: 400, message: result };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Admin',
        description: "Password couldn't be updated!"
      });
      dispatch({ type: AdminActionsTypes.EDIT_ADMIN_PASSWORD_ERROR, payload });
    }
  };
};

export const resetState = (dispatch: any) => {
  return async () => dispatch({ type: AdminActionsTypes.RESET_STATE_ERROR });
};
