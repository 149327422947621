import CheckListActionsTypes from '../actions/types/checklist';
import {
  ChecklistAction,
  ChecklistGroup,
  ChecklistInterface,
  ChecklistItemInterface,
  ChecklistStateInterface
} from '../../utils/types/checklist';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<ChecklistInterface>()

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const checklistInitialState: ChecklistInterface = {
  id: '',
  name: '',
  template: '',
  template_id: '',
  vessel_id: '',
  created_by: '',
  completed_by: '',
  createdAt: '',
  updatedAt: ''
};

export const initialState: ChecklistStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  listHistory: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: checklistInitialState
  },
  delete: actionInitialState,
  update: actionInitialState,
  complete: actionInitialState
};

export const fieldInitialState: ChecklistItemInterface = {
  name: '',
  type: 'text',
  status: false,
  value: ''
};

export const checklistGroupInitialState: ChecklistGroup = {
  name: '',
  fields: [fieldInitialState]
};

export const getFieldsType = () => ['text', 'checkBox'];

export const checklistReducer = (
  state = initialState,
  action: ChecklistAction
): ChecklistStateInterface => {
  switch (action.type) {
    case CheckListActionsTypes.CREATE_CHECKLIST_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.CREATE_CHECKLIST_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.CREATE_CHECKLIST_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.LIST_CHECKLISTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.LIST_CHECKLISTS_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<ChecklistInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.LIST_CHECKLISTS_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.LIST_CHECKLISTS_HISTORY_IN_PROGRESS:
      return {
        ...state,
        listHistory: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.LIST_CHECKLISTS_HISTORY_SUCCESS:
      return {
        ...state,
        listHistory: {
          data: action.payload as PaginatedListInterface<ChecklistInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.LIST_CHECKLISTS_HISTORY_ERROR:
      return {
        ...state,
        listHistory: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.VIEW_CHECKLIST_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.VIEW_CHECKLIST_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.VIEW_CHECKLIST_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.UPDATE_CHECKLIST_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.UPDATE_CHECKLIST_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.UPDATE_CHECKLIST_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.DELETE_CHECKLIST_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.DELETE_CHECKLIST_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.DELETE_CHECKLIST_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CheckListActionsTypes.COMPLETE_CHECKLIST_IN_PROGRESS:
      return {
        ...state,
        complete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CheckListActionsTypes.COMPLETE_CHECKLIST_SUCCESS:
      return {
        ...state,
        complete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CheckListActionsTypes.COMPLETE_CHECKLIST_ERROR:
      return {
        ...state,
        complete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };
    case CheckListActionsTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
