import { StorableAssetType, StorableAssetVisibility } from '../utils/types/storableAsset';
import ApiService from './api';

export type CreateStorableAssetDto = {
  vesselId: string;
  visibility: StorableAssetVisibility;
  assetType: StorableAssetType;
  extension: string;
  mimeType: string;
  size: number;
};

export type UploadStorableAssetDto = {
  vesselId: string;
  assetType: StorableAssetType;
  visibility: StorableAssetVisibility;
  file: File;
};

export type CreateStorableAssetUploadUrlDto = {
  vesselId: string;
  assetType: StorableAssetType;
  filename: string;
  visibility: StorableAssetVisibility;
};

export type DownloadStorableAssetDto = {
  visibility: StorableAssetVisibility;
};

export default class StorableAssetService extends ApiService {
  path = {
    plural: 'storeable-assets',
    singular: 'storeable-asset'
  };

  upload = async (dto: UploadStorableAssetDto) => {
    const formData = new FormData();

    formData.append('vesselId', dto.vesselId);
    formData.append('assetType', dto.assetType);
    formData.append('visibility', dto.visibility);
    formData.append('file', dto.file);

    const { data } = await this.axios.post(`${this.path.singular}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data.result;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`${this.path.singular}/${id}`);

    return data.result;
  };

  create = async (dto: CreateStorableAssetDto) => {
    const { data } = await this.axios.post(`${this.path.singular}`, dto);

    return data.result;
  };

  /* createUploadUrl = async (dto: CreateStorableAssetUploadUrlDto) => {
    const { data } = await this.axios.post(`${this.path.singular}/upload-url`, dto);

    return data;
  }; */

  uploadToSignedUrl = async (url: string, file: File) => {
    await fetch(url, {
      method: 'PUT',
      body: file
    });
  };

  requestDownloadUrl = async (id: string) => {
    const { data } = await this.axios.get(`${this.path.singular}/${id}/download`);

    return data.result;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`${this.path.singular}/${id}`);

    return data;
  };
}
