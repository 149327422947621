import './index.css';
import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import yms from './themes/yms';
import React from 'react';

if (process.env.REACT_APP_COGNITO_USER_POOL_ID === undefined)
  throw new Error('REACT_APP_COGNITO_USER_POOL_ID is not defined');
if (process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID === undefined)
  throw new Error('process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID is not defined');

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container!);

function AppWithCallbackAfterRender() {
  return (
    <ThemeProvider theme={yms}>
      <App />
    </ThemeProvider>
  );
}

/*
function AppWithCallbackAfterRender() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={yms}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
}
*/

root.render(<AppWithCallbackAfterRender />);
