import { Pagination } from '../utils/types/pagination';
import { TankLevelInterface } from '../utils/types/tankLevel';
import ApiService from './api';

export type CreateTankLevelDto = Omit<
  TankLevelInterface,
  'id' | 'state' | 'created_by' | 'updated_by' | 'createdAt' | 'updatedAt'
>;
export type UpdateTankLevelDto = Omit<
  TankLevelInterface,
  'id' | 'state' | 'created_by' | 'updated_by' | 'createdAt' | 'updatedAt'
>;

export default class TankLevelsService extends ApiService {
  create = async (dto: CreateTankLevelDto) => {
    const { data } = await this.axios.post('tank-level', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('tank-levels', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`tank-level/${id}`);

    return data;
  };

  update = async (id: string, dto: UpdateTankLevelDto) => {
    const { data } = await this.axios.put(`tank-level/${id}`, dto);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`tank-level/${id}`);

    return data;
  };
}
