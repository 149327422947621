import { FC, ReactElement } from 'react';
import { Grid, Paper } from '@mui/material';
// import StatusSlot from '../number-slot/StatusSlot';
import { ServiceInterface } from './ServiceCardList';
import './ServiceCard.scss';

interface ServiceCardArgs {
  service: ServiceInterface;
  onClickCard: () => void;
  notificationValue?: string;
}

const ServiceCard: FC<ServiceCardArgs> = ({
  service,
  onClickCard,
  notificationValue
}): ReactElement => {
  /* const notificationCount = service.notification && (
    <div className="corner-notification">
      <StatusSlot
        value={notificationValue || '0'}
        backgroundValue={service.notification.color}
        backgroundSlot={service.notification.backgroundColor}
      />
    </div>
  ); */

  return (
    <Grid item xs={5.51} sm={5.59} md={3.65} lg={2.75} xl={2.21}>
      <div className="card-container">
        <Paper onClick={onClickCard} elevation={0} sx={paperStyle}>
          {/* {service.notification && notificationCount} */}
          <div className="paper-content">
            {service.icon}
            <div className="title">{service.name}</div>
          </div>
        </Paper>
      </div>
    </Grid>
  );
};

export default ServiceCard;

const paperStyle = {
  display: { xs: 'block', sm: 'block' },
  '&.MuiPaper-root': {
    background: '#FFFFFF',
    height: '150px',
    margin: 0,
    '&:hover': { background: '#A9B2CD' }
  }
};
