import { Badge } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';

type Operator = {
  id: string;
  name: string;
  lastName: string;
};

const getUpcomingServiceGridColumns = () => [
  {
    field: 'service_date',
    headerName: 'Service date',
    minWidth: 150,
    renderCell: ({ value }: GridRenderCellParams<{ value: string }>) => {
      if (!value) {
        return '--';
      } else {
        return (
          <span style={{ color: '#52659B', fontWeight: 500 }}>
            {moment(value).format('DD/MM/YYYY')}
          </span>
        );
      }
    }
  },
  {
    field: 'state',
    headerName: '',
    width: 80,
    sortable: false,
    renderCell: ({ value }: GridRenderCellParams<{ value: string }>) => {
      const stateHexaMap: { [key: string]: string } = {
        green: '#4CAF50',
        yellow: '#FFC107',
        red: '#F44336'
      };

      return (
        <Badge
          color="primary"
          badgeContent={''}
          sx={{
            '.MuiBadge-badge': {
              backgroundColor: stateHexaMap[value]
            }
          }}
        />
      );
    }
  },
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 150,
    sortable: true,
    flex: 1
  },
  {
    field: 'additional_information',
    headerName: 'Additional Information',
    width: 200,
    sortable: true,
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams<{ value: string }>) => {
      return <p style={{ whiteSpace: 'pre-line' }}>{value}</p>;
    }
  },
  {
    field: 'creator',
    headerName: 'Created By',
    minWidth: 150,
    renderCell: (column: GridRenderCellParams<Operator>) => {
      if (column.value) {
        return `${column.value.name} ${column.value.lastName}`;
      } else {
        return '--';
      }
    },
    flex: 1
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    minWidth: 150,
    renderCell: ({ value }: GridRenderCellParams<{ value: string }>) => (
      <span style={{ color: '#52659B', fontWeight: 500 }}>{moment(value).fromNow()}</span>
    )
  }
];

export default getUpcomingServiceGridColumns;
