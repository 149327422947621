import Grid from '@mui/material/Grid';

type VesselEmptyListServiceProps = {
  serviceName: string;
  height?: string;
};

const VesselEmptyListService = (props: VesselEmptyListServiceProps) => {
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={props.height || '100%'}
      padding={0}
      margin={0}
      color={'#52659B'}
    >
      Oops! You don't have any {props.serviceName} created yet. The {props.serviceName} created for
      this vessel will appear here
    </Grid>
  );
};

export default VesselEmptyListService;
