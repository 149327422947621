import { Badge } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { MRT_ColumnDef } from 'material-react-table';
import moment from 'moment';
import { UscgSafetyInterface } from '../../../utils/types/uscgSafety';
import { UserInterface } from '../../../utils/types/user';

type Operator = {
  id: string;
  name: string;
  lastName: string;
};

const getUscgSafetyGridColumns = (): Array<MRT_ColumnDef<UscgSafetyInterface>> => [
  {
    accessorKey: 'expiration_date',
    header: 'Expiration date',
    Cell: ({ cell }) => {
      const date = new Date(cell.getValue<string>());
      const formattedDate = moment(date).format('DD/MM/YYYY')

      if (!date) {
        return '--';
      } else {
        return (
          <span style={{ color: '#52659B', fontWeight: 500 }}>
            {formattedDate}
          </span>
        );
      }
    }
  },
  {
    accessorKey: 'state',
    header: 'State',
    Cell: ({ cell }) => {
      const state = cell.getValue<string>();
      const stateHexaMap: { [key: string]: string } = {
        green: '#4CAF50',
        yellow: '#FFC107',
        red: '#F44336'
      };

      return (
        <Badge
          color="primary"
          badgeContent={''}
          sx={{
            '.MuiBadge-badge': {
              backgroundColor: stateHexaMap[state]
            }
          }}
        />
      );
    }
  },
  {
    accessorKey: 'title',
    header: 'Title'
  },
  {
    accessorKey: 'additional_information',
    header: 'Additional Information',
    Cell: ({ cell }) => {
      const additionalInformation = cell.getValue<string>();

      return <p style={{ whiteSpace: 'pre-line' }}>{additionalInformation}</p>;
    }
  },
  {
    accessorKey: 'creator',
    header: 'Created by',
    enableSorting: false,
    Cell: ({ cell }) => {
      const creator = cell.getValue<UserInterface>();

      return (
        <span>
          {creator ? creator.name + ' ' + creator.lastName : '--'}
        </span>
      );
    }
  },
  {
    accessorKey: 'updatedAt',
    header: 'Updated at',
    Cell: ({ cell }) => {
      const date = new Date(cell.getValue<string>());
      const humanReadableDate = moment(date).fromNow();

      return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
    }
  },
];

export default getUscgSafetyGridColumns;
