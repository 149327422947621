import { CreateVesselMediaFileDto } from '../../services/vesselMediaFile';
import { getInitialPaginationState } from '../../utils/constants/states';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  VesselMediaAction,
  VesselMediaFileInterface,
  VesselMediaFileStateInterface
} from '../../utils/types/vesselMediaFile';
import VesselMediaFileActionsTypes from '../actions/types/vesselMediaFile';
import { storableAssetInitialState } from './storableAssetReducer';

const initialPaginationState = getInitialPaginationState<VesselMediaFileInterface>();

export const vesselMediaFileInitialState: VesselMediaFileInterface = {
  id: '',
  name: '',
  storeable_asset_id: '',
  vessel_id: '',
  storeable_asset: storableAssetInitialState,
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: VesselMediaFileStateInterface = {
  create: actionInitialState,
  createBulk: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: { ...actionInitialState, data: vesselMediaFileInitialState },
  update: actionInitialState,
  delete: actionInitialState
};

export const createVesselMediaFileInitialState: CreateVesselMediaFileDto = {
  name: '',
  vessel_id: '',
  storeable_asset_id: ''
};

export const reducer = (
  state = initialState,
  action: VesselMediaAction
): VesselMediaFileStateInterface => {
  switch (action.type) {
    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_IN_PROGRESS:
      return {
        ...state,
        createBulk: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_SUCCESS:
      return {
        ...state,
        createBulk: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_ERROR:
      return {
        ...state,
        createBulk: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<VesselMediaFileInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.UPDATE_VESSEL_MEDIA_FILE_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.UPDATE_VESSEL_MEDIA_FILE_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.UPDATE_VESSEL_MEDIA_FILE_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselMediaFileActionsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
