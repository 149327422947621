import { ReactElement, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import ManBoat from '../../assets/images/man-boat.svg';
import Header from '../../components/headers/header-unauthorized-screens/Header';
import paths from '../../utils/constants/paths';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './SignIn.scss';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';

const SignIn: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/management/' + paths.ADMINS.slug);
    }
  }, [route]);

  const largeScreen = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const components: DefaultComponents = {
    SignIn: {
      Header() {
        return (
          <div className="auth-sign-in-header">
            <h1>Welcome to the yacht admin</h1>
          </div>
        );
      }
    },
    ForgotPassword: {
      Header() {
        return (
          <div className="auth-forgot-password-header">
            <h1>Forgot your password?</h1>
          </div>
        );
      }
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <>
            <div className="auth-confirm-reset-password-header">
              <h1>New password</h1>
            </div>
            <div className="auth-confirm-reset-password-helper">
              <span>Passwords must have at least 8 characters.</span>
            </div>
          </>
        );
      }
    },
    ForceNewPassword: {
      Header() {
        return (
          <>
            <div className="auth-confirm-reset-password-header">
              <h1>New password</h1>
            </div>
            <div className="auth-confirm-reset-password-helper">
              <span>Passwords must have at least 8 characters.</span>
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <Header />

      <Grid
        container
        spacing={0}
        direction={largeScreen ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={10} sm={9} md={7.5} lg={6} xl={6}>
          <img src={ManBoat} alt="man" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid>
        <Grid
          item
          xs={10}
          sm={6}
          md={3.5}
          lg={3.5}
          xl={3.34}
          style={{ paddingBottom: '2%', maxWidth: '400px' }}
        >
          <Authenticator
            hideSignUp
            loginMechanisms={['email']}
            className="auth-container"
            components={components}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
