import Grid from '@mui/material/Grid';
import { FC, ReactElement } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import FieldsFactory from '../../../components/fieldsFactory/FieldsFactory';
import { ChecklistItemInterface } from '../../../utils/types/checklist';
import './ChecklistTemplate.scss';

type Props = {
  name: string;
  fields: ChecklistItemInterface[];
  onEdit: () => void;
};

const CheckListTemplateGroupList: FC<Props> = ({ name, fields, onEdit }): ReactElement => {
  const fieldsList = (fields: ChecklistItemInterface[]) => {
    return (
      <Grid container columnSpacing={1} sx={boxStyle} alignItems="initial">
        {fields.map((field, i) => {
          return (
            <Grid item key={i} xs={12} sm={12} md={6} lg={4} xl={3} spacing={2}>
              <FieldsFactory
                type={field.type}
                name={field.name}
                rest={{ value: field.value }}
                disabled={true}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Grid container direction={'row'} justifyContent={'space-between'}>
        <Grid item>
          <p className="group-fields-name">{name}</p>
        </Grid>

        <Grid item>
          <span style={{ cursor: 'pointer' }} onClick={() => onEdit()}>
            <EditIcon />
          </span>
        </Grid>
      </Grid>
      {fieldsList(fields)}
    </>
  );
};

export default CheckListTemplateGroupList;

const boxStyle = {
  padding: '1em',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D4D9E6',
  borderRadius: '8px'
};
