import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';

const HeaderBack = (): ReactElement => {
  const navigate = useNavigate();

  return <ArrowLeftIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />;
};

export default HeaderBack;
