import { CrewRequestInterface } from '../utils/types/crewRequest';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type UpdateCrewRequestDto = Pick<CrewRequestInterface, 'state' | 'notes'>;

export default class CrewRequestService extends ApiService {
  list = async (pagination: Pagination): Promise<CrewRequestInterface[]> => {
    const { data } = await this.axios.get('crew-requests', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  update = async (id: string, dto: UpdateCrewRequestDto) => {
    const { data } = await this.axios.put(`crew-request/${id}`, dto);

    return data;
  };
}
