import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import './TankLevelsTemplate.scss';

type Props = {
  content: ReactElement;
  onClickEdit: () => void;
};

const TankLevelItem: FC<Props> = ({ content, onClickEdit }): ReactElement => {
  return (
    <div className="tank-level">
      <Box sx={boxStyleList}>
        <Paper elevation={0}>
          <div className="tank-level-item">
            <div className="text-item">{content}</div>
            <div onClick={onClickEdit} style={{ cursor: 'pointer' }}>
              <EditIcon />
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default TankLevelItem;

const boxStyleList = {
  display: 'flex',
  flexWrap: 'wrap',
  '& > :not(style)': {
    width: '100%',
    height: 70,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D4D9E6',
    borderRadius: '8px',
    padding: '24px',
    transition: 'transform 0.2s linear 0s'
  }
};
