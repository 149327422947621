import { Pagination } from '../utils/types/pagination';
import { CreateServiceRecordDto, ServiceRecordInterface } from '../utils/types/serviceRecord';
import ApiService from './api';

export default class ServiceRecordsService extends ApiService {
  create = async (dto: CreateServiceRecordDto) => {
    const { data } = await this.axios.post('service-record', dto);

    return data;
  };

  list = async (pagination: Pagination): Promise<ServiceRecordInterface[]> => {
    const { data } = await this.axios.get('service-records', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`service-record/${id}`);

    return data;
  };
}
