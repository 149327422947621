import { useState, useContext, ReactElement, FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as YmsLogo } from '../../assets/images/yms-logo-modified.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/chevron-down.svg';
import { ReactComponent as ArrowForwardIcon } from '../../assets/images/chevron-forward.svg';
import { menuList, nestedMenu } from '../../utils/constants/menu/menu';
import TopBar from '../top-bar/TopBar';
import paths from '../../utils/constants/paths';
import { ReactComponent as ProfileCircleIcon } from '../../assets/images/profile-circle.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/log-out.svg';
import './Menu.scss';
import { signOut } from 'aws-amplify/auth';

interface ItemInterface {
  name: string;
  icon: ReactElement;
  path: string;
}

export const menuWidth = 273;

const menuPartial = [
  {
    name: 'Edit profile',
    icon: <ProfileCircleIcon className="icon" />,
    path: paths.EDIT_PROFILE.slug
  },
  {
    name: 'Log out',
    icon: <LogoutIcon className="icon" />,
    path: ''
  }
];

const Menu: FC<{}> = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultItem = location.pathname.split('/')[2];

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openFormTemplatesMenu, setOpenFormTemplatesMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultItem);

  useEffect(() => {
    setSelectedItem(defaultItem === undefined ? menuList[0].path : defaultItem);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (path: string) => {
    navigate(path);
    setMobileOpen(false);
    setSelectedItem(path);
  };

  const handleFormTemplatesMenu = () => {
    setSelectedItem('form');
    setOpenFormTemplatesMenu(!openFormTemplatesMenu);
  };

  const handleItemClick = (item: ItemInterface) => {
    if (item.name === menuPartial[0].name) {
      setSelectedItem(item.path);
      navigate(item.path);
    } else {
      signOut().then(() => {
        navigate(paths.SIGN_IN.absolutePath);
      });
    }
  };

  const listItemIcon = (item: ItemInterface) => {
    return (
      <ListItemButton
        className={location.pathname.includes(item.path) ? 'selectedItem' : 'item'}
        selected={location.pathname.includes(item.path)}
        key={item.name}
        onClick={() => handleClick(item.path)}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText disableTypography primary={item.name} />
      </ListItemButton>
    );
  };

  const drawer = (
    <>
      <YmsLogo
        color="#D4D9E6"
        style={{ width: '96px', height: '37px', margin: '43px 0 27px 42px' }}
      />
      <div className="sidebar-container">
        <List style={{ margin: '80px auto 0 auto', width: '225px' }}>
          {menuList.map((item) =>
            item !== menuList[menuList.length - 1] ? listItemIcon(item) : ''
          )}

          <ListItemButton
            className={
              location.pathname.includes('template') && !openFormTemplatesMenu
                ? 'selectedItem'
                : 'item'
            }
            selected={location.pathname.includes('template') && !openFormTemplatesMenu}
            onClick={handleFormTemplatesMenu}
            style={{ height: '49px' }}
          >
            <ListItemIcon>{nestedMenu[0].icon}</ListItemIcon>
            <ListItemText primary={nestedMenu[0].name} />
            {openFormTemplatesMenu ? (
              <ArrowDownIcon style={{ width: '15px', height: '15px', color: '#D4D9E6' }} />
            ) : (
              <ArrowForwardIcon style={{ width: '15px', height: '15px', color: '#D4D9E6' }} />
            )}
          </ListItemButton>
          <Collapse in={openFormTemplatesMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedMenu.map((item, index) => (index > 0 ? listItemIcon(item) : ''))}
            </List>
          </Collapse>

          {listItemIcon(menuList[menuList.length - 1])}
        </List>

        <List style={{ margin: '0 auto 40px auto', width: '225px' }}>
          {menuPartial.map((item, index) => (
            <ListItemButton
              className={item.path === selectedItem ? 'selectedItem' : 'item'}
              selected={item.path === selectedItem}
              key={item.name + index}
              onClick={() => handleItemClick(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
        </List>
      </div>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar handleDrawerToggle={handleDrawerToggle} drawerWidth={menuWidth} />

      <Box
        component="nav"
        sx={{ width: { sm: menuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            ...drawerStyle
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          style={{ background: 'blue', color: 'white' }}
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            ...drawerStyle
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Menu;

const drawerStyle = {
  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: menuWidth },
  '& .MuiPaper-root': { background: '#0A0F1F' }
};
