import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import { TankLevelTemplateInterface } from '../../../utils/types/tankLevelTemplate';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';

const getTankLevelsGridColumns = (
  onEditTankLevel: (tankLevel: TankLevelTemplateInterface) => void,
  onDeleteTankLevel: (tankLevel: TankLevelTemplateInterface) => void
): Array<MRT_ColumnDef<TankLevelTemplateInterface>> => [
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'createdAt',
      header: 'Created',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditTankLevel(cell.row.original)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDeleteTankLevel(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getTankLevelsGridColumns;
