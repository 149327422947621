enum MyProfileActionTypes {
  VIEW_INFO_IN_PROGRESS = 'VIEW_INFO_IN_PROGRESS',
  VIEW_INFO_SUCCESS = 'VIEW_INFO_SUCCESS',
  VIEW_INFO_ERROR = 'VIEW_INFO_ERROR',

  UPDATE_INFO_IN_PROGRESS = 'UPDATE_INFO_IN_PROGRESS',
  UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS',
  UPDATE_INFO_ERROR = 'UPDATE_INFO_ERROR',

  UPDATE_PASSWORD_IN_PROGRESS = 'UPDATE_PASSWORD_IN_PROGRESS',
  UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default MyProfileActionTypes;
