import RoleEnum from '../enums/roles';
import { CreateCustomerDto, UpdateCustomerDto } from '../utils/types/customer';
import { Pagination } from '../utils/types/pagination';
import { SubmittedPasswordForm } from '../utils/types/user';
import BaseUsersService from './base-users';

export default class CustomersService extends BaseUsersService {
  role = {
    plural: 'customers',
    singular: 'customer'
  };

  findOne(id: string) {
    return this.getUser(this.role.singular, id);
  }

  findAll(pagination: Pagination) {
    return this.getBaseUsersPaginated(this.role.plural, pagination);
  }

  delete = async (id: string) => {};

  create = async (user: CreateCustomerDto) => {
    const { data } = await this.axios.post(RoleEnum.CUSTOMER, user);

    return data;
  };

  approveSignUpRequest = async (id: string) => {
    const { data } = await this.axios.post(`${this.role.singular}/sign-up/${id}/approve`);

    return data;
  };

  rejectSignUpRequest = async (id: string) => {
    const { data } = await this.axios.post(`${this.role.singular}/sign-up/${id}/reject`);

    return data;
  };

  edit = async (user: UpdateCustomerDto) => {
    const { data } = await this.axios.put(`${RoleEnum.CUSTOMER}/${user.id}`, user);

    return data;
  };

  updatePassword = async (dto: SubmittedPasswordForm) => {
    const { data } = await this.axios.put(`${RoleEnum.CUSTOMER}/${dto.userId}/password`, {
      password: dto.password
    });

    return data;
  };
}
