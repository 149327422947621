import { useCallback, useContext, useEffect, useState } from "react";
import { defaultPagination } from "../../../../utils/constants/defaults";
import { CrewRequestContext } from "../../../../context/crewRequestContext";
import MrtDataTable from "../../../../components/mrt-data-table";
import { getGridColumns } from "./columns";
import { CrewRequestInterface } from "../../../../utils/types/crewRequest";
import { crewRequestInitialState } from "../../../../context/reducers/crewRequestReducer";
import AlertDialog from "../../../../components/dialog/AlertDialog";
import Grid from "@mui/material/Grid";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { Pagination } from "../../../../utils/types/pagination";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ListGridTitle from "../ListGridTitle";

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: [],
  order: defaultOrderParams,
  perPage: 10,
};

type CreatedCrewRequestsTableProps = {
  vesselId: string;
}

export default function CreatedCrewRequestsTable(props: CreatedCrewRequestsTableProps) {
  const { listCreatedCrewRequests, updateCrewRequest, state: crewRequestState } = useContext(CrewRequestContext);

  const [selectedCrewRequest, setSelectedCrewRequest] = useState<CrewRequestInterface>(crewRequestInitialState);
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${props.vesselId}`
  });

  useEffect(() => {
    if (
      !crewRequestState.update.loading &&
      !crewRequestState.update.error &&
      crewRequestState.update.done
    ) {
      listCreatedCrewRequestsPaginated(paginationParams);
    }
  }, [crewRequestState.update])

  const onAcceptCrewRequest = (request: CrewRequestInterface) => {
    setSelectedCrewRequest(request);
    setOpenAcceptDialog(true);
  };

  const onRejectCrewRequest = (request: CrewRequestInterface) => {
    setOpenRejectDialog(true);
    setSelectedCrewRequest(request);
  };

  const onConfirmAcceptCrewRequest = () => {
    setOpenAcceptDialog(false);
    updateCrewRequest(selectedCrewRequest.id, {
      state: 'accepted',
      notes: ''
    });
  };

  const onConfirmRejectCrewRequest = () => {
    setOpenRejectDialog(false);
    updateCrewRequest(selectedCrewRequest.id, {
      state: 'rejected',
      notes: selectedCrewRequest.notes
    });
  };

  const columns = getGridColumns(onAcceptCrewRequest, onRejectCrewRequest)

  useEffect(() => {
    listCreatedCrewRequestsPaginated(paginationParams);
  }, [paginationParams]);

  const listCreatedCrewRequestsPaginated = useCallback((paginationParams: Pagination) => {
    listCreatedCrewRequests(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      const sameAsPrevParams = (
        paginationState.pageIndex === prevParams.page - 1 &&
        paginationState.pageSize === prevParams.perPage
      )

      return sameAsPrevParams ? prevParams : {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <ListGridTitle
        requestsNumber={crewRequestState?.list?.created?.data?.pagination?.total || 0}
        state={'created'}
      />

      <MrtDataTable
        isLoading={crewRequestState.list.created.loading}
        isError={Boolean(crewRequestState.list.created.error)}
        columns={columns}
        rows={crewRequestState?.list?.created?.data?.results}
        rowCount={crewRequestState?.list?.created?.data?.pagination?.total}
        initialPagination={{
          pageIndex: paginationParams.page - 1,
          pageSize: paginationParams.perPage
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
      />

      {openAcceptDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>Are you sure you want to accept this request?</span>
              </Grid>
            </Grid>
          }
          cancelText={'No'}
          approveText={'Yes'}
          open={openAcceptDialog}
          onClose={() => setOpenAcceptDialog(false)}
          onSave={onConfirmAcceptCrewRequest}
        />
      )}

      {openRejectDialog && (
        <AlertDialog
          title={
            <p
              style={{
                display: 'flex',
                color: '#1F3268',
                margin: '20px 0 -10px 0',
                fontSize: '18px'
              }}
            >
              Reject request
            </p>
          }
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <span style={{ color: '#273F82', fontSize: '14px' }}>
                  ¿Can you explain to the client why this request will be rejected?
                </span>
                <TextareaAutosize
                  name="notes"
                  value={selectedCrewRequest.notes || ''}
                  onChange={(e) =>
                    setSelectedCrewRequest({ ...selectedCrewRequest, notes: e.target.value })
                  }
                  minRows={7}
                  style={{ width: '100%', margin: '20px 0 -20px' }}
                />
              </Grid>
            </Grid>
          }
          style={{ width: '600px' }}
          cancelText={'Cancel'}
          approveText={'Send'}
          open={openRejectDialog}
          onClose={() => setOpenRejectDialog(false)}
          onSave={onConfirmRejectCrewRequest}
        />
      )}
    </>
  )
}

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };