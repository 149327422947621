import { Pagination } from '../utils/types/pagination';
import { SystemInfoInterface } from '../utils/types/systemInfo';
import ApiService from './api';

export type CreateSystemInfoDto = Omit<
  SystemInfoInterface,
  'id' | 'state' | 'created_by' | 'updated_by' | 'createdAt' | 'updatedAt'
>;
export type UpdateSystemInfoDto = Omit<
  SystemInfoInterface,
  'id' | 'state' | 'created_by' | 'updated_by' | 'createdAt' | 'updatedAt'
>;

export default class SystemInfoService extends ApiService {
  create = async (dto: CreateSystemInfoDto) => {
    const { data } = await this.axios.post('system-info', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('system-info', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`system-info/${id}`);

    return data;
  };

  update = async (id: string, systemInfo: UpdateSystemInfoDto) => {
    const { data } = await this.axios.put(`system-info/${id}`, systemInfo);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`system-info/${id}`);

    return data;
  };
}
