import moment from 'moment';

type HumanReadableDateProps = {
  value: string;
};

const HumanReadableDate = (props: HumanReadableDateProps) => (
  <span style={{ color: '#52659B', fontWeight: 500 }}>
    {moment(props.value).format('ddd DD MMM YYYY, hh:mm A')}
  </span>
);

export default HumanReadableDate;
