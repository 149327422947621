import TankLevelActionsTypes from '../../context/actions/types/tankLevel';
import {
  ActionInterface,
  ActionListInterface,
  ActionViewInterface,
  ErrorInterface,
  PaginatedListInterface
} from './common';
import { UserInterface } from './user';

export enum TankLevelColorsEnum {
  GREEN = 'Green',
  BLUE = 'Blue',
  ORANGE = 'Orange',
  RED = 'Red',
  PINK = 'Pink',
  BLACK = 'Black'
}

export const getTankLevelColors = () => ['Green', 'Blue', 'Red'];

export interface TankLevelItemInterface {
  label: string;
  color: string;
  maxLevel: number;
  value: number;
}

export type TankLevelInterface = {
  id: string;
  name: string;
  template: string;
  state: string;
  vessel_id: string;
  created_by: string;
  updated_by: string;
  createdAt: string;
  updatedAt: string;
  creator?: UserInterface;
  operator?: UserInterface;
};

export interface TankLevelStateInterface {
  create: ActionInterface;
  list: ActionInterface & ActionListInterface<TankLevelInterface>;
  view: ActionInterface & ActionViewInterface<TankLevelInterface>;
  update: ActionInterface;
  delete: ActionInterface;
  complete: ActionInterface;
}

export type TankLevelAction = {
  type: TankLevelActionsTypes;
  payload: PaginatedListInterface<TankLevelInterface> | TankLevelInterface | ErrorInterface;
};
