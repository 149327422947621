import CrewRequestsTypes from '../actions/types/crewRequest';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  CrewRequestInterface,
  CrewRequestsStateInterface,
  CrewRequestsAction
} from '../../utils/types/crewRequest';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<CrewRequestInterface>()

export const crewRequestInitialState: CrewRequestInterface = {
  id: '',
  type: 'Captain',
  schedule_date: '',
  state: 'created',
  notes: '',
  vessel_id: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: CrewRequestsStateInterface = {
  list: {
    created: { ...actionInitialState, data: initialPaginationState },
    accepted: { ...actionInitialState, data: initialPaginationState },
    rejected: { ...actionInitialState, data: initialPaginationState },
    cancelled: { ...actionInitialState, data: initialPaginationState }
  },
  update: actionInitialState
};

export const reducer = (
  state = initialState,
  action: CrewRequestsAction
): CrewRequestsStateInterface => {
  switch (action.type) {
    case CrewRequestsTypes.LIST_CREATED_CREW_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          created: {
            loading: true,
            error: undefined,
            done: false,
            data: undefined
          }
        }
      };
    case CrewRequestsTypes.LIST_CREATED_CREW_REQUESTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          created: {
            loading: false,
            error: undefined,
            done: true,
            data: action.payload as PaginatedListInterface<CrewRequestInterface>
          }
        }
      };
    case CrewRequestsTypes.LIST_CREATED_CREW_REQUESTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          created: {
            loading: false,
            error: action.payload as ErrorInterface,
            done: false,
            data: undefined
          }
        }
      };

    case CrewRequestsTypes.LIST_ACCEPTED_CREW_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          accepted: {
            loading: true,
            error: undefined,
            done: false,
            data: undefined
          }
        }
      };
    case CrewRequestsTypes.LIST_ACCEPTED_CREW_REQUESTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          accepted: {
            loading: false,
            error: undefined,
            done: true,
            data: action.payload as PaginatedListInterface<CrewRequestInterface>
          }
        }
      };
    case CrewRequestsTypes.LIST_ACCEPTED_CREW_REQUESTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          accepted: {
            loading: false,
            error: action.payload as ErrorInterface,
            done: false,
            data: undefined
          }
        }
      };

    case CrewRequestsTypes.LIST_REJECTED_CREW_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          rejected: {
            loading: true,
            error: undefined,
            done: false,
            data: undefined
          }
        }
      };
    case CrewRequestsTypes.LIST_REJECTED_CREW_REQUESTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          rejected: {
            loading: false,
            error: undefined,
            done: true,
            data: action.payload as PaginatedListInterface<CrewRequestInterface>
          }
        }
      };
    case CrewRequestsTypes.LIST_REJECTED_CREW_REQUESTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          rejected: {
            loading: false,
            error: action.payload as ErrorInterface,
            done: false,
            data: undefined
          }
        }
      };

    case CrewRequestsTypes.LIST_CANCELLED_CREW_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          cancelled: {
            loading: true,
            error: undefined,
            done: false,
            data: undefined
          }
        }
      };
    case CrewRequestsTypes.LIST_CANCELLED_CREW_REQUESTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          cancelled: {
            loading: false,
            error: undefined,
            done: true,
            data: action.payload as PaginatedListInterface<CrewRequestInterface>
          }
        }
      };
    case CrewRequestsTypes.LIST_CANCELLED_CREW_REQUESTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          cancelled: {
            loading: false,
            error: action.payload as ErrorInterface,
            done: false,
            data: undefined
          }
        }
      };

    case CrewRequestsTypes.UPDATE_CREW_REQUEST_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CrewRequestsTypes.UPDATE_CREW_REQUEST_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CrewRequestsTypes.UPDATE_CREW_REQUEST_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };
    default:
      return state;
  }
};
