import {
  SystemInfoAction,
  SystemInfoInterface,
  SystemInfoTemplateItem,
  SystemInfoStateInterface
} from '../../utils/types/systemInfo';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import SystemInfoActionTypes from '../actions/types/systemInfo';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<SystemInfoInterface>()

export const systemInfoItemInitialState: SystemInfoTemplateItem = {
  field: {
    type: 'text',
    value: ''
  },
  label: ''
};

export const systemInfoInitialState: SystemInfoInterface = {
  id: '',
  name: '',
  template: '',
  state: '',
  vessel_id: '',
  created_by: '',
  updated_by: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: SystemInfoStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: systemInfoInitialState
  },
  delete: actionInitialState,
  update: actionInitialState,
  complete: actionInitialState
};

export const reducer = (
  state = initialState,
  action: SystemInfoAction
): SystemInfoStateInterface => {
  switch (action.type) {
    case SystemInfoActionTypes.CREATE_SYSTEM_INFO_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoActionTypes.CREATE_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoActionTypes.CREATE_SYSTEM_INFO_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoActionTypes.LIST_SYSTEM_INFO_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoActionTypes.LIST_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<SystemInfoInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoActionTypes.LIST_SYSTEM_INFO_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoActionTypes.VIEW_SYSTEM_INFO_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoActionTypes.VIEW_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoActionTypes.VIEW_SYSTEM_INFO_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoActionTypes.UPDATE_SYSTEM_INFO_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoActionTypes.UPDATE_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoActionTypes.UPDATE_SYSTEM_INFO_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoActionTypes.DELETE_SYSTEM_INFO_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoActionTypes.DELETE_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoActionTypes.DELETE_SYSTEM_INFO_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoActionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
