import Joi from 'joi';

export const systemInfoTemplateSchema = Joi.object({
  name: Joi.string().required()
}).options({
  abortEarly: false
});

export const systemInfoTemplateFieldsSchema = Joi.array()
  .items(
    Joi.object({
      label: Joi.string().required(),
      field: Joi.string().valid('text', 'checkBox').required()
    })
  )
  .options({
    abortEarly: false
  });
