enum TankLevelTemplateActionsTypes {
  CREATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS = 'CREATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS',
  CREATE_TANK_LEVEL_TEMPLATE_SUCCESS = 'CREATE_TANK_LEVEL_TEMPLATE_SUCCESS',
  CREATE_TANK_LEVEL_TEMPLATE_ERROR = 'CREATE_TANK_LEVEL_TEMPLATE_ERROR',

  LIST_TANK_LEVEL_TEMPLATES_IN_PROGRESS = 'LIST_TANK_LEVEL_TEMPLATES_IN_PROGRESS',
  LIST_TANK_LEVEL_TEMPLATES_SUCCESS = 'LIST_TANK_LEVEL_TEMPLATES_SUCCESS',
  LIST_TANK_LEVEL_TEMPLATES_ERROR = 'LIST_TANK_LEVEL_TEMPLATES_ERROR',

  VIEW_TANK_LEVEL_TEMPLATE_IN_PROGRESS = 'VIEW_TANK_LEVEL_TEMPLATE_IN_PROGRESS',
  VIEW_TANK_LEVEL_TEMPLATE_SUCCESS = 'VIEW_TANK_LEVEL_TEMPLATE_SUCCESS',
  VIEW_TANK_LEVEL_TEMPLATE_ERROR = 'VIEW_TANK_LEVEL_TEMPLATE_ERROR',

  UPDATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS = 'UPDATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS',
  UPDATE_TANK_LEVEL_TEMPLATE_SUCCESS = 'UPDATE_TANK_LEVEL_TEMPLATE_SUCCESS',
  UPDATE_TANK_LEVEL_TEMPLATE_ERROR = 'UPDATE_TANK_LEVEL_TEMPLATE_ERROR',

  DELETE_TANK_LEVEL_TEMPLATE_IN_PROGRESS = 'DELETE_TANK_LEVEL_TEMPLATE_IN_PROGRESS',
  DELETE_TANK_LEVEL_TEMPLATE_SUCCESS = 'DELETE_TANK_LEVEL_TEMPLATE_SUCCESS',
  DELETE_TANK_LEVEL_TEMPLATE_ERROR = 'DELETE_TANK_LEVEL_TEMPLATE_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default TankLevelTemplateActionsTypes;
