type TableHeaderProps = {
  requestsNumber: number;
  state: string;
}

const ListGridTitle = ({ requestsNumber, state }: TableHeaderProps) => {
  return (
    <div style={requestsTitleStyles}>
      This vessel has{' '}
      <strong>
        {requestsNumber > 0 ? requestsNumber : 'no '}
        {(requestsNumber === 0 || requestsNumber > 1) && ' requests '}
        {requestsNumber === 1 && ' request '}
        {state}
      </strong>
    </div>
  )
}

export default ListGridTitle

const requestsTitleStyles = {
  color: '#273f82',
  margin: '30px 0 22px'
}
