import { Reducer } from 'react';
import {
  NotificationActionInterface,
  NotificationInterface,
  showNotificationType
} from '../../utils/types/notification';
import NotificationActionsTypes from '../actions/types/notification';

export const initialState: NotificationInterface = {
  show: false,
  variant: 'success',
  title: '',
  description: '',
  action: () => {}
};

export const reducer: Reducer<NotificationInterface, NotificationActionInterface> = (
  state = initialState,
  action: NotificationActionInterface
): NotificationInterface => {
  switch (action.type) {
    case NotificationActionsTypes.SHOW_NOTIFICATION:
      return {
        ...(action.payload as unknown as showNotificationType),
        show: true
      };
    case NotificationActionsTypes.HIDE_NOTIFICATION:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
