import CrewRequestService, { UpdateCrewRequestDto } from '../../services/crewRequest';
import { Pagination } from '../../utils/types/pagination';
import CrewRequestServicesActionsTypes from './types/crewRequest';
import { errorPayload } from './utils/errorPayload';

export const listCreatedCrewRequests = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: CrewRequestServicesActionsTypes.LIST_CREATED_CREW_REQUESTS_IN_PROGRESS });

    try {
      const crewRequestService = new CrewRequestService();
      const crewRequests = await crewRequestService.list({
        ...pagination,
        filter: `state:created,${pagination.filter}`
      });

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_CREATED_CREW_REQUESTS_SUCCESS,
        payload: crewRequests
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: CrewRequestServicesActionsTypes.LIST_CREATED_CREW_REQUESTS_ERROR, payload });
    }
  };
};

export const listAcceptedCrewRequests = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: CrewRequestServicesActionsTypes.LIST_ACCEPTED_CREW_REQUESTS_IN_PROGRESS });

    try {
      const crewRequestService = new CrewRequestService();
      const crewRequests = await crewRequestService.list({
        ...pagination,
        filter: `state:accepted,${pagination.filter}`
      });

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_ACCEPTED_CREW_REQUESTS_SUCCESS,
        payload: crewRequests
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_ACCEPTED_CREW_REQUESTS_ERROR,
        payload
      });
    }
  };
};

export const listRejectedCrewRequests = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: CrewRequestServicesActionsTypes.LIST_REJECTED_CREW_REQUESTS_IN_PROGRESS });

    try {
      const crewRequestService = new CrewRequestService();
      const crewRequests = await crewRequestService.list({
        ...pagination,
        filter: `state:rejected,${pagination.filter}`
      });

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_REJECTED_CREW_REQUESTS_SUCCESS,
        payload: crewRequests
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_REJECTED_CREW_REQUESTS_ERROR,
        payload
      });
    }
  };
};

export const listCancelledCrewRequests = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: CrewRequestServicesActionsTypes.LIST_CANCELLED_CREW_REQUESTS_IN_PROGRESS });

    try {
      const crewRequestService = new CrewRequestService();
      const crewRequests = await crewRequestService.list({
        ...pagination,
        filter: `state:cancelled,${pagination.filter}`
      });

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_CANCELLED_CREW_REQUESTS_SUCCESS,
        payload: crewRequests
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({
        type: CrewRequestServicesActionsTypes.LIST_CANCELLED_CREW_REQUESTS_ERROR,
        payload
      });
    }
  };
};

export const updateCrewRequest = (dispatch: any) => {
  return async (id: string, dto: UpdateCrewRequestDto) => {
    dispatch({ type: CrewRequestServicesActionsTypes.UPDATE_CREW_REQUEST_IN_PROGRESS });

    try {
      const crewRequestService = new CrewRequestService();
      await crewRequestService.update(id, dto);

      dispatch({ type: CrewRequestServicesActionsTypes.UPDATE_CREW_REQUEST_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: CrewRequestServicesActionsTypes.UPDATE_CREW_REQUEST_ERROR, payload });
    }
  };
};
