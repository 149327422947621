import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputText from '../../../components/form/InputText';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { systemInfoTemplateItemInitialState } from '../../../context/reducers/systemInfoTemplateReducer';
import { SystemInfoTemplateContext } from '../../../context/systemInfoTemplateContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import paths from '../../../utils/constants/paths';
import { validateForm, validateFormArray } from '../../../utils/form';
import { SystemInfoFieldsTypes, getSystemInfoFieldsType } from '../../../utils/types/systemInfo';
import { SystemInfoTemplateItem } from '../../../utils/types/systemInfoTemplate';
import TemplateBox from '../TemplateBox';
import TemplateBoxTwoFields from '../TemplateBoxTwoFields';
import {
  systemInfoTemplateFieldsSchema,
  systemInfoTemplateSchema
} from './SystemInfoTemplateSchema';

const initialErrorValues = {
  name: '',
  fields: [{ label: '', field: '' }]
};

const SystemInfoTemplateForm: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const {
    createSystemInfoTemplate,
    viewSystemInfoTemplate,
    updateSystemInfoTemplate,
    resetSystemInfoTemplateState,
    state
  } = useContext(SystemInfoTemplateContext);
  const { showAlert } = useContext(NotificationContext);

  const [templateId] = useState<string>(query.get('id') || '');
  const [templateName, setTemplateName] = useState<string>('');
  const [template, setTemplate] = useState<SystemInfoTemplateItem[]>([]);
  const [validationErrors, setValidationErrors] = useState(initialErrorValues);

  useEffect(() => {
    if (templateId) viewSystemInfoTemplate(templateId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    setTemplateName(state.view.data?.name || '');
    setTemplate(JSON.parse(state.view.data?.template || '[]'));
  }, [state.view.data]);

  useEffect(() => {
    if (state.create.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template created!'
      });

      resetSystemInfoTemplateState();
      navigate(paths.TEMPLATE_SYSTEMS_INFO.absolutePath);
    }

    if (state.create.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be created!"
      });
    }
  }, [state.create]);

  useEffect(() => {
    if (state.update.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template edited!'
      });

      resetSystemInfoTemplateState();
      navigate(paths.TEMPLATE_SYSTEMS_INFO.absolutePath);
    }

    if (state.update.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be edited!"
      });
    }
  }, [state.update]);

  const handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const { errorSummary: formErrors } = validateForm(systemInfoTemplateSchema, {
      name: templateName
    });

    const { errorSummary: fieldsErrors } = validateFormArray(
      systemInfoTemplateFieldsSchema,
      template.map((field) => ({
        label: field.label,
        field: field.field.type
      }))
    );

    setValidationErrors({
      name: formErrors.name,
      fields: fieldsErrors
    });

    if (fieldsErrors.length === 0 && !Object.values(formErrors).length) {
      if (templateId !== '') {
        updateSystemInfoTemplate(templateId, {
          name: templateName,
          template: JSON.stringify(template)
        });
      } else {
        createSystemInfoTemplate({
          name: templateName,
          template: JSON.stringify(template)
        });
      }
    }
  };

  const removeField = (index: number) => {
    const newFields = template.filter((_item, i) => i !== index);
    setTemplate(newFields);
  };

  const onChangeValues = (type: string, value: SystemInfoFieldsTypes, index: number) => {
    if (type === 'name') {
      const newFieldsList = template.map((item, i) =>
        i === index ? { ...item, label: value } : item
      );
      setTemplate(newFieldsList);
    } else {
      const newFieldsList = template.map((item, i) =>
        i === index
          ? {
              ...item,
              field: {
                type: value
              }
            }
          : item
      );
      setTemplate(newFieldsList);
    }
  };

  const renderFields = template.map((item, i) => {
    return (
      <div key={i}>
        <TemplateBoxTwoFields
          showDeleteIcon={i !== 0}
          item={{
            name: item.label,
            type: item.field.type
          }}
          onDelete={() => removeField(i)}
          boxValues={getSystemInfoFieldsType()}
          validationErrors={{
            name: validationErrors?.fields[i]?.label || '',
            type: validationErrors?.fields[i]?.field || ''
          }}
          onChangeFieldName={(e) => onChangeValues('name', e as SystemInfoFieldsTypes, i)}
          handleDropdownChange={(e) => onChangeValues('type', e as SystemInfoFieldsTypes, i)}
        />
        <div style={dividerStyle} />
      </div>
    );
  });

  const addField = () => {
    const newFields = [...template, systemInfoTemplateItemInitialState];
    setTemplate(newFields);
  };

  return (
    <>
      <TitlePage
        leftContainer={<BreadCrumb previous={'System info template'} current={'Create a new'} />}
        rightContainer={
          <Button
            variant="contained"
            disabled={state.create.loading || state.update.loading}
            onClick={(e) => handleSubmitButton(e)}
          >
            Save
          </Button>
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '65px' }}>
        <TemplateBox minHeight={'calc(100vh - 240px)'}>
          <InputText
            name={'templateName'}
            label={'System info template name'}
            value={templateName}
            validationErrors={validationErrors.name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
          {renderFields}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              variant="outlined"
              disabled={state.create.loading || state.update.loading}
              type="button"
              className="actionButton"
              onClick={() => addField()}
            >
              Add field
            </Button>
          </Grid>
        </TemplateBox>
      </Grid>
    </>
  );
};

export default SystemInfoTemplateForm;

const dividerStyle = { marginBottom: '24px', width: '100%' };
