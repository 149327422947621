import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import Grid, { RegularBreakpoints } from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { validateForm } from '../../utils/form';
import { schema } from './schema';
import './UpdatePasswordForm.scss';
import InputPassword from '../form/InputPassword';

type Props = {
  variant: 'wide' | 'narrow';
  onSubmit: (password: string) => void;
  containerBreakpoints: RegularBreakpoints;
  buttonBreakpoints: RegularBreakpoints;
  buttonText: string;
  buttonDisabled?: boolean;
};

const initialState = {
  confirmPassword: '',
  password: ''
};

export type UpdatePasswordFormForwardedRef = {
  resetForm: () => void;
};

const UpdatePasswordForm = forwardRef<UpdatePasswordFormForwardedRef, Props>(
  (props: Props, ref) => {
    const [form, setForm] = useState(initialState);
    const [formValidationErrors, setFormValidationErrors] = useState(initialState);

    useImperativeHandle(ref, () => ({
      resetForm: () => setForm(initialState)
    }));

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [event.target.name]: event.target.value
      });
    };

    const handleSubmit = () => {
      const newForm = {
        password: form.password,
        confirmPassword: form.confirmPassword
      };

      const { errorSummary } = validateForm(schema, newForm);
      setFormValidationErrors(errorSummary);

      if (!Object.values(errorSummary).length) {
        props.onSubmit(form.password);
      }
    };

    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}
        sx={{ marginBottom: '84px' }}
      >
        <Grid
          item
          xs={props.containerBreakpoints.xs}
          sm={props.containerBreakpoints.sm}
          md={props.containerBreakpoints.md}
          lg={props.containerBreakpoints.lg}
          xl={props.containerBreakpoints.xl}
        >
          <Grid container display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
              <InputPassword
                label="Password"
                name="password"
                value={`${form.password}`}
                setValue={handleInputChange}
                validationErrors={formValidationErrors.password}
              />
              {/* <InputText
              type='password'
              name={'password'}
              label={'Password'}
              value={form.password}
              validationErrors={formValidationErrors.password}
              onChange={handleInputChange}
              autoComplete={false}
            /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
              <InputPassword
                label="Confirm password"
                name="confirmPassword"
                value={`${form.confirmPassword}`}
                setValue={handleInputChange}
                validationErrors={formValidationErrors.confirmPassword}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={props.buttonBreakpoints.xs}
          sm={props.buttonBreakpoints.sm}
          md={props.buttonBreakpoints.md}
          lg={props.buttonBreakpoints.lg}
          xl={props.buttonBreakpoints.xl}
        >
          <Grid container display={'flex'} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                variant="contained"
                type="button"
                onClick={handleSubmit}
                className="actionButton"
                disabled={props.buttonDisabled}
              >
                {props.buttonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default UpdatePasswordForm;
