import {
  UscgSafetyInterface,
  UscgSafetyStateInterface,
  UscgSafetyAction
} from '../../utils/types/uscgSafety';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import UscgSafetyActionsTypes from '../actions/types/uscgSafety';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<UscgSafetyInterface>()

export const uscgSafetyInitialState: UscgSafetyInterface = {
  id: '',
  title: '',
  expiration_date: '',
  additional_information: '',
  state: '',
  vessel_id: '',
  created_by: '',
  updated_by: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: UscgSafetyStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: uscgSafetyInitialState
  },
  delete: actionInitialState,
  update: actionInitialState,
  complete: actionInitialState
};

export const reducer = (
  state = initialState,
  action: UscgSafetyAction
): UscgSafetyStateInterface => {
  switch (action.type) {
    case UscgSafetyActionsTypes.CREATE_USCG_SAFETY_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UscgSafetyActionsTypes.CREATE_USCG_SAFETY_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UscgSafetyActionsTypes.CREATE_USCG_SAFETY_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UscgSafetyActionsTypes.LIST_USCG_SAFETY_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UscgSafetyActionsTypes.LIST_USCG_SAFETY_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<UscgSafetyInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UscgSafetyActionsTypes.LIST_USCG_SAFETY_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UscgSafetyActionsTypes.VIEW_USCG_SAFETY_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UscgSafetyActionsTypes.VIEW_USCG_SAFETY_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UscgSafetyActionsTypes.VIEW_USCG_SAFETY_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UscgSafetyActionsTypes.UPDATE_USCG_SAFETY_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UscgSafetyActionsTypes.UPDATE_USCG_SAFETY_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UscgSafetyActionsTypes.UPDATE_USCG_SAFETY_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UscgSafetyActionsTypes.DELETE_USCG_SAFETY_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case UscgSafetyActionsTypes.DELETE_USCG_SAFETY_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case UscgSafetyActionsTypes.DELETE_USCG_SAFETY_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case UscgSafetyActionsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
