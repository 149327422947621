import { PaginatedResultInterface } from '../interfaces/PaginatedResultInterface';
import { Pagination } from '../utils/types/pagination';
import { TankLevelTemplateInterface } from '../utils/types/tankLevelTemplate';
import ApiService from './api';

export type CreateTankLevelTemplateDto = {
  name: string;
  template: string;
};

export type UpdateTankLevelTemplateDto = {
  name: string;
  template: string;
};

export default class TankLevelTemplateService extends ApiService {
  findAll = async (
    pagination: Pagination
  ): Promise<PaginatedResultInterface<TankLevelTemplateInterface[]>> => {
    const { data } = await this.axios.get('tank-level-templates', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`tank-level-template/${id}`);

    return data;
  };

  create = async (dto: CreateTankLevelTemplateDto) => {
    const { data } = await this.axios.post('tank-level-template', dto);

    return data;
  };

  update = async (id: string, dto: UpdateTankLevelTemplateDto) => {
    const { data } = await this.axios.put(`tank-level-template/${id}`, dto);
    return data;
  };

  deleteTankLevelTemplate = async (id: string) => {
    const { data } = await this.axios.delete(`tank-level-template/${id}`);
    return data;
  };
}
