import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import './WideBar.scss';

type Props = {
  text: string;
  icon: ReactNode;
  onClickIcon?: () => void;
};

const WideBar: FC<Props> = ({ text, onClickIcon, icon }) => {
  return (
    <Box sx={boxStyleList} onClick={onClickIcon}>
      <Paper elevation={0} sx={paperStyle}>
        <div className="wide-bar-item">
          <div className="bar-text-item">{text}</div>
          <div>{icon}</div>
        </div>
      </Paper>
    </Box>
  );
};

export default WideBar;

const boxStyleList = {
  display: 'flex',
  flexWrap: 'wrap',
  cursor: 'pointer',
  '& > :not(style)': {
    margin: '22px 0',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0)',
    border: '1px solid #939FC1',
    borderStyle: 'dashed',
    borderRadius: '8px',
    padding: '24px'
  }
};

const paperStyle = {
  transition: 'transform 0.2s linear 0s',
  '&:hover': {
    transform: 'scale(1.01)'
  }
};
