import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_RowData,
  MRT_SortingState,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';

type MrtDataTableProps<T extends MRT_RowData> = {
  rows?: T[];
  rowCount?: number;
  columns: MRT_ColumnDef<T>[];
  isLoading: boolean;
  isError: boolean;
  initialPagination: MRT_PaginationState;
  onPaginationChange?: (paginationState: MRT_PaginationState) => void;
  onSortingChange?: (sortingState: MRT_SortingState) => void;
};

export default function MrtDataTable<T extends MRT_RowData>(props: MrtDataTableProps<T>) {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.rows, [props.rows]);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>(props.initialPagination);

  useEffect(() => {
    if (props.onPaginationChange) {
      props.onPaginationChange(pagination);
    }
  }, [pagination]);

  useEffect(() => {
    if (props.onSortingChange) {
      props.onSortingChange(sorting);
    }
  }, [sorting]);

  const table = useMaterialReactTable({
    columns: columns,
    data: data || [],
    enableColumnActions: false,
    enableTopToolbar: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableProps: {
      sx: {
        padding: '24px',

        '& .MuiTableHead-root': {
          '& .MuiTableRow-head': {
            boxShadow: 'none',

            '& .MuiTableCell-head': {
              paddingBottom: '20px',
            },

            '& .Mui-TableHeadCell-Content': {
              fontSize: '13px',
              fontWeight: 500,
            }
          }
        },
      }
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 50],
      showFirstButton: false,
      showLastButton: false
    },
    muiToolbarAlertBannerProps: props.isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    pageCount: props.rowCount ? Math.ceil(props.rowCount / pagination.pageSize) : 0,
    rowCount: props.rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      isLoading: props.isLoading,
      showProgressBars: props.isLoading,
      showLoadingOverlay: props.isLoading,
      showAlertBanner: props.isError,
      pagination: pagination,
      sorting: sorting,
    }
  });

  return (
    <MaterialReactTable table={table} />
  );
}