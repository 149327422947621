import './NumberSlot.scss';

type NumberSlotProps = {
  value: string;
};

const NumberSlot = ({ value }: NumberSlotProps) => {
  return (
    <div className="slot">
      <span className="number-slot">{value}</span>
    </div>
  );
};

export default NumberSlot;
