import { FC, ReactElement, ReactNode } from 'react';
import Grid from '@mui/material/Grid';

interface FieldContainerArgs {
  children: ReactNode;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

const FieldContainer: FC<FieldContainerArgs> = ({ children, xs, sm, md, lg, xl }): ReactElement => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Grid container display={'flex'} justifyContent={'space-between'}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FieldContainer;
