import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    small: true;
  }
}

const yms = createTheme({
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    body1: {
      fontSize: 15
    },
    body2: {
      fontSize: 14
    },
    h1: {
      fontSize: 24,
      lineHeight: 30
    },
    h2: {
      fontSize: 18,
      lineHeight: 30
    }
  },
  palette: {
    primary: {
      main: '#273F82',
      dark: '#17264E'
    },
    secondary: {
      main: '#fff'
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          width: 'calc(100% - 273px)'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '11px',
          width: '100%'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          fontSize: '14px',
          '.MuiAutocomplete-option': {
            color: '#232735'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #273F82'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#fff',
          lineHeight: '1.4375em'
        },
        sizeSmall: {
          fontSize: '14px',
          lineHeight: '21px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '22px',
          marginBottom: '10px',
          color: '#232735'
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          lineHeight: '1px',
          '& .MuiSlider-thumb': {
            color: '#fff',
            border: '2px solid #273F82'
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'transparent',
            color: '#52659B',
            fontWeight: 700,
            top: '2px'
          },
          '& .MuiSlider-mark': {
            height: '6px',
            borderRadius: '1px',
            backgroundColor: '#B6B7BC',
            top: '100%'
          },
          '& > :not(style)': {
            lineHeight: '2'
          }
        },
        valueLabel: {
          lineHeight: 2,
          backgrounColor: 'red'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          lineHeight: '18px',
          '&.Mui-disabled': { background: '#A9B2CD' },
          '.MuiButtonBase-root-MuiListItem-root.Mui-selected': {
            backgroundColor: 'red',
            color: 'white'
          },
          '&.MuiListItemButton-root': {
            height: '49px',
            margin: 0,
            marginTop: '14.25px',
            '&:hover': {
              background: '#17264E',
              borderRadius: '8px'
            }
          }
        },
        sizeSmall: { fontSize: '12px', height: '30px' },
        sizeMedium: { fontSize: '14px', height: '40px' },
        sizeLarge: { fontSize: '16px', height: '40px' }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.MuiListItemButton-root': {
            height: '49px',
            margin: 0,
            marginTop: '14.25px'
          },
          '&:hover': {
            background: '#17264E',
            borderRadius: '8px'
          },
          '&.Mui-selected': {
            background: '#17264E',
            borderRadius: '8px'
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          // '&$selected': {
          //   backgroundColor: 'red !important',
          //   color: 'white',
          //   '& .MuiListItemIcon-root': {
          //     color: 'white',
          //   },
          // },
          // '&:hover': {
          //   backgroundColor: 'green',
          // },
          '&.MuiListItem-root': {
            height: '49px',
            margin: 0,
            marginTop: '14.25px',
            '&:hover': {
              background: '#17264E',
              borderRadius: '8px'
            }
          },
          '&.Mui-selected': {
            background: '#17264E',
            borderRadius: '8px'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          lineHeight: '24px',
          fontSize: '14px',
          fontWeight: 'bold',
          textDecorationColor: '#ffffff',
          underline: 'hover'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // background: '#0A0F1F',
          fontSize: '15px',
          lineHeight: '21px',
          color: '#273F82',

          '.sidebar-container': {
            color: '#A9B2CD'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '52px'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            display: 'flex',
            justifyContent: 'space-between'
          },
          '.MuiDataGrid-cell': {
            borderBottom: '1px solid #E7E7E9'
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '.MuiDataGrid-footerContainer': {
            height: '96px'
          },
          '.MuiTablePagination-displayedRows': {
            color: '#9D9FA5',
            lineHeight: '1.4375em'
          },
          '.MuiTablePagination-actions': {
            lineHeight: '1.4375em'
          },
          '.MuiTablePagination-selectLabel': {
            lineHeight: '1.4375em'
          },
          '.MuiDataGrid-main': {
            fontSize: '13px'
          }
        }
      }
    }
  }
});

export default yms;
