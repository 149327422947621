enum UscgSafetyActionsTypes {
  CREATE_USCG_SAFETY_IN_PROGRESS = 'CREATE_USCG_SAFETY_IN_PROGRESS',
  CREATE_USCG_SAFETY_SUCCESS = 'CREATE_USCG_SAFETY_SUCCESS',
  CREATE_USCG_SAFETY_ERROR = 'CREATE_USCG_SAFETY_ERROR',

  LIST_USCG_SAFETY_IN_PROGRESS = 'FETCH_USCG_SAFETY_LIST_IN_PROGRESS',
  LIST_USCG_SAFETY_SUCCESS = 'LIST_USCG_SAFETY_SUCCESS',
  LIST_USCG_SAFETY_ERROR = 'LIST_USCG_SAFETY_ERROR',

  VIEW_USCG_SAFETY_IN_PROGRESS = 'VIEW_USCG_SAFETY_IN_PROGRESS',
  VIEW_USCG_SAFETY_SUCCESS = 'VIEW_USCG_SAFETY_SUCCESS',
  VIEW_USCG_SAFETY_ERROR = 'VIEW_USCG_SAFETY_ERROR',

  UPDATE_USCG_SAFETY_IN_PROGRESS = 'UPDATE_USCG_SAFETY_IN_PROGRESS',
  UPDATE_USCG_SAFETY_SUCCESS = 'UPDATE_USCG_SAFETY_SUCCESS',
  UPDATE_USCG_SAFETY_ERROR = 'UPDATE_USCG_SAFETY_ERROR',

  DELETE_USCG_SAFETY_IN_PROGRESS = 'DELETE_USCG_SAFETY_IN_PROGRESS',
  DELETE_USCG_SAFETY_SUCCESS = 'DELETE_USCG_SAFETY_SUCCESS',
  DELETE_USCG_SAFETY_ERROR = 'DELETE_USCG_SAFETY_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default UscgSafetyActionsTypes;
