import { FC } from 'react';
import './TextTag.scss';

type Props = {
  text: string;
  tag: string;
};

const TextTag: FC<Props> = ({ text, tag }) => {
  return (
    <>
      <span className="spanText">{text}</span>
      <div className="tag">
        <span>{tag}</span>
      </div>
    </>
  );
};
export default TextTag;
