import { ReactElement } from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import paths from '../../utils/constants/paths';
import HeaderText from '../headers/HeaderText';
import HeaderBack from '../headers/HeaderBack';
import HeaderWelcome from '../headers/HeaderWelcome';

const TopBar = ({ handleDrawerToggle, drawerWidth }: any) => {
  const location = useLocation();

  const handleHeaderContent = (): ReactElement => {
    const propertyValues = Object.values(paths);
    const found = propertyValues.find((item) => item.absolutePath === location.pathname);

    switch (found?.header) {
      case 'welcome':
        return <HeaderWelcome />;
      case 'back':
        return <HeaderBack />;
      default:
        return <HeaderText text={found?.header} />;
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: '#fff',
          boxShadow: 'none'
        }}
      >
        <Toolbar style={{ height: '94px' }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {handleHeaderContent()}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
