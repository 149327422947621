import Joi from 'joi';
import { checklistGroupInitialState, getFieldsType } from '../context/reducers/checklistReducer';
import { ChecklistGroup } from './types/checklist';

export const validateForm = (schema: Joi.ObjectSchema<any>, form: any) => {
  const { error, value } = schema.validate(form);
  const errorSummary: any = {};

  if (error) {
    error.details.forEach((err) => {
      errorSummary[err.path.join('.')] = err.message;
    });
  }

  return { errorSummary, value };
};

export const validateFormArray = (schema: Joi.ArraySchema<any[]>, form: any) => {
  const { error, value } = schema.validate(form);
  const errorSummary: any[] = [];

  if (error) {
    error.details.forEach((err: any) => {
      errorSummary[err.path[0]] = {
        ...errorSummary[err.path[0]],
        [err.path[1]]: err.message.split('"')[2]
      };
    });
  }

  return { errorSummary, value };
};

export const validateGroupFieldsForm = (schema: Joi.ObjectSchema<any>, fieldsGroup: any) => {
  const errorSummary: any = [];

  fieldsGroup.forEach((field: any) => {
    const { error: errorField } = schema.validate(field);
    const errorSummaryField: any = {};

    if (errorField) {
      errorField.details.forEach((err) => {
        errorSummaryField['field'] = 'text';
        errorSummaryField[err.path.join('.')] = err.message;
      });

      errorSummary.push(errorSummaryField);
    } else {
      errorSummary.push(checklistGroupInitialState);
    }
  });

  return { errorSummary };
};

export const isValidForm = (form: ChecklistGroup): boolean => {
  let isValidFields = true;

  form.fields.forEach((field) => {
    if (!field.name || !(field.type in getFieldsType())) {
      isValidFields = false;
    }
  });

  return !form.name && isValidFields;
};
