import BlogsEntryActionTypes from '../actions/types/blogEntry';
import {
  BlogEntryAction,
  BlogEntryStateInterface,
  BlogEntryInterface
} from '../../utils/types/blogEntry';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<BlogEntryInterface>()

export const blogEntryInitialState: BlogEntryInterface = {
  id: '',
  title: '',
  published: false,
  excerpt: '',
  text: '',
  storeable_asset_id: '',
  image_caption: '',
  updatedAt: '',
  createdAt: '',
  deletedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: BlogEntryStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: { ...actionInitialState, data: blogEntryInitialState },
  update: actionInitialState,
  delete: actionInitialState
};

export const blogEntryReducer = (
  state = initialState,
  action: BlogEntryAction
): BlogEntryStateInterface => {
  switch (action.type) {
    case BlogsEntryActionTypes.CREATE_BLOG_ENTRY_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case BlogsEntryActionTypes.CREATE_BLOG_ENTRY_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case BlogsEntryActionTypes.CREATE_BLOG_ENTRY_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case BlogsEntryActionTypes.LIST_BLOG_ENTRIES_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case BlogsEntryActionTypes.LIST_BLOG_ENTRIES_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          error: undefined,
          done: true,
          data: action.payload as PaginatedListInterface<BlogEntryInterface>
        }
      };
    case BlogsEntryActionTypes.LIST_BLOG_ENTRIES_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case BlogsEntryActionTypes.VIEW_BLOG_ENTRY_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case BlogsEntryActionTypes.VIEW_BLOG_ENTRY_SUCCESS:
      return {
        ...state,
        view: {
          loading: false,
          error: undefined,
          done: true,
          data: action.payload as BlogEntryInterface
        }
      };
    case BlogsEntryActionTypes.VIEW_BLOG_ENTRY_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case BlogsEntryActionTypes.UPDATE_BLOG_ENTRY_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case BlogsEntryActionTypes.UPDATE_BLOG_ENTRY_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case BlogsEntryActionTypes.UPDATE_BLOG_ENTRY_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case BlogsEntryActionTypes.DELETE_BLOG_ENTRY_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case BlogsEntryActionTypes.DELETE_BLOG_ENTRY_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case BlogsEntryActionTypes.DELETE_BLOG_ENTRY_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case BlogsEntryActionTypes.RESET_STATE:
      return initialState;
  }
};
