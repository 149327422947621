import { PaginatedResultInterface } from '../interfaces/PaginatedResultInterface';
import { SystemInfoTemplateInterface } from '../utils/types/systemInfoTemplate';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type CreateSystemInfoTemplateDto = {
  name: string;
  template: string;
};

export type UpdateSystemInfoTemplateDto = {
  name: string;
  template: string;
};

export default class SystemInfoTemplateService extends ApiService {
  create = async (dto: CreateSystemInfoTemplateDto) => {
    const { data } = await this.axios.post('system-info-template', dto);

    return data;
  };

  list = async (
    pagination: Pagination
  ): Promise<PaginatedResultInterface<SystemInfoTemplateInterface[]>> => {
    const { data } = await this.axios.get('system-info-templates', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`system-info-template/${id}`);

    return data;
  };

  update = async (id: string, dto: UpdateSystemInfoTemplateDto) => {
    const { data } = await this.axios.put(`system-info-template/${id}`, dto);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`system-info-template/${id}`);

    return data;
  };
}
