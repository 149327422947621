import ServiceRecordsService from '../../services/serviceRecord';
import { Pagination } from '../../utils/types/pagination';
import { CreateServiceRecordDto } from '../../utils/types/serviceRecord';
import ServiceRecordActionsTypes from './types/serviceRecord';
import { errorPayload } from './utils/errorPayload';

export const createServiceRecord = (dispatch: any) => {
  return async (dto: CreateServiceRecordDto) => {
    dispatch({ type: ServiceRecordActionsTypes.CREATE_SERVICE_RECORD_IN_PROGRESS });

    try {
      const servicesRecordsService = new ServiceRecordsService();
      await servicesRecordsService.create(dto);

      dispatch({ type: ServiceRecordActionsTypes.CREATE_SERVICE_RECORD_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ServiceRecordActionsTypes.CREATE_SERVICE_RECORD_ERROR, payload });
    }
  };
};

export const listServiceRecords = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: ServiceRecordActionsTypes.LIST_SERVICE_RECORDS_IN_PROGRESS });

    try {
      const servicesRecordsService = new ServiceRecordsService();
      const servicesRecords = await servicesRecordsService.list(pagination);

      dispatch({
        type: ServiceRecordActionsTypes.LIST_SERVICE_RECORDS_SUCCESS,
        payload: servicesRecords
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ServiceRecordActionsTypes.LIST_SERVICE_RECORDS_ERROR, payload });
    }
  };
};

export const deleteServiceRecord = (dispatch: any) => {
  const servicesRecordsService = new ServiceRecordsService();

  return async (id: string) => {
    dispatch({ type: ServiceRecordActionsTypes.DELETE_SERVICE_RECORD_IN_PROGRESS });

    try {
      const result = await servicesRecordsService.delete(id);

      dispatch({ type: ServiceRecordActionsTypes.DELETE_SERVICE_RECORD_SUCCESS, payload: result });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ServiceRecordActionsTypes.DELETE_SERVICE_RECORD_ERROR, payload });
    }
  };
};

export const resetServiceRecordState = (dispatch: any) => {
  return async () => dispatch({ type: ServiceRecordActionsTypes.RESET_STATE });
};
