import { FC, ReactElement, useContext, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom'
import { VesselInterface } from '../../utils/types/vessel';
import ServiceCardList from '../../components/service-card/ServiceCardList';
import TitlePage from '../../components/title-page/TitlePage';
// import { CrewRequestContext } from '../../context/crewRequestContext'
// import { ChecklistContext } from '../../context/checklistContext'
import './Vessels.scss';
import { VesselContext } from '../../context/vesselContext';
import { useQueryParams } from '../../hooks/useQueryParams';

export type Notification = {
  service: string;
  count: number;
};

const VesselSections: FC = (): ReactElement => {
  const query = useQueryParams();
  const { viewVessel, state } = useContext(VesselContext);
  const [vesselId] = useState<string>(query.get('id') || '');
  const [vessel, setVessel] = useState<VesselInterface | undefined>(undefined);

  useEffect(() => {
    if (vesselId) viewVessel(vesselId, { join: 'owner' });
  }, []);

  useEffect(() => {
    if (state.view.data) setVessel(state.view.data);
  }, [state.view]);

  return (
    <>
      <div className="vessel-sections-title">
        <TitlePage leftContainer={<div className="vessel-grid">{vessel?.name}</div>} />
      </div>
      {vessel && <ServiceCardList vesselId={vesselId} />}
    </>
  );
};

export default VesselSections;
