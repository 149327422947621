export const debounce = (func: (...args: any) => void, wait: number) => {
  let timeout: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => func(...args), wait);
  };
};

export const documentTypes = ['pdf', 'doc', 'xls', 'ppt', 'txt'];
export const mediaTypes = [ 'mp4', 'mov', 'avi', 'wmv', 'mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'm4v', 'mkv', 'webm'];

export const isImageType = (mimeType: string) => {
  return mimeType.includes('image');
};

export const isDocumentType = (mimeType: string) => {
  return documentTypes.some((type) => mimeType.includes(type));
};

export const isMediaType = (mimeType: string) => {
  return mediaTypes.some((type) => mimeType.includes(type));
};
