import moment from 'moment';
import { SystemInfoInterface } from '../../../utils/types/systemInfo';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { MRT_ColumnDef } from 'material-react-table';
import { UserInterface } from '../../../utils/types/user';
import IconButton from '@mui/material/IconButton';

const getSystemInfoGridColumns = (
  onEditSystemInfo: (systemInfo: SystemInfoInterface) => void,
  onDeleteSystemInfo: (systemInfo: SystemInfoInterface) => void
): Array<MRT_ColumnDef<SystemInfoInterface>> => [
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'state',
      header: 'State'
    },
    {
      accessorKey: 'creator',
      header: 'Created by',
      Cell: ({ cell }) => {
        const creator = cell.getValue<UserInterface>();

        return (
          <span>
            {creator ? creator.name + ' ' + creator.lastName : ''}
          </span>
        );
      }
    },
    {
      accessorKey: 'operator',
      header: 'Last Updated By',
      Cell: ({ cell }) => {
        const operator = cell.getValue<UserInterface>();

        return (
          <span>
            {operator ? operator.name + ' ' + operator.lastName : ''}
          </span>
        );
      }
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated at',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditSystemInfo(cell.row.original)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDeleteSystemInfo(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    },
  ];

export default getSystemInfoGridColumns;
