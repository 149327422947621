import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BlogIcon } from '../../assets/images/first-line-blog-wrapper.svg';
import InputStorableAsset from '../../components/form/InputStorableAsset';
import InputText from '../../components/form/InputText';
import InputWysiwyg from '../../components/form/InputWysiwyg';
import { BlogEntryContext } from '../../context/blogEntryContext';
import { NotificationContext } from '../../context/notificationContext';
import { StorableAssetContext } from '../../context/storableAssetContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { CreateBlogEntryDto } from '../../services/blogEntry';
import paths from '../../utils/constants/paths';
import { validateForm } from '../../utils/form';
import TemplateBox from '../form-templates/TemplateBox';
import { blogEntrySchema } from './BlogEntrySchema';

const createBlogEntryFormInitialState: CreateBlogEntryDto = {
  title: '',
  excerpt: '',
  text: '',
  storeable_asset_id: '',
  image_caption: '',
  published: false
};

const createBlogEntryFormValidationErrorsInitialState: CreateBlogEntryDto = {
  title: '',
  excerpt: '',
  text: '',
  storeable_asset_id: '',
  image_caption: '',
  published: false
};

const BlogEntryCreate: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const {
    createBlogEntry,
    viewBlogEntry,
    updateBlogEntry,
    resetBlogEntryState,
    state: blogEntryState
  } = useContext(BlogEntryContext);
  const { state: storableAssetState } = useContext(StorableAssetContext);
  const { showAlert } = useContext(NotificationContext);
  const [form, setForm] = useState<CreateBlogEntryDto>(createBlogEntryFormInitialState);
  const [formValidationErrors, setFormValidationErrors] = useState<CreateBlogEntryDto>(
    createBlogEntryFormValidationErrorsInitialState
  );

  const [blogEntryId] = useState<string | null>(query.get('id') || '');

  useEffect(() => {
    resetBlogEntryState();

    if (blogEntryId) viewBlogEntry(blogEntryId);
  }, []);

  useEffect(() => {
    if (blogEntryState.view.data?.id) {
      setForm({
        title: blogEntryState.view.data.title,
        excerpt: blogEntryState.view.data.excerpt,
        text: blogEntryState.view.data.text,
        storeable_asset_id: blogEntryState.view.data.storeable_asset_id,
        image_caption: blogEntryState.view.data.image_caption,
        published: blogEntryState.view.data.published
      });
    } else {
      setForm(createBlogEntryFormInitialState);
    }
  }, [blogEntryState.view]);

  useEffect(() => {
    if (blogEntryState.create.done) {
      showAlert({
        variant: 'success',
        title: 'Done',
        description: 'Blog entry created!'
      });

      resetBlogEntryState();
      navigate({ pathname: paths.BLOG_ENTRIES.absolutePath });
    }

    if (blogEntryState.create.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "Blog entry couldn't be created!"
      });
    }
  }, [blogEntryState.create]);

  useEffect(() => {
    if (blogEntryState.update.done) {
      showAlert({
        variant: 'success',
        title: 'Done',
        description: 'Blog entry updated!'
      });

      resetBlogEntryState();
      navigate({ pathname: paths.BLOG_ENTRIES.absolutePath });
    }

    if (blogEntryState.update.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "Blog entry couldn't be updated!"
      });
    }
  }, [blogEntryState.update]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleWysiwygChange = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmitForm = (published: boolean) => {
    const { errorSummary } = validateForm(blogEntrySchema, form);

    setFormValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) {
      if (!blogEntryId) createBlogEntry({ ...form, published });
      else updateBlogEntry(blogEntryId, { ...form, published });
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        style={{ padding: '46px 0 27px 0', alignItems: 'center' }}
      >
        <Grid item xs={2} sm={1} md={0.8} lg={0.5} xl={0.3}>
          <BlogIcon />
        </Grid>
        <Grid item xs={10} sm={10} md={5} lg={5} xl={5}>
          <p style={{ color: '#273F82', fontWeight: 'bold', marginBottom: '20px' }}>
            {blogEntryId ? 'Edit post' : 'New post'}
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1.5} />
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1.5} />
        <Grid item xs={12} sm={12} md={1} lg={1.5} xl={1.5}>
          <Button
            variant="outlined"
            type="button"
            onClick={() => handleSubmitForm(false)}
            style={{ width: '100%', marginBottom: '20px' }}
            disabled={
              blogEntryState.create.loading ||
              blogEntryState.update.loading ||
              storableAssetState.upload.loading
            }
          >
            Save as draft
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={1.5} lg={1.5} xl={1}>
          <Button
            variant="contained"
            type="button"
            onClick={() => handleSubmitForm(true)}
            style={{ width: '100%', marginBottom: '20px' }}
            disabled={
              blogEntryState.create.loading ||
              blogEntryState.update.loading ||
              storableAssetState.upload.loading
            }
          >
            Publish
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TemplateBox minHeight={'calc(100vh - 240px)'}>
          <InputText
            name={'title'}
            label={'Title'}
            value={form.title}
            validationErrors={formValidationErrors.title}
            onChange={handleInputChange}
            width={'100%'}
          />

          <InputText
            name={'excerpt'}
            label={'Excerpt'}
            value={form.excerpt}
            validationErrors={formValidationErrors.excerpt}
            onChange={handleInputChange}
            width={'100%'}
            multiline={true}
            minRows={7}
            maxLength={215}
          />

          <InputWysiwyg
            name={'text'}
            label={'Content'}
            value={form.text}
            validationErrors={formValidationErrors.text}
            onChange={handleWysiwygChange}
            width={'100%'}
          />

          <InputStorableAsset
            label={'Upload image'}
            name={'storeable_asset_id'}
            value={form?.storeable_asset_id}
            automaticUpload={true}
            assetType="blog-image"
            vesselId={'blog-image'}
            visibility="public"
            validationErrors={formValidationErrors.storeable_asset_id}
            onChange={handleInputChange}
          />

          <InputText
            name={'image_caption'}
            label={'Image caption'}
            value={form.image_caption}
            validationErrors={formValidationErrors.image_caption}
            onChange={handleInputChange}
            width={'100%'}
          />
        </TemplateBox>
      </Grid>
    </Grid>
  );
};

export default BlogEntryCreate;
