import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { VesselInterface } from '../../utils/types/vessel';
import { MRT_ColumnDef } from 'material-react-table';
import { UserInterface } from '../../utils/types/user';
import IconButton from '@mui/material/IconButton';

const getVesselsGridColumns = (
  onEditVessel: (checklist: VesselInterface) => void
): Array<MRT_ColumnDef<VesselInterface>> => [
    {
      accessorKey: 'hullNumber',
      header: 'Hull Number',
      Cell: ({ cell }) => {
        const value = cell.getValue<string>();

        return <span>{value}</span>;
      }
    },
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'owner',
      header: 'Owner',
      enableSorting: false,
      Cell: ({ cell }) => {
        const owner = cell.getValue<UserInterface>();

        return owner ? `${owner.name} ${owner.lastName}` : '--'
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditVessel(cell.row.original)}
          >
            <EditIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getVesselsGridColumns;
