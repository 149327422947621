enum ChecklistActionsTypes {
  CREATE_CHECKLIST_IN_PROGRESS = 'CREATE_CHECKLIST_IN_PROGRESS',
  CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS',
  CREATE_CHECKLIST_ERROR = 'CREATE_CHECKLIST_ERROR',

  LIST_CHECKLISTS_IN_PROGRESS = 'LIST_CHECKLISTS_IN_PROGRESS',
  LIST_CHECKLISTS_SUCCESS = 'LIST_CHECKLISTS_SUCCESS',
  LIST_CHECKLISTS_ERROR = 'LIST_CHECKLISTS_ERROR',

  LIST_CHECKLISTS_HISTORY_IN_PROGRESS = 'LIST_CHECKLISTS_HISTORY_IN_PROGRESS',
  LIST_CHECKLISTS_HISTORY_SUCCESS = 'LIST_CHECKLISTS_HISTORY_SUCCESS',
  LIST_CHECKLISTS_HISTORY_ERROR = 'LIST_CHECKLISTS_HISTORY_ERROR',

  VIEW_CHECKLIST_IN_PROGRESS = 'VIEW_CHECKLIST_IN_PROGRESS',
  VIEW_CHECKLIST_SUCCESS = 'VIEW_CHECKLIST_SUCCESS',
  VIEW_CHECKLIST_ERROR = 'VIEW_CHECKLIST_ERROR',

  UPDATE_CHECKLIST_IN_PROGRESS = 'UPDATE_CHECKLIST_IN_PROGRESS',
  UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS',
  UPDATE_CHECKLIST_ERROR = 'UPDATE_CHECKLIST_ERROR',

  DELETE_CHECKLIST_IN_PROGRESS = 'DELETE_CHECKLIST_IN_PROGRESS',
  DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS',
  DELETE_CHECKLIST_ERROR = 'DELETE_CHECKLIST_ERROR',

  COMPLETE_CHECKLIST_IN_PROGRESS = 'COMPLETE_CHECKLIST_IN_PROGRESS',
  COMPLETE_CHECKLIST_SUCCESS = 'COMPLETE_CHECKLIST_SUCCESS',
  COMPLETE_CHECKLIST_ERROR = 'COMPLETE_CHECKLIST_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default ChecklistActionsTypes;
