import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../../components/dialog/AlertDialog';
import FieldsFactory from '../../../components/fieldsFactory/FieldsFactory';
import ServiceActions from '../../../components/service-actions/ServiceActions';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { ChecklistContext } from '../../../context/checklistContext';
import { ChecklistTemplateContext } from '../../../context/checklistTemplateContext';
import { NotificationContext } from '../../../context/notificationContext';
import { checklistInitialState } from '../../../context/reducers/checklistReducer';
import { VesselContext } from '../../../context/vesselContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import paths from '../../../utils/constants/paths';
import {
  ChecklistGroup,
  ChecklistInterface,
  ChecklistItemInterface
} from '../../../utils/types/checklist';
import { onChangeTypes } from '../../../utils/types/common';
import TemplateBox from '../../form-templates/TemplateBox';
import './ChecklistEdit.scss';

const ChecklistEdit: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const {
    viewChecklistTemplate,
    resetChecklistTemplateState,
    state: checklistTemplateState
  } = useContext(ChecklistTemplateContext);
  const {
    createChecklist,
    viewChecklist,
    updateChecklist,
    completeChecklist,
    resetChecklistState,
    state: checklistState
  } = useContext(ChecklistContext);
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { showAlert } = useContext(NotificationContext);
  const [checklistId] = useState<string | null>(query.get('id') || '');
  const [checklistTemplateId] = useState<string | null>(query.get('checklistTemplateId') || '');
  const [vesselId] = useState<string | null>(query.get('vesselId') || '');
  const [openDialog, setOpenDialog] = useState(false);
  const [template, setTemplate] = useState<ChecklistGroup[]>([]);
  const [checklist, setChecklist] = useState<ChecklistInterface>(checklistInitialState);

  useEffect(() => {
    resetChecklistTemplateState();
    resetChecklistState();

    if (checklistId) viewChecklist(checklistId);
    if (!vesselState.view.data?.id && vesselId) viewVessel(vesselId);
    if (checklistTemplateId) viewChecklistTemplate(checklistTemplateId);
  }, []);

  useEffect(() => {
    if (checklistTemplateState.view.data?.template) {
      const parsedTemplate = JSON.parse(checklistTemplateState.view.data?.template || '{}');

      setTemplate(parsedTemplate);
    } else {
      setTemplate([]);
    }
  }, [checklistTemplateState.view.data]);

  useEffect(() => {
    if (checklistState.view.data?.template) {
      const parsedTemplate = JSON.parse(checklistState.view.data?.template || '{}');

      setChecklist(checklistState.view.data);
      setTemplate(parsedTemplate);
    } else {
      setChecklist(checklistInitialState);
      setTemplate([]);
    }
  }, [checklistState.view.data]);

  useEffect(() => {
    if (checklistState.create.done) {
      showAlert({
        variant: 'success',
        title: 'Checklist',
        description: 'Checklist created!'
      });

      resetChecklistState();
      navigate({
        pathname: paths.VESSEL_CHECKLIST.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (checklistState.create.error) {
      showAlert({
        variant: 'error',
        title: 'Checklist',
        description: "Checklist couldn't be created!"
      });
    }
  }, [checklistState.create]);

  useEffect(() => {
    if (checklistState.update.done) {
      showAlert({
        variant: 'success',
        title: 'Checklist',
        description: 'Checklist updated!'
      });

      resetChecklistState();
      navigate({
        pathname: paths.VESSEL_CHECKLIST.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (checklistState.update.error) {
      showAlert({
        variant: 'error',
        title: 'Checklist',
        description: "Checklist couldn't be updated!"
      });
    }
  }, [checklistState.update]);

  useEffect(() => {
    if (checklistState.complete.done) {
      showAlert({
        variant: 'success',
        title: 'Checklist',
        description: 'Checklist completed!'
      });

      resetChecklistState();
      navigate({
        pathname: paths.VESSEL_CHECKLIST.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (checklistState.complete.error) {
      showAlert({
        variant: 'error',
        title: 'Checklist',
        description: "Checklist couldn't be completed!"
      });
    }
  }, [checklistState.complete]);

  const changed = (e: onChangeTypes, name: string, fieldName: string) => {
    const tempChecklist = { ...checklist };
    const tempChecklistGroups = template;
    const group = template.find((g) => g.name === name);
    const groupIndex = template.findIndex((g) => g.name === name);

    if (group) {
      const tempFields = [...group?.fields];
      const indexField = group?.fields.findIndex((field) => field.name === fieldName);
      let field = group?.fields[indexField];

      switch (field?.type) {
        case 'text':
          tempFields[indexField].value = (e as ChangeEvent<HTMLInputElement>).target.value;
          tempChecklistGroups[groupIndex].fields = tempFields;
          tempChecklist.template = JSON.stringify(tempChecklistGroups);

          setChecklist(tempChecklist);

          if (tempFields[indexField].value && tempFields[indexField].value !== undefined) {
            tempFields[indexField].status = true;
          } else {
            tempFields[indexField].status = false;
          }

          return;
        case 'checkBox':
          tempFields[indexField].value = (e as ChangeEvent<HTMLInputElement>).target.checked;
          tempFields[indexField].status = (e as ChangeEvent<HTMLInputElement>).target.checked;
          tempChecklistGroups[groupIndex].fields = tempFields;
          tempChecklist.template = JSON.stringify(tempChecklistGroups);

          setChecklist(tempChecklist);
          return;
      }
    }
  };

  const handleCompleteSubmit = () => {
    setOpenDialog(false);
    completeChecklist(checklist.id);
  };

  const fields = (fields: ChecklistItemInterface[], name: string) => {
    return (
      <Box sx={boxStyle}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
        >
          {fields.map((field, index) => {
            return (
              <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
                <FieldsFactory
                  type={field.type}
                  name={field.name}
                  rest={{
                    onChange: (e: onChangeTypes) => changed(e, name, field.name),
                    value: field.value as any
                  }}
                  disabled={false}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const checklistFields = () => {
    if (template) {
      return template.map((group, index) => {
        return (
          <div key={index}>
            <p className="group-fields-name">{group.name}</p>
            {fields(group.fields, group.name)}
          </div>
        );
      });
    }
  };

  const completeChecklistDialog = (
    <AlertDialog
      content={
        <Grid
          container
          direction="row"
          alignItems="center"
          margin={'auto'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
            <span className="complete-checklist-modal">
              Are you sure you want complete the checklist? This action can not be undone
            </span>
          </Grid>
        </Grid>
      }
      cancelText={'No'}
      approveText={'Yes'}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      onSave={handleCompleteSubmit}
    />
  );

  const handleSave = () => {
    if (checklistTemplateId)
      createChecklist({
        name: checklistTemplateState.view.data?.name || '',
        template: JSON.stringify(template),
        template_id: checklistTemplateId,
        vessel_id: vesselId || ''
      });

    if (checklistId)
      updateChecklist(checklistId, {
        name: checklist.name,
        template: JSON.stringify(template),
        vessel_id: checklist.vessel_id
      });
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || ''}
            middle={'Checklist'}
            current={checklistState.view.data?.name || ''}
          />
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={gridContainerStyle}>
        <TemplateBox minHeight={'calc(100vh - 240px)'} paperStyle={paperStyle}>
          <div>{checklistFields()}</div>
          {!checklist.completed_by && (
            <div style={{ marginTop: '30px' }}>
              <ServiceActions
                disabled={checklistState.create.loading || checklistState.update.loading}
                onSave={handleSave}
                onCompleteService={checklistTemplateId ? undefined : () => setOpenDialog(true)}
              />
            </div>
          )}
        </TemplateBox>
      </Grid>
      {openDialog && completeChecklistDialog}
    </>
  );
};

export default ChecklistEdit;

const boxStyle = {
  '& > :not(style)': {
    padding: '1em',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D4D9E6',
    borderRadius: '8px'
  }
};
const paperStyle = { display: 'grid', alignContent: 'space-between' };
const gridContainerStyle = { marginTop: '71px', marginBottom: '49px' };
