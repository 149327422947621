import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import InputText from '../../../components/form/InputText';
import TemplateBoxTwoFields from '../TemplateBoxTwoFields';
import FieldContainerExternal from '../../../components/field-container/FieldContainer';
import {
  checklistGroupInitialState,
  fieldInitialState
} from '../../../context/reducers/checklistReducer';
import {
  ChecklistGroup,
  ChecklistItemInterface,
  FieldsTypes
} from '../../../utils/types/checklist';
// import { getFieldsType } from '../../../context/reducers/checklistReducer'

interface CheckListArgs {
  groupInfo: (name: string, fields: ChecklistItemInterface[]) => void;
  group: ChecklistGroup;
  errorsMessages: {
    name: string;
    fields: {
      name: string;
      type: string;
      value: string;
      status: string;
    }[];
  };
}

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={12} sm={12} md={12} lg={12} xl={12} />;
};

type OnChangeEventType = ChangeEvent<HTMLInputElement>;

const ChecklistForm = forwardRef<{ getGroupInfo(): void }, CheckListArgs>(
  ({ groupInfo, group, errorsMessages }, ref): ReactElement => {
    const [groupName, setGroupName] = useState(checklistGroupInitialState.name);
    const [groupFields, setGroupFields] = useState<ChecklistItemInterface[]>([]);
    const [validationErrors, setValidationErrors] = useState(errorsMessages);

    useEffect(() => {
      if (group) {
        setGroupName(group.name);
        setGroupFields(group.fields);
      }
    }, [group]);

    useEffect(() => {
      setValidationErrors(errorsMessages);
    }, [errorsMessages]);

    useImperativeHandle(ref, () => ({
      getGroupInfo: () => groupInfo(groupName, groupFields)
    }));

    const addField = () => {
      const newChecklist = [...groupFields, fieldInitialState];
      setGroupFields(newChecklist);
      setValidationErrors((errors) => ({
        ...errors,
        groupFields: [...errors.fields, fieldInitialState]
      }));
    };

    const removeField = (index: number) => {
      const newGroupFields = groupFields.filter((_item, i) => i !== index);
      setGroupFields(newGroupFields);
    };

    const onChangeValues = (field: string, type: FieldsTypes, index: number) => {
      if (field === 'name') {
        const newChecklist = groupFields.map((item, i) =>
          i === index ? { ...item, name: type } : item
        );
        setGroupFields(newChecklist);
      } else {
        const newChecklist = groupFields.map((item, i) =>
          i === index ? { ...item, type: type, value: type === 'checkBox' ? false : '' } : item
        );
        setGroupFields(newChecklist);
      }
    };

    const groupNameField = (
      <FieldContainer>
        <InputText
          name={'groupName'}
          label={'Group name'}
          onChange={(e: OnChangeEventType) => setGroupName(e.target.value)}
          value={groupName}
          validationErrors={validationErrors?.name}
        />
      </FieldContainer>
    );

    const addFieldButton = (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button
          variant="outlined"
          type="button"
          className="actionButton"
          onClick={() => addField()}
        >
          Add field
        </Button>
      </Grid>
    );

    const renderFields = groupFields.map((item, i) => {
      return (
        <div key={i}>
          <TemplateBoxTwoFields
            showDeleteIcon={i !== 0}
            item={item}
            boxValues={['checkBox', 'text']}
            onDelete={() => removeField(i)}
            validationErrors={validationErrors?.fields[i]}
            onChangeFieldName={(e) => onChangeValues('name', e as FieldsTypes, i)}
            handleDropdownChange={(e) => onChangeValues('type', e as FieldsTypes, i)}
          />
          <div style={dividerStyle} />
        </div>
      );
    });

    return (
      <>
        {groupNameField}
        {renderFields}
        {addFieldButton}
      </>
    );
  }
);

export default ChecklistForm;

const dividerStyle = { marginBottom: '24px', width: '100%' };
