import ChecklistTemplateActionsTypes from './types/checklistTemplate';
import ChecklistTemplateService, {
  CreateChecklistTemplateDto,
  UpdateChecklistTemplateDto
} from '../../services/checklistTemplate';
import { errorPayload } from './utils/errorPayload';
import { Pagination } from '../../utils/types/pagination';

export const listChecklistTemplates = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_IN_PROGRESS });

    try {
      const checklistTemplateService = new ChecklistTemplateService();
      const checklistTemplatePaginated = await checklistTemplateService.findAll(pagination);

      dispatch({
        type: ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_SUCCESS,
        payload: checklistTemplatePaginated
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_ERROR, payload });
    }
  };
};

export const viewChecklistTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_IN_PROGRESS });

    try {
      const checklistTemplateService = new ChecklistTemplateService();
      const checklistTemplates = await checklistTemplateService.getChecklistTemplate(id);

      dispatch({
        type: ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_SUCCESS,
        payload: checklistTemplates
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_ERROR, payload });
    }
  };
};

export const deleteChecklistTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_IN_PROGRESS });

    try {
      const result = await new ChecklistTemplateService().deleteChecklist(id);

      if (result) {
        dispatch({ type: ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_SUCCESS });
      } else {
        throw { code: 400, message: result };
      }
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_ERROR, payload });
    }
  };
};

export const createChecklistTemplate = (dispatch: any) => {
  return async (dto: CreateChecklistTemplateDto) => {
    dispatch({ type: ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_IN_PROGRESS });

    try {
      const result = await new ChecklistTemplateService().create(dto);

      if (result) {
        dispatch({ type: ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_SUCCESS });
      } else throw { code: 400, message: result };
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_ERROR, payload });
    }
  };
};

export const updateChecklistTemplate = (dispatch: any) => {
  return async (id: string, dto: UpdateChecklistTemplateDto) => {
    dispatch({ type: ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_IN_PROGRESS });

    try {
      const result = await new ChecklistTemplateService().update(id, dto);

      if (result) {
        dispatch({ type: ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_SUCCESS });
      } else throw { code: 400, message: result };
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_ERROR, payload });
    }
  };
};

export const resetChecklistTemplateState = (dispatch: any) => {
  return async () => {
    dispatch({ type: ChecklistTemplateActionsTypes.RESET_STATE });
  };
};
