import VesselsService from '../../services/vessels';
import { CreateVesselInterface, VesselInterface } from '../../utils/types/vessel';
import VesselActionsTypes from './types/vessel';
import { showNotificationType } from '../../utils/types/notification';
import { Pagination } from '../../utils/types/pagination';

export const createVessel = (dispatch: any) => {
  return async (
    attributes: CreateVesselInterface /* showAlert: (args: showNotificationType) => void */
  ) => {
    const vesselService = new VesselsService();

    dispatch({ type: VesselActionsTypes.CREATE_VESSEL_IN_PROGRESS });

    try {
      const createdVessel = await vesselService.createVessel(attributes);

      if (createdVessel.id) {
        dispatch({ type: VesselActionsTypes.CREATE_VESSEL_SUCCESS });
      } else {
        throw { code: 400, message: createdVessel };
      }
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: VesselActionsTypes.CREATE_VESSEL_ERROR, payload });
    }
  };
};

export const listVesselsPaginated = (dispatch: any) => {
  const vesselService = new VesselsService();

  return async (pagination: Pagination) => {
    dispatch({ type: VesselActionsTypes.LIST_VESSELS_IN_PROGRESS });

    try {
      const vesselsPaginated = await vesselService.findAll(pagination);

      dispatch({ type: VesselActionsTypes.LIST_VESSELS_SUCCESS, payload: vesselsPaginated });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: VesselActionsTypes.LIST_VESSELS_ERROR, payload });
    }
  };
};

export const viewVessel = (dispatch: any) => {
  const vesselService = new VesselsService();
  return async (id: string, params?: Record<string, string | number | boolean>) => {
    dispatch({ type: VesselActionsTypes.VIEW_VESSEL_IN_PROGRESS });

    try {
      const vessel = await vesselService.getVessel(id, params);

      dispatch({ type: VesselActionsTypes.VIEW_VESSEL_SUCCESS, payload: vessel });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: VesselActionsTypes.VIEW_VESSEL_ERROR, payload });
    }
  };
};

export const updateVessel = (dispatch: any) => {
  const vesselService = new VesselsService();
  return async (id: string, attributes: VesselInterface) => {
    dispatch({ type: VesselActionsTypes.UPDATE_VESSEL_IN_PROGRESS });

    try {
      const updatedVessel = await vesselService.updateVessel(id, attributes);

      if (updatedVessel.id) {
        dispatch({ type: VesselActionsTypes.UPDATE_VESSEL_SUCCESS, payload: attributes });
      } else {
        throw { code: 400, message: updatedVessel };
      }
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: VesselActionsTypes.UPDATE_VESSEL_ERROR, payload });
    }
  };
};

export const deleteVessel = (dispatch: any) => {
  const vesselService = new VesselsService();
  return async (
    id: string,
    showAlert: (args: showNotificationType) => void,
    onAction: () => void
  ) => {
    dispatch({ type: VesselActionsTypes.DELETE_VESSEL_IN_PROGRESS });

    try {
      const vessel = await vesselService.deleteVessel(id);

      dispatch({ type: VesselActionsTypes.DELETE_VESSEL_SUCCESS, payload: vessel });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: VesselActionsTypes.DELETE_VESSEL_ERROR, payload });
    }
  };
};

export const resetVesselState = (dispatch: any) => {
  return async () => dispatch({ type: VesselActionsTypes.RESET_STATE });
};
