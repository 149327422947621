import { FC, ReactElement, useContext, useMemo } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { AuthUserInterface } from '../../context/interfaces/AuthUserInterface';

const HeaderWelcome = (): ReactElement => {
  /* const { username, userId, signInDetails } = getCurrentUser(); */

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ color: 'black', fontSize: '24px', marginRight: '21px' }}>
        👋 &nbsp;Hi {'username'}!
      </span>
      <div
        style={{
          background: '#d4d9e6',
          width: 'auto',
          height: '34px',
          borderRadius: '4px',
          padding: '0 10px',
          display: 'flex',
          alignItems: 'center',
          color: '#17264E',
          fontSize: '11px'
        }}
      >
        {/* <span>{user.roles.join(' | ')}</span> */}
      </div>
    </div>
  );
};

export default HeaderWelcome;
