import VesselMediaFileService, {
  CreateVesselMediaFileBulkDto,
  CreateVesselMediaFileDto
} from '../../services/vesselMediaFile';
import { Pagination } from '../../utils/types/pagination';
import { vesselMediaFileInitialState } from '../reducers/vesselMediaFileReducer';
import VesselMediaFileActionsTypes from './types/vesselMediaFile';
import { errorPayload } from './utils/errorPayload';

export const createVesselMediaFileBulk = (dispatch: any) => {
  return async (dto: CreateVesselMediaFileBulkDto) => {
    dispatch({
      type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_IN_PROGRESS
    });

    const vesselMediaFileService = new VesselMediaFileService();
    const result = await Promise.allSettled(
      dto.files.map((file) => vesselMediaFileService.create({ ...file, vessel_id: dto.vessel_id }))
    );

    const isAnyRejected = result.some((r) => r.status === 'rejected');

    if (!isAnyRejected) {
      dispatch({
        type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_SUCCESS
      });
    } else {
      // const payload = errorPayload(result.find((r) => r.status === 'rejected')?.reason);

      console.log('Result', JSON.stringify(result, null, 2));

      dispatch({
        type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_BULK_ERROR,
        // payload
      });
    }
  };
};

export const createVesselMediaFile = (dispatch: any) => {
  return async (dto: CreateVesselMediaFileDto) => {
    dispatch({ type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_IN_PROGRESS });

    try {
      const vesselMediaFileService = new VesselMediaFileService();
      const vesselMediaFile = await vesselMediaFileService.create(dto);

      dispatch({
        type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_SUCCESS,
        payload: vesselMediaFile
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: VesselMediaFileActionsTypes.CREATE_VESSEL_MEDIA_FILE_ERROR, payload });
    }
  };
};

export const listVesselMediaFiles = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_IN_PROGRESS });

    try {
      const vesselMediaFileService = new VesselMediaFileService();
      const vesselsMediaFiles = await vesselMediaFileService.list(pagination);

      dispatch({
        type: VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_SUCCESS,
        payload: vesselsMediaFiles
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: VesselMediaFileActionsTypes.LIST_VESSEL_MEDIA_FILES_ERROR, payload });
    }
  };
};

export const viewVesselMediaFile = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_IN_PROGRESS });

    try {
      const vesselMediaFileService = new VesselMediaFileService();
      const vesselMediaFile = await vesselMediaFileService.view(id);

      dispatch({
        type: VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_SUCCESS,
        payload: vesselMediaFile
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_ERROR, payload });
    }
  };
};

export const deleteVesselMediaFile = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_IN_PROGRESS });

    try {
      const vesselMediaFileService = new VesselMediaFileService();
      await vesselMediaFileService.delete(id);

      dispatch({ type: VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: VesselMediaFileActionsTypes.DELETE_VESSEL_MEDIA_FILE_ERROR, payload });
    }
  };
};

export const resetViewVesselMediaFileState = (dispatch: any) => {
  return () => {
    dispatch({
      type: VesselMediaFileActionsTypes.VIEW_VESSEL_MEDIA_FILE_SUCCESS,
      payload: vesselMediaFileInitialState
    });
  };
};

export const resetVesselMediaFileState = (dispatch: any) => {
  return () => {
    dispatch({ type: VesselMediaFileActionsTypes.RESET_STATE });
  };
};
