import { Checkbox, FormHelperText, FormLabel, Grid } from '@mui/material';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import { ReactComponent as ShipIcon } from '../../assets/images/wrapped-ship.svg';
import FieldContainerExternal from '../../components/field-container/FieldContainer';
import CustomerInputAutoComplete, {
  SelectedOption
} from '../../components/form/InputCustomerAutocomplete';
import InputText from '../../components/form/InputText';
import SwipeableDrawerHeader from '../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import { NotificationContext } from '../../context/notificationContext';
import { createVesselInitialState } from '../../context/reducers/vesselReducer';
import { VesselContext } from '../../context/vesselContext';
import { validateForm } from '../../utils/form';
import { vesselFormSchema } from '../../utils/schemas';
import './VesselForm.scss';

interface VesselFormArgs {
  onClickClose: () => void;
}

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={10} sm={8} md={7} lg={7} xl={7} />;
};

type OnChangeEventType = ChangeEvent<HTMLInputElement>;

const VesselForm: FC<VesselFormArgs> = ({ onClickClose }): ReactElement => {
  const { createVessel, state } = useContext(VesselContext);
  const { showAlert } = useContext(NotificationContext);
  const [form, setForm] = useState(createVesselInitialState);
  const [validationErrors, setValidationErrors] = useState(createVesselInitialState);
  const [formErrorMessage] = useState('');
  const [wasLoading, setWasLoading] = useState(state.create.loading);

  useEffect(() => {
    if (wasLoading && !state.create.loading && !state.create.error) {
      onClickClose();
    }
    setWasLoading(state.create.loading);
  }, [state.create.loading]);

  useEffect(() => {
    if (state.create.done) {
      showAlert({
        variant: 'success',
        title: 'Vessel',
        description: 'Vessel created!'
      });
    }

    if (state.create.error) {
      showAlert({
        variant: 'error',
        title: 'Vessel',
        description: "Vessel couldn't be created!"
      });
    }
  }, [state.create]);

  const handleFormChange = (event: OnChangeEventType) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleCheckBoxChange = (event: OnChangeEventType) => {
    setForm((prevState) => ({
      ...prevState,
      active: event.target?.checked
    }));
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const { errorSummary } = validateForm(vesselFormSchema, form);
    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) createVessel(form /* showAlert */);
  };

  const handleClose = () => {
    onClickClose();
    setForm(createVesselInitialState);
    setValidationErrors(createVesselInitialState);
  };

  const handleChangeOwner = (_event: SyntheticEvent, value: SelectedOption | null) => {
    setForm((prevForm) => ({
      ...prevForm,
      user_id: value ? value.value : ''
    }));
  };

  return (
    <div className="container-vessel-form">
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}
      >
        <SwipeableDrawerHeader
          disableButtons={state.create.loading}
          onClickClose={handleClose}
          onClickSave={handleSubmit}
          title={'Add new vessel'}
          icon={<ShipIcon className="vessel-icon" />}
        />
        <FieldContainer>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputText
              name={'name'}
              label={'Name'}
              value={form.name}
              validationErrors={validationErrors.name}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <CustomerInputAutoComplete
              name="user_id"
              errorSummary={!!validationErrors?.user_id}
              value={form.user_id}
              onChange={handleChangeOwner}
            />
          </Grid>
        </FieldContainer>
        <FieldContainer>
          <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
            <InputText
              name={'hullNumber'}
              label={'Hull number'}
              value={form.hullNumber}
              validationErrors={validationErrors.hullNumber}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
            <InputText
              name={'hight'}
              label={'Hight'}
              value={form.hight}
              validationErrors={validationErrors.hight}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
            <InputText
              name={'beam'}
              label={'Beam'}
              value={form.beam}
              validationErrors={validationErrors.beam}
              onChange={handleFormChange}
            />
          </Grid>
        </FieldContainer>
        <FieldContainer>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputText
              name={'draft'}
              label={'Draft'}
              value={form.draft}
              validationErrors={validationErrors.draft}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputText
              name={'length'}
              label={'Length'}
              value={form.length}
              validationErrors={validationErrors.length}
              onChange={handleFormChange}
            />
          </Grid>
        </FieldContainer>
        <Grid item xs={10} sm={8} md={7} lg={7} xl={7}>
          <FormLabel component="label" className={'regular'}>
            Active
          </FormLabel>
          <div>
            <Checkbox
              style={{ paddingLeft: '0px' }}
              checked={form.active}
              onChange={(e: OnChangeEventType) => handleCheckBoxChange(e)}
            ></Checkbox>
          </div>
        </Grid>
        <FormHelperText error>{formErrorMessage}</FormHelperText>
      </Grid>
    </div>
  );
};

export default VesselForm;
