enum StorableAssetActionsTypes {
  UPLOAD_STORABLE_ASSET_IN_PROGRESS = 'UPLOAD_STORABLE_ASSET_IN_PROGRESS',
  UPLOAD_STORABLE_ASSET_SUCCESS = 'UPLOAD_STORABLE_ASSET_SUCCESS',
  UPLOAD_STORABLE_ASSET_ERROR = 'UPLOAD_STORABLE_ASSET_ERROR',

  VIEW_STORABLE_ASSET_IN_PROGRESS = 'VIEW_STORABLE_ASSET_IN_PROGRESS',
  VIEW_STORABLE_ASSET_SUCCESS = 'VIEW_STORABLE_ASSET_SUCCESS',
  VIEW_STORABLE_ASSET_ERROR = 'VIEW_STORABLE_ASSET_ERROR',

  CREATE_STORABLE_ASSET_UPLOAD_URL_IN_PROGRESS = 'CREATE_STORABLE_ASSET_UPLOAD_URL_IN_PROGRESS',
  CREATE_STORABLE_ASSET_UPLOAD_URL_SUCCESS = 'CREATE_STORABLE_ASSET_UPLOAD_URL_SUCCESS',
  CREATE_STORABLE_ASSET_UPLOAD_URL_ERROR = 'CREATE_STORABLE_ASSET_UPLOAD_URL_ERROR',

  REQUEST_STORABLE_ASSET_DOWNLOAD_URL_IN_PROGRESS = 'REQUEST_STORABLE_ASSET_DOWNLOAD_URL_IN_PROGRESS',
  REQUEST_STORABLE_ASSET_DOWNLOAD_URL_SUCCESS = 'REQUEST_STORABLE_ASSET_DOWNLOAD_URL_SUCCESS',
  REQUEST_STORABLE_ASSET_DOWNLOAD_URL_ERROR = 'REQUEST_STORABLE_ASSET_DOWNLOAD_URL_ERROR',

  DELETE_STORABLE_ASSET_IN_PROGRESS = 'DELETE_STORABLE_ASSET_IN_PROGRESS',
  DELETE_STORABLE_ASSET_SUCCESS = 'DELETE_STORABLE_ASSET_SUCCESS',
  DELETE_STORABLE_ASSET_ERROR = 'DELETE_STORABLE_ASSET_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default StorableAssetActionsTypes;
