import DocumentActionTypes from '../actions/types/document';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  CreateDocumentDto,
  DocumentAction,
  DocumentInterface,
  DocumentsStateInterface
} from '../../utils/types/document';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<DocumentInterface>()

export const documentInitialState: DocumentInterface = {
  id: '',
  name: '',
  expiration_date: '',
  storeable_asset_id: '',
  vessel_id: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const createDocumentInitialState: CreateDocumentDto = {
  name: '',
  expiration_date: null,
  vessel_id: '',
  storeable_asset_id: ''
};

export const initialState: DocumentsStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  delete: actionInitialState
};

export const reducer = (state = initialState, action: DocumentAction): DocumentsStateInterface => {
  switch (action.type) {
    case DocumentActionTypes.CREATE_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case DocumentActionTypes.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case DocumentActionTypes.CREATE_DOCUMENT_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case DocumentActionTypes.LIST_DOCUMENTS_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case DocumentActionTypes.LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<DocumentInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case DocumentActionTypes.LIST_DOCUMENTS_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case DocumentActionTypes.DELETE_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case DocumentActionTypes.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case DocumentActionTypes.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case DocumentActionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
