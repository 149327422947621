import { FC, useCallback, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TitlePage from '../../../components/title-page/TitlePage';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import { ReactComponent as CheckListHistoryIcon } from '../../../assets/images/clock-wrapper.svg';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import getServiceRecordsGridColumns from './ListGridColumns';
import TitleGrid from '../../../components/grid/TitleGrid';
import { defaultPagination } from '../../../utils/constants/defaults';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { VesselContext } from '../../../context/vesselContext';
import { ChecklistContext } from '../../../context/checklistContext';
import { Pagination } from '../../../utils/types/pagination';
import { ChecklistInterface } from '../../../utils/types/checklist';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';
import AlertDialog from '../../../components/dialog/AlertDialog';
import { StorableAssetContext } from '../../../context/storableAssetContext';

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: ['creator', 'operator'],
  order: defaultOrderParams
};

const ChecklistHistoryList: FC = () => {
  const query = useQueryParams();

  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { listChecklistsHistory, state: checklistHistoryState } = useContext(ChecklistContext);
  const { requestStorableAssetDownloadUrl, state: storableAssetState } = useContext(StorableAssetContext);

  const [selectedChecklistHistory, setSelectedChecklistHistory] = useState<ChecklistInterface | null>(null);
  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${vesselId}`
  });

  useEffect(() => {
    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (storableAssetState.requestDownloadUrl.data) {
      setOpenDownloadDialog(false);
      window.open(storableAssetState.requestDownloadUrl.data.downloadUrl, '_blank');
    }
  }, [storableAssetState.requestDownloadUrl]);

  const onDownload = (row: ChecklistInterface) => {
    setSelectedChecklistHistory(row);
    setOpenDownloadDialog(true);
  };

  const columns = getServiceRecordsGridColumns(onDownload);

  useEffect(() => {
    listChecklistHistoryPaginated(paginationParams);
  }, [paginationParams]);

  const listChecklistHistoryPaginated = useCallback((paginationParams: Pagination) => {
    listChecklistsHistory(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  const onConfirmDownload = () => {
    if (!selectedChecklistHistory?.storeable_asset_id) return;

    requestStorableAssetDownloadUrl(selectedChecklistHistory.storeable_asset_id);
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || 'Vessel'}
            current={'Checklist history'}
          />
        }
        rightContainer={<></>}
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {checklistHistoryState.listHistory?.data?.results.length === 0 ? (
            <VesselEmptyListService serviceName={'Checklist history'} />
          ) : (
            <>
              <TitleGrid
                icon={<CheckListHistoryIcon className="icon-title" />}
                title={'Checklist history'}
              />

              <MrtDataTable
                isLoading={checklistHistoryState.listHistory.loading}
                isError={Boolean(checklistHistoryState.listHistory.error)}
                columns={columns}
                rows={checklistHistoryState?.listHistory?.data?.results}
                rowCount={checklistHistoryState?.listHistory?.data?.pagination?.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>

      {openDownloadDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>Are you sure you want to download this document?</span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={
            storableAssetState.requestDownloadUrl.loading ? 'Downloading...' : 'Yes, download'
          }
          disableButtons={storableAssetState.requestDownloadUrl.loading}
          open={openDownloadDialog}
          onClose={() => setOpenDownloadDialog(false)}
          onSave={onConfirmDownload}
        />
      )}
    </>
  );
};

export default ChecklistHistoryList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };