enum TankLevelsActionsTypes {
  CREATE_TANK_LEVEL_IN_PROGRESS = 'CREATE_TANK_LEVEL_IN_PROGRESS',
  CREATE_TANK_LEVEL_SUCCESS = 'CREATE_TANK_LEVEL_SUCCESS',
  CREATE_TANK_LEVEL_ERROR = 'CREATE_TANK_LEVEL_ERROR',

  LIST_TANK_LEVELS_IN_PROGRESS = 'LIST_TANK_LEVELS_IN_PROGRESS',
  LIST_TANK_LEVELS_SUCCESS = 'LIST_TANK_LEVELS_SUCCESS',
  LIST_TANK_LEVELS_ERROR = 'LIST_TANK_LEVELS_ERROR',

  VIEW_TANK_LEVEL_IN_PROGRESS = 'VIEW_TANK_LEVEL_IN_PROGRESS',
  VIEW_TANK_LEVEL_SUCCESS = 'VIEW_TANK_LEVEL_SUCCESS',
  VIEW_TANK_LEVEL_ERROR = 'VIEW_TANK_LEVEL_ERROR',

  UPDATE_TANK_LEVEL_IN_PROGRESS = 'UPDATE_TANK_LEVEL_IN_PROGRESS',
  UPDATE_TANK_LEVEL_SUCCESS = 'UPDATE_TANK_LEVEL_SUCCESS',
  UPDATE_TANK_LEVEL_ERROR = 'UPDATE_TANK_LEVEL_ERROR',

  DELETE_TANK_LEVEL_IN_PROGRESS = 'DELETE_TANK_LEVEL_IN_PROGRESS',
  DELETE_TANK_LEVEL_SUCCESS = 'DELETE_TANK_LEVEL_SUCCESS',
  DELETE_TANK_LEVEL_ERROR = 'DELETE_TANK_LEVEL_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default TankLevelsActionsTypes;
