import { FC, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as ArrowForwardIcon } from '../../assets/images/chevron-forward.svg';
import './TitlePage.scss';

const BreadCrumb: FC<{ previous: string; middle?: string; current?: string }> = ({
  previous,
  middle,
  current
}): ReactElement => {
  return (
    <Grid
      item
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className="grid"
    >
      <span className="leftContainer">{previous}</span>
      {middle && (
        <>
          <ArrowForwardIcon className="arrowIcon" />
          <span className="leftContainer">{middle}</span>
        </>
      )}
      {current && (
        <>
          <ArrowForwardIcon className="arrowIcon" />
          <span className="titlePage">{current}</span>
        </>
      )}
    </Grid>
  );
};

export default BreadCrumb;
