import DocumentService from '../../services/document';
import { CreateDocumentDto } from '../../utils/types/document';
import { Pagination } from '../../utils/types/pagination';
import DocumentActionsTypes from './types/document';
import { errorPayload } from './utils/errorPayload';

export const createDocument = (dispatch: any) => {
  return async (dto: CreateDocumentDto) => {
    dispatch({ type: DocumentActionsTypes.CREATE_DOCUMENT_IN_PROGRESS });

    try {
      const servicesRecordsService = new DocumentService();
      await servicesRecordsService.create(dto);

      dispatch({ type: DocumentActionsTypes.CREATE_DOCUMENT_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: DocumentActionsTypes.CREATE_DOCUMENT_ERROR, payload });
    }
  };
};

export const listDocuments = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: DocumentActionsTypes.LIST_DOCUMENTS_IN_PROGRESS });

    try {
      const documentsService = new DocumentService();
      const documents = await documentsService.list(pagination);

      dispatch({ type: DocumentActionsTypes.LIST_DOCUMENTS_SUCCESS, payload: documents });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: DocumentActionsTypes.LIST_DOCUMENTS_ERROR, payload });
    }
  };
};

export const deleteDocument = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: DocumentActionsTypes.DELETE_DOCUMENT_IN_PROGRESS });

    try {
      const documentsService = new DocumentService();
      await documentsService.delete(id);

      dispatch({ type: DocumentActionsTypes.DELETE_DOCUMENT_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: DocumentActionsTypes.DELETE_DOCUMENT_ERROR, payload });
    }
  };
};

export const resetDocumentState = (dispatch: any) => {
  return async () => dispatch({ type: DocumentActionsTypes.RESET_STATE });
};
