import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactEventHandler,
  useEffect,
  useState
} from 'react';
import { ReactComponent as TankLevelsIcon } from '../../../assets/images/tank-levels-wrapper.svg';
import FieldContainerExternal from '../../../components/field-container/FieldContainer';
import FieldColor from '../../../components/fieldColor/FieldColor';
import InputText from '../../../components/form/InputText';
import SwipeableDrawerHeader from '../../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import { tankLevelItemInitialState } from '../../../context/reducers/tankLevelReducer';
import { validateForm } from '../../../utils/form';
import './TankLevelsTemplate.scss';
import { levelGroup } from './TankLevelsTemplateForm';
import { tankLevelColors as tankLevelColorsConst, defaultColorCode } from './constants/colors';
import { tankLevelTemplateLevelSchema } from './tankLevelsSchema';

type Props = {
  open: boolean;
  form: levelGroup;
  onOpen: (open: boolean) => ReactEventHandler<{}> | void;
  onSaveForm: (form: levelGroup) => void;
};

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <FieldContainerExternal xs={10} sm={8} md={7} lg={7} xl={7}>
      {children}
    </FieldContainerExternal>
  );
};

const TankLevelsTemplateFieldForm: FC<Props> = ({
  open,
  onOpen,
  form,
  onSaveForm
}): ReactElement => {
  const [newForm, setNewForm] = useState(tankLevelItemInitialState);
  const [validationErrors, setValidationErrors] = useState({
    label: '',
    maxLevel: '',
    color: ''
  });

  useEffect(() => {
    if (form) {
      const tankColor = tankLevelColorsConst.find((c) => c.color === form.color);
      const updatedForm = { ...form, color: tankColor ? tankColor.color : defaultColorCode };
      setNewForm(updatedForm);
    }
  }, [form]);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewForm((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.name === 'maxLevel' ? Number(event.target.value) : event.target.value
    }));
  };

  const handleDropdownChange = (value: string) => {
    setNewForm((prevState) => ({
      ...prevState,
      color: value
    }));
  };

  const tankLevelColors = tankLevelColorsConst.map((color) => (
    <MenuItem value={color.color} style={menuItemStyle} key={color.color}>
      <FieldColor color={color.color} name={color.name} />
    </MenuItem>
  ));

  const handleSave = () => {
    const { errorSummary } = validateForm(tankLevelTemplateLevelSchema, {
      label: newForm.label,
      color: newForm.color,
      maxLevel: newForm.maxLevel
    });

    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) onSaveForm({ ...newForm, edit: form.edit });
  };

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={open}
      onClose={onOpen(false) as ReactEventHandler<{}>}
      onOpen={onOpen(true) as ReactEventHandler<{}>}
      sx={swipeableDrawerStyle}
    >
      <div className="tanks-template-form-container">
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          margin={'auto'}
          justifyContent={'center'}
        >
          <SwipeableDrawerHeader
            onClickClose={() => onOpen(false)}
            onClickSave={handleSave}
            disableButtons={false}
            title={'Create Tank level'}
            icon={<TankLevelsIcon className={'tank-level-icon'} />}
          />

          <FieldContainer>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <InputText
                name={'label'}
                label={'Name'}
                value={newForm.label}
                validationErrors={validationErrors.label}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                width={'100%'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <InputText
                name={'maxLevel'}
                label={'Max level'}
                value={`${newForm.maxLevel}`}
                type="number"
                validationErrors={validationErrors.maxLevel}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
                width={'100%'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
              <FormControl style={{ width: '100%' }} error={!!validationErrors.color}>
                <FormLabel component="label" className={'regular'}>
                  Color
                </FormLabel>
                <Select
                  value={newForm.color}
                  onChange={(e) => handleDropdownChange(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {tankLevelColors}
                </Select>
                <FormHelperText>{validationErrors.color}</FormHelperText>
              </FormControl>
            </Grid>
          </FieldContainer>
        </Grid>
      </div>
    </SwipeableDrawer>
  );
};

export default TankLevelsTemplateFieldForm;

const menuItemStyle = { color: '#232735', fontSize: '15px' };
const swipeableDrawerStyle = {
  maxHeight: 'calc(100% - 64px)',
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px 8px 0 0'
  }
};
