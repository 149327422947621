import {
  FC,
  ReactElement,
  useState,
  ChangeEvent,
  useImperativeHandle,
  forwardRef,
  ReactNode,
  useEffect
} from 'react';
import { Grid } from '@mui/material';
import InputText from '../../components/form/InputText';
import FieldContainerExternal from '../../components/field-container/FieldContainer';
import { UserBasicInfo, UserInterface } from '../../utils/types/user';

interface UserFormArgs {
  sendForm: (formInfo: UserInterface) => void;
  user: UserInterface;
  validationErrors: UserBasicInfo;
  wide?: boolean;
}

const smallSizes = {
  xs: 10,
  sm: 8,
  md: 7,
  lg: 7,
  xl: 7
};

const wideSizes = {
  xs: 10,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
};

type FieldType = {
  wide?: boolean;
  child: ReactNode;
};

const FieldContainer: FC<FieldType> = ({ child, wide }): ReactElement => {
  const size = wide ? wideSizes : smallSizes;
  return (
    <FieldContainerExternal
      children={child}
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      lg={size.lg}
      xl={size.xl}
    />
  );
};

export type UserProfileForwardedRef = {
  getUserBasicInfo: () => void;
};

const UserProfile = forwardRef<UserProfileForwardedRef, UserFormArgs>(
  ({ sendForm, user, validationErrors, wide }, ref): ReactElement => {
    const [form, setForm] = useState<UserInterface>(user);

    useEffect(() => {
      setForm(user);
    }, [user]);

    useImperativeHandle(ref, () => ({
      getUserBasicInfo: () => sendForm(form)
    }));

    const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    };

    return (
      <>
        <FieldContainer
          wide={wide}
          child={
            <>
              <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                <InputText
                  name={'name'}
                  label={'Name'}
                  value={`${form.name}`}
                  validationErrors={validationErrors.name}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                <InputText
                  name={'lastName'}
                  label={'Last name'}
                  value={`${form.lastName}`}
                  validationErrors={validationErrors.lastName}
                  onChange={handleFormChange}
                />
              </Grid>
            </>
          }
        />
        <FieldContainer
          wide={wide}
          child={
            <>
              <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                <InputText
                  name={'email'}
                  label={'Email'}
                  value={`${form.email}`}
                  validationErrors={validationErrors.email}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
                <InputText
                  name={'phone'}
                  label={'Phone'}
                  value={`${form.phone}`}
                  validationErrors={validationErrors.phone}
                  onChange={handleFormChange}
                />
              </Grid>
            </>
          }
        />
      </>
    );
  }
);

export default UserProfile;
