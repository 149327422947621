import CustomersService from '../../services/customers';
import { customerAdapter } from '../../utils/adapters/userAdapter';
import CustomerActionsTypes from './types/customer';
import { errorPayload } from './utils/errorPayload';
import { showNotificationType } from '../../utils/types/notification';
import { Pagination } from '../../utils/types/pagination';
import { SubmittedPasswordForm } from '../../utils/types/user';
import { CreateCustomerDto, UpdateCustomerDto } from '../../utils/types/customer';

export const getCustomersPaginated = (dispatch: any) => {
  const customersService = new CustomersService();

  return async (pagination: Pagination) => {
    dispatch({ type: CustomerActionsTypes.FETCH_CUSTOMERS_IN_PROGRESS });

    try {
      const paginatedUsers = await customersService.findAll(pagination);

      dispatch({ type: CustomerActionsTypes.FETCH_CUSTOMERS_SUCCESS, payload: paginatedUsers });
    } catch (error: any) {
      dispatch({ type: CustomerActionsTypes.FETCH_CUSTOMERS_ERROR, payload: errorPayload(error) });
    }
  };
};

export const getCustomer = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (id: string) => {
    dispatch({ type: CustomerActionsTypes.FETCH_CUSTOMER_IN_PROGRESS });

    try {
      const user = await customerService.findOne(id);

      dispatch({
        type: CustomerActionsTypes.FETCH_CUSTOMER_SUCCESS,
        payload: customerAdapter(user)
      });
    } catch (error: any) {
      dispatch({ type: CustomerActionsTypes.FETCH_CUSTOMER_ERROR, payload: errorPayload(error) });
    }
  };
};

export const deleteCustomer = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (id: string, role: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.DELETE_CUSTOMER_IN_PROGRESS });

    try {
      await customerService.deleteUser(id, role);
      dispatch({ type: CustomerActionsTypes.DELETE_CUSTOMER_SUCCESS });
      showAlert({
        variant: 'success',
        title: 'Customer',
        description: 'Customer deleted!'
      });
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Customer',
        description: "Customer couldn't be deleted!"
      });
      dispatch({ type: CustomerActionsTypes.DELETE_CUSTOMER_ERROR, payload });
    }
  };
};

export const createCustomer = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (user: CreateCustomerDto, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.CREATE_CUSTOMER_IN_PROGRESS });

    try {
      const createdUser = await customerService.create(user);

      if (createdUser.id) {
        dispatch({ type: CustomerActionsTypes.CREATE_CUSTOMER_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Customer',
          description: 'Customer created!'
        });
      } else {
        throw { code: 400, message: createdUser };
      }
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Customer',
        description: "Customer couldn't be created!"
      });
      dispatch({ type: CustomerActionsTypes.CREATE_CUSTOMER_ERROR, payload });
    }
  };
};

export const approveCustomerSignUpRequest = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (id: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS });

    try {
      const done = await customerService.approveSignUpRequest(id);

      if (done) {
        dispatch({ type: CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Success',
          description: 'Sign up request approved'
        });
      } else throw { code: 400, message: done };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Error',
        description: 'Sign up request could not be approved'
      });
      dispatch({ type: CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_ERROR, payload });
    }
  };
};

export const rejectCustomerSignUpRequest = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (id: string, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS });

    try {
      const done = await customerService.rejectSignUpRequest(id);

      if (done) {
        dispatch({ type: CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Success',
          description: 'Sign up request rejected'
        });
      } else throw { code: 400, message: done };
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Error',
        description: 'Sign up request could not be rejected'
      });
      dispatch({ type: CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_ERROR, payload });
    }
  };
};

export const editCustomer = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (user: UpdateCustomerDto, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_IN_PROGRESS });

    try {
      const editedCustomer = await customerService.edit(user);

      if (editedCustomer.id) {
        dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Customer',
          description: 'Customer edited!'
        });
      }
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Customer',
        description: "Customer couldn't be edited!"
      });
      dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_ERROR, payload });
    }
  };
};

export const updatePassword = (dispatch: any) => {
  const customerService = new CustomersService();

  return async (form: SubmittedPasswordForm, showAlert: (args: showNotificationType) => void) => {
    dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_IN_PROGRESS });

    try {
      const result = await customerService.updatePassword(form);

      if (result.updated) {
        dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_SUCCESS });
        showAlert({
          variant: 'success',
          title: 'Customer',
          description: 'Password updated!'
        });
      }
    } catch (error: any) {
      const payload = errorPayload(error);
      showAlert({
        variant: 'error',
        title: 'Customer',
        description: "Password couldn't be updated!"
      });
      dispatch({ type: CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_ERROR, payload });
    }
  };
};

export const resetState = (dispatch: any) => {
  return async () => dispatch({ type: CustomerActionsTypes.RESET_STATE_ERROR });
};
