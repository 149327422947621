import ChecklistTemplateActionsTypes from '../actions/types/checklistTemplate';
import {
  ChecklistTemplateAction,
  ChecklistTemplateInterface,
  ChecklistTemplateStateInterface
} from '../../utils/types/checklistTemplate';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<ChecklistTemplateInterface>()

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: ChecklistTemplateStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: {
      id: '',
      name: '',
      template: '',
      created_by: ''
    }
  },
  update: actionInitialState,
  delete: actionInitialState,
  complete: actionInitialState
};

export const checklistTemplateReducer = (
  state = initialState,
  action: ChecklistTemplateAction
): ChecklistTemplateStateInterface => {
  switch (action.type) {
    case ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ChecklistTemplateActionsTypes.CREATE_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<ChecklistTemplateInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ChecklistTemplateActionsTypes.LIST_CHECKLIST_TEMPLATES_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ChecklistTemplateActionsTypes.VIEW_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ChecklistTemplateActionsTypes.UPDATE_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ChecklistTemplateActionsTypes.DELETE_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ChecklistTemplateActionsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
