import Joi from 'joi';

export const tankLevelTemplateSchema = Joi.object({
  name: Joi.string().required(),
  levels: Joi.array().min(1).required()
}).options({
  abortEarly: false
});

export const tankLevelTemplateLevelSchema = Joi.object({
  label: Joi.string().required(),
  color: Joi.string().required(),
  maxLevel: Joi.number().min(0).required()
}).options({
  abortEarly: false
});
