import CloseIcon from '@mui/icons-material/Close';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../context/notificationContext';
import './TemporalAlert.scss';

const variants = {
  error: {
    color: 'rgb(244, 67, 54)',
    icon: ''
  },
  warning: {
    color: 'rgb(255, 167, 38)',
    icon: ''
  },
  success: {
    color: 'rgb(102, 187, 106)',
    icon: ''
  },
  info: {
    color: 'rgb(41, 182, 246)',
    icon: ''
  }
};

const TemporalAlert: FC = (): ReactElement => {
  const { hideAlert, state } = useContext(NotificationContext);
  const [themeVariant, setThemeVariant] = useState(variants[state.variant]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const time = 1500;

  useEffect(() => {
    setThemeVariant(variants[state.variant]);
    if (state.show) {
      setIsCollapsed(false);
      const timeOut = setTimeout(() => {
        hideAlert();
        setIsCollapsed(true);
        clearTimeout(timeOut);
      }, time);
      if (state.action) state.action();
    }
  }, [state]);

  return (
    <>
      {!isCollapsed && (
        <Collapse
          in={state.show}
          sx={{
            width: '250px',
            position: 'fixed',
            top: '10px',
            right: '10px',
            borderRadius: 1,
            overflow: 'hidden',
            border: 1,
            borderColor: themeVariant.color,
            zIndex: 10000
          }}
        >
          <Alert
            severity={state.variant}
            action={
              <IconButton aria-label="close" color={state.variant} size="small" onClick={hideAlert}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              width: '100%',
              backgroundColor: '#fff'
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                overflow: 'visible'
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 400
                }}
              >
                {state.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px'
                }}
              >
                {state.description}
              </Typography>
            </Box>
          </Alert>
          <Box
            sx={{
              '@keyframes width-increase': {
                '0%': {
                  width: '0px'
                },
                '100%': {
                  width: '100%'
                }
              },
              animation: `${time - 200}ms ease width-increase forwards`,
              position: 'absolute',
              bottom: 0,
              height: '2px',
              backgroundColor: themeVariant.color
            }}
          />
        </Collapse>
      )}
    </>
  );
};

export default TemporalAlert;
