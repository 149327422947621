import { Pagination } from '../utils/types/pagination';
import { VesselMediaFileInterface } from '../utils/types/vesselMediaFile';
import ApiService from './api';

export type CreateVesselMediaFileDto = Pick<
  VesselMediaFileInterface,
  'name' | 'storeable_asset_id' | 'vessel_id'
>;

export type CreateVesselMediaFileBulkDto = Pick<VesselMediaFileInterface, 'vessel_id'> & {
  files: Pick<VesselMediaFileInterface, 'name' | 'storeable_asset_id'>[];
};

export default class VesselMediaFileService extends ApiService {
  create = async (dto: CreateVesselMediaFileDto) => {
    const { data } = await this.axios.post('vessel-media-file', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('vessel-media-files', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`vessel-media-file/${id}`);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`vessel-media-file/${id}`);

    return data;
  };
}
