import { FC } from 'react';
import { Grid } from '@mui/material';
import './TitleGrid.scss';

const TitleGrid: FC<{ icon: any; title: string }> = ({ icon, title }) => {
  return (
    <Grid display={'flex'} alignItems={'center'} marginBottom={'15px'}>
      {icon}
      <p className="text-title">{title}</p>
    </Grid>
  );
};

export default TitleGrid;
