import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { CalendarProps } from '../../utils/types/common';

export type InputDateProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  validationError?: string;
  minDate?: Date;
  maxDate?: Date;
  calendarProps: CalendarProps;
};

const InputDate = (props: InputDateProps) => {
  const { label, disabled, validationError, calendarProps, minDate, maxDate } = props;

  return (
    <FormControl error={!!validationError}>
      <FormLabel component="label" className={'regular'}>
        {label}
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          sx={{
            svg: {
              color: '#939FC1'
            },
            input: {
              color: !!validationError ? '#d32f2f' : '#000'
            }
          }}
          slotProps={{
            textField: {
              error: !!validationError
            }
          }}
          /* name={name} */
          disabled={disabled}
          value={dayjs(calendarProps.value)}
          onChange={calendarProps.onChange}
          minDate={minDate ? dayjs(minDate) : undefined}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
        />
      </LocalizationProvider>
      <FormHelperText>{validationError}</FormHelperText>
    </FormControl>
  );
};

export default InputDate;
