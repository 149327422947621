import { CreateDocumentDto, DocumentInterface } from '../utils/types/document';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type UpdateDocumentDto = Pick<DocumentInterface, 'storeable_asset_id'>;

export default class DocumentService extends ApiService {
  create = async (dto: CreateDocumentDto) => {
    const { data } = await this.axios.post('document', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('documents', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  update = async (id: string, dto: UpdateDocumentDto) => {
    const { data } = await this.axios.put(`document/${id}`, dto);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`document/${id}`);

    return data;
  };
}
