import VesselActionsTypes from '../actions/types/vessel';
import {
  VesselAction,
  VesselInterface,
  CreateVesselInterface,
  VesselStateInterface
} from '../../utils/types/vessel';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<VesselInterface>()

export const vesselInitialState: VesselInterface = {
  id: '',
  active: false,
  hullNumber: '',
  name: '',
  hight: '',
  beam: '',
  draft: '',
  length: '',
  location: '',
  notes: '',
  user_id: '',
  storeable_asset_id: ''
};

export const createVesselInitialState: CreateVesselInterface = {
  active: false,
  hullNumber: '',
  name: '',
  hight: '',
  beam: '',
  draft: '',
  length: '',
  user_id: '',
  location: '',
  notes: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: VesselStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: {
      id: '',
      active: false,
      hullNumber: '',
      name: '',
      hight: '',
      beam: '',
      draft: '',
      length: '',
      location: '',
      notes: '',
      user_id: '',
      storeable_asset_id: '',
      owner: undefined
    }
  },
  update: actionInitialState,
  delete: actionInitialState,
  complete: actionInitialState
};

export const reducer = (state = initialState, action: VesselAction): VesselStateInterface => {
  switch (action.type) {
    case VesselActionsTypes.CREATE_VESSEL_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselActionsTypes.CREATE_VESSEL_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselActionsTypes.CREATE_VESSEL_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselActionsTypes.LIST_VESSELS_IN_PROGRESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselActionsTypes.LIST_VESSELS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload as PaginatedListInterface<VesselInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselActionsTypes.LIST_VESSELS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselActionsTypes.VIEW_VESSEL_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselActionsTypes.VIEW_VESSEL_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselActionsTypes.VIEW_VESSEL_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselActionsTypes.UPDATE_VESSEL_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselActionsTypes.UPDATE_VESSEL_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselActionsTypes.UPDATE_VESSEL_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselActionsTypes.DELETE_VESSEL_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case VesselActionsTypes.DELETE_VESSEL_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case VesselActionsTypes.DELETE_VESSEL_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case VesselActionsTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
