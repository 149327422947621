import { showNotificationType } from '../../utils/types/notification';
import NotificationActionsTypes from './types/notification';

export const showAlert = (dispatch: any) => {
  return async (args: showNotificationType) => {
    dispatch({ type: NotificationActionsTypes.SHOW_NOTIFICATION, payload: args });
  };
};

export const hideAlert = (dispatch: any) => () =>
  dispatch({ type: NotificationActionsTypes.HIDE_NOTIFICATION });
