enum ChecklistTemplateActionsTypes {
  CREATE_CHECKLIST_TEMPLATE_IN_PROGRESS = 'CREATE_CHECKLIST_TEMPLATE_IN_PROGRESS',
  CREATE_CHECKLIST_TEMPLATE_SUCCESS = 'CREATE_CHECKLIST_TEMPLATE_SUCCESS',
  CREATE_CHECKLIST_TEMPLATE_ERROR = 'CREATE_CHECKLIST_TEMPLATE_ERROR',

  LIST_CHECKLIST_TEMPLATES_IN_PROGRESS = 'LIST_CHECKLIST_TEMPLATES_IN_PROGRESS',
  LIST_CHECKLIST_TEMPLATES_SUCCESS = 'LIST_CHECKLIST_TEMPLATES_SUCCESS',
  LIST_CHECKLIST_TEMPLATES_ERROR = 'LIST_CHECKLIST_TEMPLATES_ERROR',

  VIEW_CHECKLIST_TEMPLATE_IN_PROGRESS = 'VIEW_CHECKLIST_TEMPLATE_IN_PROGRESS',
  VIEW_CHECKLIST_TEMPLATE_SUCCESS = 'VIEW_CHECKLIST_TEMPLATE_SUCCESS',
  VIEW_CHECKLIST_TEMPLATE_ERROR = 'VIEW_CHECKLIST_TEMPLATE_ERROR',

  UPDATE_CHECKLIST_TEMPLATE_IN_PROGRESS = 'UPDATE_CHECKLIST_TEMPLATE_IN_PROGRESS',
  UPDATE_CHECKLIST_TEMPLATE_SUCCESS = 'UPDATE_CHECKLIST_TEMPLATE_SUCCESS',
  UPDATE_CHECKLIST_TEMPLATE_ERROR = 'UPDATE_CHECKLIST_TEMPLATE_ERROR',

  DELETE_CHECKLIST_TEMPLATE_IN_PROGRESS = 'DELETE_CHECKLIST_TEMPLATE_IN_PROGRESS',
  DELETE_CHECKLIST_TEMPLATE_SUCCESS = 'DELETE_CHECKLIST_TEMPLATE_SUCCESS',
  DELETE_CHECKLIST_TEMPLATE_ERROR = 'DELETE_CHECKLIST_TEMPLATE_ERROR',

  COMPLETE_CHECKLIST_TEMPLATE_IN_PROGRESS = 'COMPLETE_CHECKLIST_TEMPLATE_IN_PROGRESS',
  COMPLETE_CHECKLIST_TEMPLATE_SUCCESS = 'COMPLETE_CHECKLIST_TEMPLATE_SUCCESS',
  COMPLETE_CHECKLIST_TEMPLATE_ERROR = 'COMPLETE_CHECKLIST_TEMPLATE_ERROR',

  RESET_STATE = 'RESET_STATE',
  RESET_STATE_ERROR = 'RESET_STATE_ERROR'
}

export default ChecklistTemplateActionsTypes;
