import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckListIcon } from '../../../assets/images/check-list-wrapper.svg';
import AlertDialog from '../../../components/dialog/AlertDialog';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { systemInfoInitialState } from '../../../context/reducers/systemInfoReducer';
import { SystemInfoTemplateContext } from '../../../context/systemInfoTemplateContext';
import { defaultPagination } from '../../../utils/constants/defaults';
import paths from '../../../utils/constants/paths';
import VesselEmptyListService from '../../vessels/VesselEmptyListService';
import TemplateBox from '../TemplateBox';
import getSystemInfoGridColumns from './ListGridColumns';
import { Pagination } from '../../../utils/types/pagination';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';
import { SystemInfoTemplateInterface } from '../../../utils/types/systemInfoTemplate';

const defaultAttributesParams = ['id', 'name', 'createdAt', 'updatedAt'];
const defaultOrderParams = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  order: defaultOrderParams
};

const SystemInfoTemplateList: FC = (): ReactElement => {
  const navigate = useNavigate();

  const { listSystemInfoTemplates, deleteSystemInfoTemplate, state } = useContext(SystemInfoTemplateContext);
  const { showAlert } = useContext(NotificationContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<SystemInfoTemplateInterface>(systemInfoInitialState);
  const [paginationParams, setPaginationParams] = useState<Pagination>(defaultPaginationParams);

  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (state.delete.done) {
      listSystemInfoTemplatesPaginated(paginationParams);

      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template deleted!'
      });
    }

    if (state.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be deleted!"
      });
    }
  }, [state.delete]);

  const onEditSystemInfo = (systemInfo: SystemInfoTemplateInterface) => {
    navigate({
      pathname: paths.TEMPLATE_SYSTEM_INFO_FORM.absolutePath,
      search: `id=${systemInfo.id}`
    });
  };

  const onDeleteSystemInfo = (systemInfo: SystemInfoTemplateInterface) => {
    setSelectedRecord(systemInfo);
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    deleteSystemInfoTemplate(selectedRecord.id);
    setOpenDeleteDialog(false);
  };

  const columns = getSystemInfoGridColumns(onEditSystemInfo, onDeleteSystemInfo);

  useEffect(() => {
    listSystemInfoTemplatesPaginated(paginationParams);
  }, [paginationParams]);

  const listSystemInfoTemplatesPaginated = useCallback((paginationParams: Pagination) => {
    listSystemInfoTemplates(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <TitlePage
        leftContainer={<BreadCrumb previous={'Templates'} current={'System info'} />}
        rightContainer={
          <Button
            variant="outlined"
            onClick={() => navigate(paths.TEMPLATE_SYSTEM_INFO_FORM.absolutePath)}
          >
            Create new template
          </Button>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {state?.list?.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'System info templates'} />
          ) : (
            <>
              <TitleGrid
                icon={<CheckListIcon className="icon-title" />}
                title={'System info templates'}
              />

              <MrtDataTable
                isLoading={state?.list.loading}
                isError={Boolean(state?.list.error)}
                columns={columns}
                rows={state?.list.data?.results}
                rowCount={state?.list.data?.pagination.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
      {openDeleteDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to remove <strong>{selectedRecord.name}</strong>? There
                  might be vessels using this template
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={handleDelete}
          disableButtons={state.list.loading || state.delete.loading}
        />
      )}
    </>
  );
};

export default SystemInfoTemplateList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
