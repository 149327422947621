import Grid from '@mui/material/Grid';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as ServiceRecordsIcon } from '../../../assets/images/service-records-wrapper.svg';
import AlertDialog from '../../../components/dialog/AlertDialog';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { serviceRecordInitialState } from '../../../context/reducers/serviceRecordReducer';
import { ServiceRecordContext } from '../../../context/serviceRecordContext';
import { VesselContext } from '../../../context/vesselContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { defaultPagination } from '../../../utils/constants/defaults';
import { Pagination } from '../../../utils/types/pagination';
import { ServiceRecordInterface } from '../../../utils/types/serviceRecord';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import getServiceRecordsGridColumns from './ListGridColumns';
import './ServiceRecords.scss';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ServiceRecordForm from './ServiceRecordForm';
import Button from '@mui/material/Button';
import { NotificationContext } from '../../../context/notificationContext';
import { StorableAssetContext } from '../../../context/storableAssetContext';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';

const initialValues = {
  ...serviceRecordInitialState
};

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: [],
  order: defaultOrderParams
};

const ServiceRecordsList: FC = () => {
  const query = useQueryParams();

  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { deleteServiceRecord, listServiceRecords, resetServiceRecordState, state: serviceRecordState } = useContext(ServiceRecordContext);
  const { deleteStorableAsset, requestStorableAssetDownloadUrl, state: storableAssetState } = useContext(StorableAssetContext);
  const { showAlert } = useContext(NotificationContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<ServiceRecordInterface>(initialValues);
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${vesselId}`
  });

  useEffect(() => {
    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (serviceRecordState.create.done) {
      showAlert({
        variant: 'success',
        title: 'Service Record',
        description: 'Service Record created!'
      });

      resetServiceRecordState();
      setIsFormOpen(false);
      listServiceRecordsPaginated(paginationParams);
    }

    if (serviceRecordState.create.error) {
      showAlert({
        variant: 'error',
        title: 'Service Record',
        description: "Service Record couldn't be created!"
      });

      setIsFormOpen(false);
    }
  }, [serviceRecordState.create]);

  useEffect(() => {
    if (serviceRecordState.delete.done) {
      showAlert({
        variant: 'success',
        title: 'Service Record',
        description: 'Service Record deleted!'
      });

      deleteStorableAsset(selectedService.storeable_asset_id);
      listServiceRecordsPaginated(paginationParams);
    } else if (serviceRecordState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Service Record',
        description: "Service Record couldn't be deleted!"
      });
    }

    setOpenDeleteDialog(false);
  }, [serviceRecordState.delete]);

  useEffect(() => {
    if (storableAssetState.requestDownloadUrl.data) {
      setOpenDownloadDialog(false);
      window.open(storableAssetState.requestDownloadUrl.data.downloadUrl, '_blank');
    }
  }, [storableAssetState.requestDownloadUrl]);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsFormOpen(isOpen);
  };

  const onDownload = (serviceRecord: ServiceRecordInterface) => {
    setSelectedService(serviceRecord);
    setOpenDownloadDialog(true);
  };

  const onConfirmDownload = () => {
    if (!selectedService.storeable_asset_id) return;

    requestStorableAssetDownloadUrl(selectedService.storeable_asset_id);
  };

  const onDelete = (serviceRecord: ServiceRecordInterface) => {
    setSelectedService(serviceRecord);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    if (!selectedService.storeable_asset_id) return;

    deleteServiceRecord(selectedService.id);
  };

  const columns = getServiceRecordsGridColumns(onDownload, onDelete);

  useEffect(() => {
    listServiceRecordsPaginated(paginationParams);
  }, [paginationParams]);

  const listServiceRecordsPaginated = useCallback((paginationParams: Pagination) => {
    listServiceRecords(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || 'Vessel'}
            current={'Service records'}
          />
        }
        rightContainer={
          <Button variant="outlined" onClick={() => setIsFormOpen(true)}>
            Add new service record
          </Button>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {serviceRecordState.list.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'Service records'} />
          ) : (
            <>
              <TitleGrid
                icon={<ServiceRecordsIcon className="icon-title" />}
                title={'Service records'}
              />

              <MrtDataTable
                isLoading={serviceRecordState.list.loading}
                isError={Boolean(serviceRecordState.list.error)}
                columns={columns}
                rows={serviceRecordState?.list?.data?.results}
                rowCount={serviceRecordState?.list?.data?.pagination?.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>

      <SwipeableDrawer
        anchor={'bottom'}
        open={isFormOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={swipeableDrawerStyle}
      >
        {isFormOpen && (
          <ServiceRecordForm vesselId={vesselId} onClose={() => setIsFormOpen(false)} />
        )}
      </SwipeableDrawer>

      {openDownloadDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>Are you sure you want to download this document?</span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={
            storableAssetState.requestDownloadUrl.loading ? 'Downloading...' : 'Yes, download'
          }
          disableButtons={storableAssetState.requestDownloadUrl.loading}
          open={openDownloadDialog}
          onClose={() => setOpenDownloadDialog(false)}
          onSave={onConfirmDownload}
        />
      )}

      {openDeleteDialog && (
        <AlertDialog
          disableButtons={serviceRecordState.delete.loading || storableAssetState.delete.loading}
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to delete this record? This action can not be undone
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default ServiceRecordsList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };

const swipeableDrawerStyle = {
  maxHeight: 'calc(100% - 64px)',
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px 8px 0 0'
  }
};
