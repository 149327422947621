import { getInitialPaginationState } from '../../utils/constants/states';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  TankLevelAction,
  TankLevelInterface,
  TankLevelItemInterface,
  TankLevelStateInterface
} from '../../utils/types/tankLevel';
import TankLevelsTypes from '../actions/types/tankLevel';

const initialPaginationState = getInitialPaginationState<TankLevelInterface>()

export const tankLevelItemInitialState: TankLevelItemInterface = {
  color: 'Green',
  label: '',
  maxLevel: 100,
  value: 0
};

export const tankLevelInitialState: TankLevelInterface = {
  id: '',
  name: '',
  template: '',
  state: '',
  vessel_id: '',
  created_by: '',
  updated_by: '',
  createdAt: '',
  updatedAt: ''
};

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: TankLevelStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: { ...actionInitialState, data: tankLevelInitialState },
  update: actionInitialState,
  delete: actionInitialState,
  complete: actionInitialState
};

export const reducer = (state = initialState, action: TankLevelAction): TankLevelStateInterface => {
  switch (action.type) {
    case TankLevelsTypes.CREATE_TANK_LEVEL_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case TankLevelsTypes.CREATE_TANK_LEVEL_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case TankLevelsTypes.CREATE_TANK_LEVEL_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case TankLevelsTypes.LIST_TANK_LEVELS_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case TankLevelsTypes.LIST_TANK_LEVELS_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<TankLevelInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case TankLevelsTypes.LIST_TANK_LEVELS_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case TankLevelsTypes.VIEW_TANK_LEVEL_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case TankLevelsTypes.VIEW_TANK_LEVEL_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case TankLevelsTypes.VIEW_TANK_LEVEL_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case TankLevelsTypes.UPDATE_TANK_LEVEL_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case TankLevelsTypes.UPDATE_TANK_LEVEL_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case TankLevelsTypes.UPDATE_TANK_LEVEL_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case TankLevelsTypes.DELETE_TANK_LEVEL_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case TankLevelsTypes.DELETE_TANK_LEVEL_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case TankLevelsTypes.DELETE_TANK_LEVEL_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case TankLevelsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
