import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import { MRT_ColumnDef } from 'material-react-table';
import { SystemInfoTemplateInterface } from '../../../utils/types/systemInfoTemplate';
import IconButton from '@mui/material/IconButton';

const getSystemInfosGridColumns = (
  onEditSystemInfoTemplate: (systemInfo: SystemInfoTemplateInterface) => void,
  onDeleteSystemInfoTemplate: (systemInfo: SystemInfoTemplateInterface) => void
): Array<MRT_ColumnDef<SystemInfoTemplateInterface>> => [
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'createdAt',
      header: 'Created',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditSystemInfoTemplate(cell.row.original)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDeleteSystemInfoTemplate(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getSystemInfosGridColumns;
