import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, ReactElement } from 'react';

type Props = {
  onSave: () => void;
  onCompleteService?: () => void;
  disabled?: boolean;
};

const ServiceActions: FC<Props> = ({
  onSave,
  onCompleteService,
  disabled = false
}): ReactElement => {
  return (
    <Grid container display={'flex'} justifyContent={'space-between'}>
      <Grid item xs={12} sm={12} md={4} lg={6.5} xl={8} />
      <Grid item xs={12} sm={12} md={'auto'} lg={'auto'} xl={'auto'}>
        <Button
          disabled={disabled}
          variant={onCompleteService !== undefined ? 'outlined' : 'contained'}
          type="button"
          style={{ width: '100%', minWidth: '170px', marginBottom: '10px' }}
          onClick={onSave}
        >
          Save
        </Button>
      </Grid>
      {onCompleteService !== undefined && (
        <Grid item xs={12} sm={12} md={'auto'} lg={'auto'} xl={'auto'}>
          <Button
            disabled={disabled}
            variant="contained"
            type="button"
            style={{ width: '100%', minWidth: '170px' }}
            onClick={onCompleteService}
          >
            Complete checklist
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ServiceActions;
