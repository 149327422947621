import Joi from 'joi';

export const blogEntrySchema = Joi.object({
  title: Joi.string().required(),
  excerpt: Joi.string().max(255).allow(''),
  text: Joi.string().required(),
  storeable_asset_id: Joi.string().uuid().required(),
  image_caption: Joi.string().required(),
  published: Joi.boolean().required()
}).options({
  abortEarly: false
});
