import { FC, ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';

interface AlertDialogArgs {
  content: ReactElement;
  cancelText: string;
  approveText: string;
  title?: ReactElement | string;
  style?: {};
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  disableButtons?: boolean;
}

const AlertDialog: FC<AlertDialogArgs> = ({
  content,
  cancelText,
  approveText,
  title,
  style,
  open,
  onClose,
  onSave,
  disableButtons = false
}: AlertDialogArgs): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          background: '#fff',
          borderRadius: '8px',
          width: '480px',
          minHeight: '279px',
          ...style
        }
      }}
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>
        {title ? title : <WarningIcon style={{ marginTop: '28px' }} />}
      </DialogTitle>
      <DialogContent style={{ padding: '20px 0 56px' }}>{content}</DialogContent>
      <DialogActions
        style={{ margin: 'auto', justifyContent: 'space-evenly', padding: '0 0 35px 0' }}
      >
        <Button
          variant="outlined"
          type="button"
          disabled={disableButtons}
          onClick={onClose}
          style={{ width: '178px' }}
        >
          {cancelText}
        </Button>
        <Button
          variant="contained"
          type="button"
          disabled={disableButtons}
          onClick={onSave}
          autoFocus
          style={{ width: '178px' }}
        >
          {approveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
