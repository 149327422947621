import Grid from '@mui/material/Grid';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useRef,
  useState
} from 'react';
import SwipeableDrawerHeader from '../../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import FieldContainerExternal from '../../../components/field-container/FieldContainer';
import InputText from '../../../components/form/InputText';
import { DocumentContext } from '../../../context/documentContext';
import { StorableAssetContext } from '../../../context/storableAssetContext';
import { ReactComponent as DocumentsIcon } from '../../../assets/images/menu-board-wrapper.svg';
import { createDocumentInitialState } from '../../../context/reducers/documentReducer';
import InputStorableAsset from '../../../components/form/InputStorableAsset';
import { validateForm } from '../../../utils/form';
import { documentSchema } from './DocumentSchema';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { CreateDocumentDto } from '../../../utils/types/document';
import InputDate from '../../../components/form/InputDate';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={10} sm={8} md={7} lg={7} xl={7} />;
};

type DocumentFormProps = {
  vesselId: string;
  onClose: () => void;
};

const DocumentForm: FC<DocumentFormProps> = (props: DocumentFormProps) => {
  const inputStorableAssetRef = useRef<any>(null);
  const query = useQueryParams();

  const { createDocument, state: documentState } = useContext(DocumentContext);
  const { state: storableAssetState } = useContext(StorableAssetContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [form, setForm] = useState<CreateDocumentDto>({
    ...createDocumentInitialState,
    vessel_id: vesselId
  });
  const [validationErrors, setValidationErrors] = useState<CreateDocumentDto>(
    createDocumentInitialState
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleDateChange = (
    value: dayjs.Dayjs | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    setForm((prevState) => ({
      ...prevState,
      expiration_date: value?.format('YYYY-MM-DD') || null
    }));
  };

  const handleClose = () => {
    setForm(createDocumentInitialState);
    setValidationErrors(createDocumentInitialState);
    props.onClose();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const { errorSummary } = validateForm(documentSchema, form);

    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) {
      createDocument(form);
    }
  };

  return (
    <div className="container-document-form">
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}
      >
        <SwipeableDrawerHeader
          disableButtons={documentState.create.loading || storableAssetState.upload.loading}
          onClickClose={handleClose}
          onClickSave={handleSubmit}
          title={'Add new Document'}
          icon={<DocumentsIcon className="icon-title" />}
        />
        <FieldContainer>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputText
              name={'name'}
              label={'Name'}
              value={form.name}
              validationErrors={validationErrors.name}
              onChange={handleInputChange}
            />

            <InputDate
              name={'expiration_date'}
              label="Expiration date"
              calendarProps={{
                value: form.expiration_date,
                onChange: handleDateChange
              }}
              validationError={validationErrors.expiration_date || undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputStorableAsset
              ref={inputStorableAssetRef}
              label={'Select file'}
              name={'storeable_asset_id'}
              value={form.storeable_asset_id}
              assetType={'documents'}
              vesselId={props.vesselId}
              visibility={'private'}
              validationErrors={validationErrors.storeable_asset_id || undefined}
              automaticUpload={true}
              onChange={handleInputChange}
            />
          </Grid>
        </FieldContainer>
      </Grid>
    </div>
  );
};

export default DocumentForm;
