import { Pagination } from '../types/pagination';

export const defaultPagination: Pagination = {
  attributes: [],
  page: 1,
  perPage: 20,
  join: [],
  filter: '',
  search: ''
};
