import {
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  SyntheticEvent
} from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import InputText from '../../components/form/InputText';
import TitlePage from '../../components/title-page/TitlePage';
import BreadCrumb from '../../components/title-page/BreadCrumb';
import AlertDialog from '../../components/dialog/AlertDialog';
import CustomerInputAutoComplete from '../../components/form/InputCustomerAutocomplete';
import { vesselFormSchema } from '../../utils/schemas';
import { validateForm } from '../../utils/form';
import paths from '../../utils/constants/paths';
import { VesselContext } from '../../context/vesselContext';
import { vesselInitialState } from '../../context/reducers/vesselReducer';
import { ReactComponent as ShipIcon } from '../../assets/images/wrapped-ship.svg';
import { NotificationContext } from '../../context/notificationContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import TextTag from '../../components/TextTag/TextTag';
import './VesselForm.scss';
import InputStorableAsset from '../../components/form/InputStorableAsset';

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <Grid item xs={10} sm={10} md={11} lg={11} xl={11}>
      <Grid container display={'flex'} justifyContent={'space-between'}>
        {children}
      </Grid>
    </Grid>
  );
};

const VesselEdit: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const { showAlert } = useContext(NotificationContext);
  const { viewVessel, deleteVessel, updateVessel, resetVesselState, state } =
    useContext(VesselContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [form, setForm] = useState(vesselInitialState);
  const [validationErrors, setValidationErrors] = useState(vesselInitialState);
  const [formErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    resetVesselState();
    if (vesselId) viewVessel(vesselId, { join: 'owner' });

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (state.view.data) setForm(state.view.data);
  }, [state.view]);

  useEffect(() => {
    if (state.update.done) {
      showAlert({
        variant: 'success',
        title: 'Vessel',
        description: 'Vessel updated!'
      });
    }

    if (state.update.error) {
      showAlert({
        variant: 'error',
        title: 'Vessel',
        description: "Vessel couldn't be updated!"
      });
    }
  }, [state.update]);

  useEffect(() => {
    if (state.delete.done) {
      showAlert({
        variant: 'success',
        title: 'Vessel',
        description: 'Vessel deleted!'
      });
      navigate(paths.VESSELS.absolutePath);
    }

    if (state.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Vessel',
        description: "Vessel couldn't be deleted!"
      });
    }
  }, [state.delete]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      active: event.target?.checked
    }));
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    let newForm = {
      active: form.active,
      hullNumber: form.hullNumber,
      name: form.name,
      hight: form.hight,
      beam: form.beam,
      draft: form.draft,
      length: form.length,
      location: form.location,
      notes: form.notes,
      user_id: form.user_id,
      storeable_asset_id: form.storeable_asset_id
    };

    const { errorSummary } = validateForm(vesselFormSchema, newForm);
    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) {
      updateVessel(vesselId, {
        ...newForm,
        id: form.id
      });
    }
  };

  const handleChangeOwner = (_event: SyntheticEvent, owner: any | Array<any>) => {
    setForm((prevForm) => ({
      ...prevForm,
      user_id: owner?.value
    }));
  };

  const title = (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <TitlePage
        leftContainer={
          <BreadCrumb previous={state.view.data?.name || ''} current={'Vessel Info'} />
        }
        rightContainer={
          <TextTag
            text={
              state.view.data?.owner
                ? `${state.view.data?.owner.name} ${state.view.data?.owner.lastName}`
                : ''
            }
            tag={'Owner'}
          />
        }
      />
    </Grid>
  );

  const actions = (
    <FieldContainer>
      <Grid item xs={12} sm={12} md={7} lg={9} xl={9} />
      <Grid item xs={12} sm={12} md={1.8} lg={1.2} xl={1.2}>
        <Button
          variant="outlined"
          type="button"
          color="error"
          className="action-buttons"
          onClick={() => setOpenDialog(true)}
          disabled={state.view.loading}
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={1.5} lg={1} xl={1}>
        <Button
          variant="contained"
          type="button"
          className="action-buttons"
          onClick={handleSubmit}
          disabled={state.view.loading || state.update.loading}
        >
          Save
        </Button>
      </Grid>
    </FieldContainer>
  );

  const formComponent = (
    <>
      <Grid item xs={10} sm={10} md={11} lg={11} xl={11}>
        <Grid
          container
          display={'flex'}
          justifyContent={'start'}
          alignItems={'center'}
          className="vessel-edit-title"
        >
          <ShipIcon className="icon-title" />
          <p className="text-title">Edit vessel info</p>
        </Grid>
      </Grid>
      <FieldContainer>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <InputText
            name={'name'}
            label={'Name'}
            value={`${form?.name}`}
            validationErrors={validationErrors.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <CustomerInputAutoComplete
            name="user_id"
            errorSummary={!!validationErrors?.user_id}
            value={form.user_id || null}
            onChange={handleChangeOwner}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer>
        <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
          <InputText
            name={'hullNumber'}
            label={'Hull number'}
            value={`${form?.hullNumber}`}
            validationErrors={validationErrors.hullNumber}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
          <InputText
            name={'hight'}
            label={'Hight'}
            value={`${form?.hight}`}
            validationErrors={validationErrors.hight}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
          <InputText
            name={'beam'}
            label={'Beam'}
            value={`${form?.beam}`}
            validationErrors={validationErrors.beam}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <InputText
            name={'draft'}
            label={'Draft'}
            value={`${form?.draft}`}
            validationErrors={validationErrors.draft}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <InputText
            name={'length'}
            label={'Length'}
            value={`${form?.length}`}
            validationErrors={validationErrors.length}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <InputText
            name={'location'}
            label={'Location'}
            value={`${form?.location}`}
            validationErrors={validationErrors.location}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
          <InputText
            name={'notes'}
            label={'Notes'}
            value={`${form?.notes}`}
            multiline
            minRows={3}
            validationErrors={validationErrors.notes}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
            width={'100%'}
            maxLength={500}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <InputStorableAsset
            label={'Upload image'}
            name={'storeable_asset_id'}
            value={form?.storeable_asset_id}
            automaticUpload={true}
            assetType="vessel-picture"
            vesselId={vesselId}
            visibility="public"
            validationErrors={validationErrors.storeable_asset_id || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
          />
        </Grid>
      </FieldContainer>
      <FieldContainer>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Checkbox
            style={{ paddingLeft: '0px' }}
            checked={form?.active || false}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckBoxChange(e)}
          />
          <FormLabel component="label" className={'regular'}>
            Active
          </FormLabel>
        </Grid>
      </FieldContainer>
    </>
  );

  const removeModal = (
    <AlertDialog
      content={
        <Grid
          container
          direction="row"
          alignItems="center"
          margin={'auto'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
            <span style={{ color: '#1F1F1F', fontSize: '14px' }}>
              Are you sure you want to remove the vessel <strong>{state.view.data?.name}</strong>?
            </span>
          </Grid>
        </Grid>
      }
      cancelText={'No, cancel'}
      approveText={'Yes, remove'}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      onSave={() => {
        setOpenDialog(false);
        deleteVessel(vesselId, showAlert, () => navigate(paths.VESSELS.absolutePath));
      }}
    />
  );

  return (
    <>
      {title}
      <div className="vessel-edit-container">
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          margin={'auto'}
          justifyContent={'center'}
        >
          {formComponent}
          {actions}
          <FormHelperText error>{formErrorMessage}</FormHelperText>
        </Grid>
      </div>
      {openDialog && removeModal}
    </>
  );
};

export default VesselEdit;
