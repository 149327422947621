import { FC, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import UserProfile, { UserProfileForwardedRef } from '../../components/user-profile/UserProfile';
import UpdatePasswordForm, {
  UpdatePasswordFormForwardedRef
} from '../../components/update-password-form/UpdatePasswordForm';
import { userInitialState } from '../../context/reducers/adminReducer';
import { UserBasicInfo, UserInterface } from '../../utils/types/user';
import { MyProfileContext } from '../../context/myProfileContext';
import { myProfileInitialState } from '../../context/reducers/myProfileReducer';
import { NotificationContext } from '../../context/notificationContext';

const EditProfile: FC = (): ReactElement => {
  const userProfileRef = useRef<UserProfileForwardedRef>(null);
  const updatePasswordFormRef = useRef<UpdatePasswordFormForwardedRef>(null);

  const {
    viewMyProfileInfo,
    updateMyProfileInfo,
    updateMyProfilePassword,
    state: myProfileState
  } = useContext(MyProfileContext);
  const { showAlert } = useContext(NotificationContext);

  const [profileForm, setProfileForm] = useState<UserBasicInfo>(myProfileInitialState);
  const [profileFormValidationErrors] = useState(userInitialState);

  useEffect(() => {
    viewMyProfileInfo();

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (
      !myProfileState.viewInfo.error &&
      myProfileState.viewInfo.done &&
      myProfileState.viewInfo.data
    ) {
      setProfileForm(myProfileState.viewInfo.data);
    }
  }, [myProfileState.viewInfo]);

  useEffect(() => {
    if (!myProfileState.updateInfo.error && myProfileState.updateInfo.done) {
      showAlert({
        variant: 'success',
        title: 'Done',
        description: 'Profile info updated!'
      });
    } else if (myProfileState.updateInfo.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "Profile couldn't be updated!"
      });
    }
  }, [myProfileState.updateInfo]);

  useEffect(() => {
    if (!myProfileState.updatePassword.error && myProfileState.updatePassword.done) {
      showAlert({
        variant: 'success',
        title: 'Done',
        description: 'Password updated!'
      });

      if (updatePasswordFormRef.current) updatePasswordFormRef.current.resetForm();
    } else if (myProfileState.updatePassword.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "Password couldn't be updated!"
      });
    }
  }, [myProfileState.updatePassword]);

  const onClickSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (userProfileRef.current) userProfileRef.current.getUserBasicInfo();
  };

  const onSubmitUpdateProfile = (form: UserBasicInfo) => {
    updateMyProfileInfo(form);
  };

  const onSubmitUpdatePassword = (password: string) => {
    updateMyProfilePassword({ password });
  };

  const updateProfileAction = (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid container display={'flex'} justifyContent={'flex-end'}>
        <Grid item>
          <Button
            variant="contained"
            type="button"
            onClick={onClickSave}
            style={buttonStyle}
            disabled={myProfileState.updateInfo.loading}
          >
            Update profile
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={0}
      maxWidth={'md'}
      direction="row"
      alignItems="center"
      margin={'41px 0 36px 0'}
      justifyContent={'center'}
    >
      <UserProfile
        ref={userProfileRef}
        sendForm={onSubmitUpdateProfile}
        user={profileForm as UserInterface}
        validationErrors={profileFormValidationErrors}
        wide={true}
      />

      {updateProfileAction}

      <Divider style={dividerStyle} />

      <UpdatePasswordForm
        ref={updatePasswordFormRef}
        variant="wide"
        onSubmit={onSubmitUpdatePassword}
        containerBreakpoints={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        buttonBreakpoints={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
        buttonText={'Save new password'}
        buttonDisabled={myProfileState.updatePassword.loading}
      />
    </Grid>
  );
};

export default EditProfile;

const dividerStyle = { width: '100%', margin: '20px' };
const buttonStyle = { width: '100%', marginBottom: '20px' };
