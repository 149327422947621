import moment from 'moment';
import { DocumentInterface } from '../../../utils/types/document';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { Badge } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-document.svg';
import './DocumentsList.scss';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';

const getDocumentsGridColumns = (
  onDownload: (document: DocumentInterface) => void,
  onDelete: (document: DocumentInterface) => void
): Array<MRT_ColumnDef<DocumentInterface>> => [
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'expiration_date',
      header: 'Expiration date',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const formattedDate = moment(date).format('DD/MM/YYYY')

        if (!date) {
          return '--';
        } else {
          return (
            <span style={{ color: '#52659B', fontWeight: 500 }}>
              {formattedDate}
            </span>
          );
        }
      }
    },
    {
      accessorKey: 'state',
      header: 'State',
      Cell: ({ cell }) => {
        const value = cell.getValue<string>();
        const stateHexaMap: { [key: string]: string } = {
          green: '#4CAF50',
          yellow: '#FFC107',
          red: '#F44336'
        };

        return (
          <Badge
            color="primary"
            badgeContent={''}
            sx={{
              '.MuiBadge-badge': {
                backgroundColor: stateHexaMap[value]
              }
            }}
          />
        );
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onDownload(cell.row.original)}
          >
            <DownloadIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDelete(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getDocumentsGridColumns;
