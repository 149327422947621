enum VesselsActionsTypes {
  CREATE_VESSEL_IN_PROGRESS = 'CREATE_VESSEL_IN_PROGRESS',
  CREATE_VESSEL_SUCCESS = 'CREATE_VESSEL_SUCCESS',
  CREATE_VESSEL_ERROR = 'CREATE_VESSEL_ERROR',

  LIST_VESSELS_IN_PROGRESS = 'LIST_VESSELS_IN_PROGRESS',
  LIST_VESSELS_SUCCESS = 'LIST_VESSELS_SUCCESS',
  LIST_VESSELS_ERROR = 'LIST_VESSELS_ERROR',

  VIEW_VESSEL_IN_PROGRESS = 'VIEW_VESSEL_IN_PROGRESS',
  VIEW_VESSEL_SUCCESS = 'VIEW_VESSEL_SUCCESS',
  VIEW_VESSEL_ERROR = 'VIEW_VESSEL_ERROR',

  UPDATE_VESSEL_IN_PROGRESS = 'UPDATE_VESSEL_IN_PROGRESS',
  UPDATE_VESSEL_SUCCESS = 'UPDATE_VESSEL_SUCCESS',
  UPDATE_VESSEL_ERROR = 'UPDATE_VESSEL_ERROR',

  DELETE_VESSEL_IN_PROGRESS = 'DELETE_VESSEL_IN_PROGRESS',
  DELETE_VESSEL_SUCCESS = 'DELETE_VESSEL_SUCCESS',
  DELETE_VESSEL_ERROR = 'DELETE_VESSEL_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default VesselsActionsTypes;
