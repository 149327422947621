import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TemplateBox from '../TemplateBox';
import TitlePage from '../../../components/title-page/TitlePage';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import InputText from '../../../components/form/InputText';
import WideBar from '../../../components/wide-bar/WideBar';
import CheckListTemplateCreateGroup from './CheckListTemplateCreateGroup';
import { ReactComponent as AddIcon } from '../../../assets/images/add-square.svg';
import CheckListTemplateGroupList from './CheckListTemplateGroupList';
import { ChecklistTemplateContext } from '../../../context/checklistTemplateContext';
import { NotificationContext } from '../../../context/notificationContext';
import paths from '../../../utils/constants/paths';
import { ChecklistGroup } from '../../../utils/types/checklist';
import { checklistInitialState } from '../../../context/reducers/checklistReducer';
import { validateForm } from '../../../utils/form';
import { groupNameSchema } from './checklistSchema';
import { useQueryParams } from '../../../hooks/useQueryParams';
import './ChecklistTemplate.scss';

export type GroupType = ChecklistGroup & {
  edit?: boolean;
};

const CheckListTemplateForm: FC = (): ReactElement => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const {
    createChecklistTemplate,
    viewChecklistTemplate,
    updateChecklistTemplate,
    resetChecklistTemplateState,
    state
  } = useContext(ChecklistTemplateContext);
  const { showAlert } = useContext(NotificationContext);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [checklistTemplateId] = useState<string>(query.get('id') || '');
  const [checklistTemplateName, setChecklistTemplateName] = useState<string>(
    checklistInitialState.name
  );
  const [groups, setGroups] = useState<GroupType[]>();
  const [groupToEdit, setGroupToEdit] = useState<{ group: ChecklistGroup; edit: boolean }>();
  const [validationErrors, setValidationErrors] = useState(checklistInitialState);

  useEffect(() => {
    if (checklistTemplateId) viewChecklistTemplate(checklistTemplateId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (state.create.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template created!'
      });

      resetChecklistTemplateState();
      navigate(paths.TEMPLATE_CHECKLIST.absolutePath);
    }

    if (state.create.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be created!"
      });
    }
  }, [state.create]);

  useEffect(() => {
    if (state.update.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template edited!'
      });

      resetChecklistTemplateState();
      navigate(paths.TEMPLATE_CHECKLIST.absolutePath);
    }

    if (state.update.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be edited!"
      });
    }
  }, [state.update]);

  useEffect(() => {
    setChecklistTemplateName(state.view.data?.name || '');
    setGroups(JSON.parse(state.view.data?.template || '[]'));
  }, [state.view.data]);

  const handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const { errorSummary } = validateForm(groupNameSchema, { name: checklistTemplateName });

    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length && groups && groups?.length! > 0) {
      if (checklistTemplateId !== '') {
        updateChecklistTemplate(checklistTemplateId, {
          name: checklistTemplateName,
          template: JSON.stringify(groups)
        });
      } else
        createChecklistTemplate({
          name: checklistTemplateName,
          template: JSON.stringify(groups)
        });
    }
  };

  const onClickEdit = (groupToEdit: ChecklistGroup) => {
    const newGroups = groups?.map((g) => {
      if (g.name === groupToEdit.name && g.fields === groupToEdit.fields) {
        setGroupToEdit({ group: groupToEdit, edit: true });
        return { ...g, edit: true };
      }
      return g;
    });
    if (newGroups) setGroups(newGroups);

    setShowGroupForm(true);
  };

  const addGroup = (groupInfo: GroupType) => {
    if (groups) {
      if (groupInfo.edit) {
        const tempGroups = [...groups];
        const editingIndex = groups.findIndex((g) => g.edit === true);
        groupInfo.edit = false;
        tempGroups[editingIndex] = groupInfo;
        setGroups([...tempGroups]);
        setGroupToEdit(undefined);
      } else setGroups([...groups, groupInfo]);
    } else setGroups([groupInfo]);
    setShowGroupForm(false);
  };

  const template = (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={'Checklist template'}
            current={checklistTemplateId ? 'Update' : 'Create a new'}
          />
        }
        rightContainer={
          <Button
            disabled={state.update.loading || state.create.loading}
            variant="contained"
            onClick={(e) => handleSubmitButton(e)}
          >
            Save
          </Button>
        }
      />

      <Grid container style={{ marginTop: '65px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TemplateBox minHeight={'calc(100vh - 240px)'}>
            <Grid container>
              <InputText
                name={'templateName'}
                label={'Checklist template name'}
                value={checklistTemplateName}
                validationErrors={validationErrors.name}
                onChange={(e) => setChecklistTemplateName(e.target.value)}
              />
            </Grid>
            {groups &&
              groups.map((g, i) => {
                return (
                  <CheckListTemplateGroupList
                    key={i}
                    name={g.name}
                    fields={g.fields}
                    onEdit={() => onClickEdit(g)}
                  />
                );
              })}
            <div style={{ width: '100%' }}>
              <WideBar
                text={'Create a new group'}
                icon={<AddIcon />}
                onClickIcon={() => setShowGroupForm(true)}
              />
            </div>
          </TemplateBox>
        </Grid>
      </Grid>
    </>
  );

  return showGroupForm ? (
    <CheckListTemplateCreateGroup
      saveGroup={(groupInfo) => addGroup(groupInfo)}
      groupInfo={groupToEdit}
    />
  ) : (
    template
  );
};

export default CheckListTemplateForm;
