enum CrewRequestActionsTypes {
  LIST_CREATED_CREW_REQUESTS_IN_PROGRESS = 'LIST_CREATED_CREW_REQUESTS_IN_PROGRESS',
  LIST_CREATED_CREW_REQUESTS_SUCCESS = 'LIST_CREATED_CREW_REQUESTS_SUCCESS',
  LIST_CREATED_CREW_REQUESTS_ERROR = 'LIST_CREATED_CREW_REQUESTS_ERROR',

  LIST_ACCEPTED_CREW_REQUESTS_IN_PROGRESS = 'LIST_ACCEPTED_CREW_REQUESTS_IN_PROGRESS',
  LIST_ACCEPTED_CREW_REQUESTS_SUCCESS = 'LIST_ACCEPTED_CREW_REQUESTS_SUCCESS',
  LIST_ACCEPTED_CREW_REQUESTS_ERROR = 'LIST_ACCEPTED_CREW_REQUESTS_ERROR',

  LIST_REJECTED_CREW_REQUESTS_IN_PROGRESS = 'LIST_REJECTED_CREW_REQUESTS_IN_PROGRESS',
  LIST_REJECTED_CREW_REQUESTS_SUCCESS = 'LIST_REJECTED_CREW_REQUESTS_SUCCESS',
  LIST_REJECTED_CREW_REQUESTS_ERROR = 'LIST_REJECTED_CREW_REQUESTS_ERROR',

  LIST_CANCELLED_CREW_REQUESTS_IN_PROGRESS = 'LIST_CANCELLED_CREW_REQUESTS_IN_PROGRESS',
  LIST_CANCELLED_CREW_REQUESTS_SUCCESS = 'LIST_CANCELLED_CREW_REQUESTS_SUCCESS',
  LIST_CANCELLED_CREW_REQUESTS_ERROR = 'LIST_CANCELLED_CREW_REQUESTS_ERROR',

  UPDATE_CREW_REQUEST_IN_PROGRESS = 'UPDATE_CREW_REQUEST_IN_PROGRESS',
  UPDATE_CREW_REQUEST_SUCCESS = 'UPDATE_CREW_REQUEST_SUCCESS',
  UPDATE_CREW_REQUEST_ERROR = 'UPDATE_CREW_REQUEST_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default CrewRequestActionsTypes;
