import moment from 'moment';
import { ReactComponent as EditIcon } from '../../assets/images/pencil.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/eye.svg';
import StatusSlot from '../../components/number-slot/StatusSlot';
import { BlogEntryInterface } from '../../utils/types/blogEntry';
import { Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';

const getBlogsGridColumns = (
  onViewBlog: (blog: BlogEntryInterface) => void,
  onEditBlog: (blog: BlogEntryInterface) => void,
  onDeleteBlog: (blog: BlogEntryInterface) => void
): Array<MRT_ColumnDef<BlogEntryInterface>> => [
    {
      accessorKey: 'createdAt',
      header: 'Date',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ cell, row }) => {
        const title = cell.getValue<string>();
        const published = row.original.published;

        return (
          <>
            <Typography sx={{ fontSize: '13px', marginRight: '10px', display: 'inline' }} noWrap>
              {title}
            </Typography>
            {!published && (
              <StatusSlot value={'Draft'} backgroundValue="#273F82" backgroundSlot="#D4D9E6" />
            )}
          </>
        );
      }
    },
    {
      accessorKey: 'updatedAt',
      header: 'Last Updated',
      Cell: ({ cell }) => {
        const rawDate = cell.getValue<string>();
        const date = new Date(rawDate);
        const humanReadableDate = rawDate ? moment(date).fromNow() : '--';

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Edit"
            onClick={() => onEditBlog(cell.row.original)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="View"
            onClick={() => onViewBlog(cell.row.original)}
          >
            <ViewIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDeleteBlog(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getBlogsGridColumns;
