enum VesselMediaFilesActionsTypes {
  CREATE_VESSEL_MEDIA_FILE_IN_PROGRESS = 'CREATE_VESSEL_MEDIA_FILE_IN_PROGRESS',
  CREATE_VESSEL_MEDIA_FILE_SUCCESS = 'CREATE_VESSEL_MEDIA_FILE_SUCCESS',
  CREATE_VESSEL_MEDIA_FILE_ERROR = 'CREATE_VESSEL_MEDIA_FILE_ERROR',

  CREATE_VESSEL_MEDIA_FILE_BULK_IN_PROGRESS = 'CREATE_VESSEL_MEDIA_FILE_BULK_IN_PROGRESS',
  CREATE_VESSEL_MEDIA_FILE_BULK_SUCCESS = 'CREATE_VESSEL_MEDIA_FILE_BULK_SUCCESS',
  CREATE_VESSEL_MEDIA_FILE_BULK_ERROR = 'CREATE_VESSEL_MEDIA_FILE_BULK_ERROR',

  LIST_VESSEL_MEDIA_FILES_IN_PROGRESS = 'LIST_VESSEL_MEDIA_FILES_IN_PROGRESS',
  LIST_VESSEL_MEDIA_FILES_SUCCESS = 'LIST_VESSEL_MEDIA_FILES_SUCCESS',
  LIST_VESSEL_MEDIA_FILES_ERROR = 'LIST_VESSEL_MEDIA_FILES_ERROR',

  VIEW_VESSEL_MEDIA_FILE_IN_PROGRESS = 'VIEW_VESSEL_MEDIA_FILE_IN_PROGRESS',
  VIEW_VESSEL_MEDIA_FILE_SUCCESS = 'VIEW_VESSEL_MEDIA_FILE_SUCCESS',
  VIEW_VESSEL_MEDIA_FILE_ERROR = 'VIEW_VESSEL_MEDIA_FILE_ERROR',

  UPDATE_VESSEL_MEDIA_FILE_IN_PROGRESS = 'UPDATE_VESSEL_MEDIA_FILE_IN_PROGRESS',
  UPDATE_VESSEL_MEDIA_FILE_SUCCESS = 'UPDATE_VESSEL_MEDIA_FILE_SUCCESS',
  UPDATE_VESSEL_MEDIA_FILE_ERROR = 'UPDATE_VESSEL_MEDIA_FILE_ERROR',

  DELETE_VESSEL_MEDIA_FILE_IN_PROGRESS = 'DELETE_VESSEL_MEDIA_FILE_IN_PROGRESS',
  DELETE_VESSEL_MEDIA_FILE_SUCCESS = 'DELETE_VESSEL_MEDIA_FILE_SUCCESS',
  DELETE_VESSEL_MEDIA_FILE_ERROR = 'DELETE_VESSEL_MEDIA_FILE_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default VesselMediaFilesActionsTypes;
