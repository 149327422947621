import { FC, ReactElement } from 'react';

interface HeaderTextArgs {
  text: string | undefined;
}

const HeaderText: FC<HeaderTextArgs> = ({ text }: HeaderTextArgs): ReactElement => {
  return <span style={{ color: 'black', fontSize: '24px' }}>{text}</span>;
};

export default HeaderText;
