import { UscgSafetyInterface } from '../utils/types/uscgSafety';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type CreateUscgSafetyDto = Pick<
  UscgSafetyInterface,
  'title' | 'expiration_date' | 'additional_information' | 'vessel_id'
>;

export default class UscgSafetyService extends ApiService {
  create = async (dto: CreateUscgSafetyDto) => {
    const { data } = await this.axios.post('uscg-safety', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('uscg-safety', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`uscg-safety/${id}`);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`uscg-safety/${id}`);

    return data;
  };
}
