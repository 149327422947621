import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { FC, ReactElement } from 'react';
import {
  CalendarProps,
  CheckBoxProps,
  FieldsType,
  NumberProps,
  TextProps
} from '../../utils/types/common';
import InputText from '../form/InputText';
import InputDate from '../form/InputDate';

export interface FieldsFactoryProps {
  type: FieldsType;
  name: string;
  disabled?: boolean;
  rest: any;
  validationError?: string;
  minDate?: Date;
  maxDate?: Date;
}

const FieldsFactory: FC<FieldsFactoryProps> = (props): ReactElement => {
  const { type, name, disabled, validationError, rest, minDate, maxDate } = props;

  const inputText = (
    <InputText
      name={name}
      label={name}
      disabled={disabled}
      value={(rest as TextProps).value}
      validationErrors={validationError}
      onChange={(e) => (rest as TextProps).onChange(e)}
    />
  );

  const number = (
    <FormControl fullWidth>
      <FormLabel component="label" className={'regular'}>
        {name}
      </FormLabel>

      <TextField
        required
        type={type}
        name={name}
        disabled={disabled}
        value={(rest as NumberProps).value}
        onChange={(rest as NumberProps).onChange}
        error={!!validationError}
        helperText={validationError || ''}
      />
    </FormControl>
  );

  const datePicker = (
    <InputDate
      name={name}
      disabled={disabled}
      validationError={validationError}
      calendarProps={rest as CalendarProps}
      minDate={minDate}
      maxDate={maxDate}
    />
  );

  const checkbox = (
    <FormControl error={!!validationError} fullWidth>
      <FormLabel
        error={!!validationError}
        component="label"
        className={'regular'}
        style={{ opacity: 0 }}
      >
        {name}
      </FormLabel>

      <FormControlLabel
        value={Boolean((rest as CheckBoxProps).value)}
        disabled={disabled}
        control={
          <Checkbox
            name={name}
            checked={(rest as CheckBoxProps).value}
            onChange={(rest as CheckBoxProps).onChange}
          />
        }
        label={name}
        labelPlacement="end"
        sx={{
          width: '100%',
          marginLeft: 0,
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          height: '55px',
          padding: '16.5px 14px'
        }}
      />
    </FormControl>
  );

  switch (type) {
    case 'text':
      return inputText;
    case 'number':
      return number;
    case 'calendar':
      return datePicker;
    case 'checkBox':
      return checkbox;
    default:
      throw new Error('Action not supported');
  }
};

export default FieldsFactory;
