import useAutocomplete, { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-normal.svg';

const Root = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  font-size: 14px;
`
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const SlotNumber = styled('div')(`
  background: #d4d9e6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 28px;
  border-radius: 8px;
    `);

const InputWrapper = styled('div')(
  ({ theme }) => `
      width: 100%;
      height: auto;
      min-height: 56px;
      border: 1px solid #CECFD2;
      background-color: #fff;
      border-radius: 4px;
      padding: 1px;
      display: flex;
      flex-wrap: wrap;

      &:hover {
        border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
      }

      &.focused {
        border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }

      & input {
        background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
        color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
        border: 1px solid #CECFD2;
        height: 56px;
        box-sizing: border-box;
        padding: 4px 6px;
        width: 0;
        min-width: 30px;
        flex-grow: 1;
        border: 0;
        margin: 0;
        outline: 0;
      }
    `
);

const Input = styled('input')(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  color: theme.palette.mode === 'light' ? '#000' : '#fff'
}));

const Listbox = styled('ul')(
  ({ theme }) => `
  position: absolute;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 12px 20px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#d4d9e6'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#fafafa'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

const Tag = (props: TagProps) => {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
};

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  min-height: 41px;
  margin: 7px 16px 0 0;
  line-height: 22px;
  color: #52659B;
  background-color: #D4D9E6;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 50px;
  box-sizing: content-box;
  padding: 0 12px 0 15px;
  outline: 0;
  overflow: hidden;


  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

export type InputTagOption<T> = {
  id: string;
  label: string;
  value: string;
  objectRef: T;
};

export type InputTagsArgs<T> = {
  title: string;
  options: InputTagOption<T>[];
  value: InputTagOption<T>[];
  onChange: (selected: InputTagOption<T>[]) => void;
};

export default function UseAutocomplete<T>({ options, value, onChange, title }: InputTagsArgs<T>) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getTagProps,
    getOptionProps,
    groupedOptions,
    focused,
    value: selected,
    popupOpen,
    setAnchorEl
  } = useAutocomplete({
    id: 'vessels-assigned',
    options,
    getOptionLabel: (option) => option.label,
    value,
    isOptionEqualToValue: (option: InputTagOption<T>, value: InputTagOption<T>) => {
      return option.id === value.id;
    },
    multiple: true,
    onChange: (event, newValue) => onChange(newValue),
    openOnFocus: false
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Label {...getInputLabelProps()} style={{ margin: '10px 0 10px 0' }}>
            {title}
          </Label>
          <SlotNumber>
            <span style={{ color: '#273F82', fontWeight: 'bold' }}>{value.length}</span>
          </SlotNumber>
        </Grid>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          <Input {...getInputProps()} />
          <SearchIcon style={{ margin: 'auto 20px' }} />
        </InputWrapper>
      </div>

      {popupOpen && groupedOptions.length > 0 && (
        <div style={{ position: 'relative', width: '100%' }}>
          <Listbox {...getListboxProps()}>
            {(groupedOptions as typeof value).map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option.label}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        </div>
      )}

      {selected.length > 0 ? (
        <div style={{ marginTop: '20px' }}>
          {value.map((option: InputTagOption<T>, index: number) => (
            <StyledTag label={option.label} {...getTagProps({ index })} />
          ))}
        </div>
      ) : (
        <></>
      )}
    </Root>
  );
}
