import TankLevelsService, {
  CreateTankLevelDto,
  UpdateTankLevelDto
} from '../../services/tankLevels';
import { Pagination } from '../../utils/types/pagination';
import TankLevelActionsTypes from './types/tankLevel';
import { errorPayload } from './utils/errorPayload';

export const createTankLevel = (dispatch: any) => {
  return async (dto: CreateTankLevelDto) => {
    dispatch({ type: TankLevelActionsTypes.CREATE_TANK_LEVEL_IN_PROGRESS });

    try {
      const tankLevelService = new TankLevelsService();
      await tankLevelService.create(dto);

      dispatch({ type: TankLevelActionsTypes.CREATE_TANK_LEVEL_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelActionsTypes.CREATE_TANK_LEVEL_ERROR, payload });
    }
  };
};

export const listTankLevels = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: TankLevelActionsTypes.LIST_TANK_LEVELS_IN_PROGRESS });

    try {
      const tankLevelService = new TankLevelsService();
      const tankLevelsPaginated = await tankLevelService.list(pagination);

      dispatch({
        type: TankLevelActionsTypes.LIST_TANK_LEVELS_SUCCESS,
        payload: tankLevelsPaginated
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: TankLevelActionsTypes.LIST_TANK_LEVELS_ERROR, payload });
    }
  };
};

export const viewTankLevel = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: TankLevelActionsTypes.VIEW_TANK_LEVEL_IN_PROGRESS });

    try {
      const tankLevelService = new TankLevelsService();
      const tankLevel = await tankLevelService.view(id);

      dispatch({ type: TankLevelActionsTypes.VIEW_TANK_LEVEL_SUCCESS, payload: tankLevel });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: TankLevelActionsTypes.VIEW_TANK_LEVEL_ERROR, payload });
    }
  };
};

export const updateTankLevel = (dispatch: any) => {
  return async (id: string, dto: UpdateTankLevelDto) => {
    dispatch({ type: TankLevelActionsTypes.UPDATE_TANK_LEVEL_IN_PROGRESS });

    try {
      const tankLevelService = new TankLevelsService();
      await tankLevelService.update(id, dto);

      dispatch({ type: TankLevelActionsTypes.UPDATE_TANK_LEVEL_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelActionsTypes.UPDATE_TANK_LEVEL_ERROR, payload });
    }
  };
};

export const deleteTankLevel = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: TankLevelActionsTypes.DELETE_TANK_LEVEL_IN_PROGRESS });

    try {
      const tankLevelService = new TankLevelsService();
      await tankLevelService.delete(id);

      dispatch({ type: TankLevelActionsTypes.DELETE_TANK_LEVEL_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelActionsTypes.DELETE_TANK_LEVEL_ERROR, payload });
    }
  };
};

export const resetTankLevelState = (dispatch: any) => {
  return async () => dispatch({ type: TankLevelActionsTypes.RESET_STATE });
};
