import Joi from 'joi';
import { userFormSchema } from '../../utils/schemas';

export const createAdminSchema = Joi.object({
  ...userFormSchema,
  date: Joi.any(),
  assignedVessels: Joi.any()
}).options({
  abortEarly: false
});

export const updateAdminSchema = Joi.object({
  ...userFormSchema,
  date: Joi.any(),
  assignedVessels: Joi.any()
}).options({
  abortEarly: false
});
