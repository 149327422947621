import Grid from '@mui/material/Grid';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TankLevelsIcon } from '../../../assets/images/tank-levels-wrapper.svg';
import TitleGrid from '../../../components/grid/TitleGrid';
import ServiceActions from '../../../components/service-actions/ServiceActions';
import SliderTanks from '../../../components/slider/TanksSlider';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { tankLevelInitialState } from '../../../context/reducers/tankLevelReducer';
import { TankLevelContext } from '../../../context/tankLevelContext';
import { TankLevelTemplateContext } from '../../../context/tankLevelTemplateContext';
import { VesselContext } from '../../../context/vesselContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import paths from '../../../utils/constants/paths';
import { TankLevelInterface, TankLevelItemInterface } from '../../../utils/types/tankLevel';
import TemplateBox from '../../form-templates/TemplateBox';
import './TankLevelEdit.scss';

const TankLevelEdit: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const {
    viewTankLevelTemplate,
    resetTankLevelsTemplateState,
    state: tankLevelTemplateState
  } = useContext(TankLevelTemplateContext);
  const {
    createTankLevel,
    viewTankLevel,
    updateTankLevel,
    resetTankLevelState,
    state: tankLevelState
  } = useContext(TankLevelContext);
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { showAlert } = useContext(NotificationContext);

  const [tankLevelId] = useState<string | null>(query.get('id') || '');
  const [tankLevelTemplateId] = useState<string | null>(query.get('tankLevelTemplateId') || '');
  const [vesselId] = useState<string | null>(query.get('vesselId') || '');
  const [template, setTemplate] = useState<TankLevelItemInterface[]>([]);
  const [tankLevel, setTankLevel] = useState<TankLevelInterface>(tankLevelInitialState);

  useEffect(() => {
    resetTankLevelsTemplateState();
    resetTankLevelState();

    if (tankLevelId) viewTankLevel(tankLevelId);
    if (!vesselState.view.data?.id && vesselId) viewVessel(vesselId);
    if (tankLevelTemplateId) viewTankLevelTemplate(tankLevelTemplateId);
  }, []);

  useEffect(() => {
    if (tankLevelTemplateState.view.data?.template) {
      const parsedTemplate = JSON.parse(tankLevelTemplateState.view.data?.template || '{}');

      setTemplate(parsedTemplate);
    } else {
      setTemplate([]);
    }
  }, [tankLevelTemplateState.view.data]);

  useEffect(() => {
    if (tankLevelState.view.data?.template) {
      const parsedTemplate = JSON.parse(tankLevelState.view.data?.template || '{}');

      setTankLevel(tankLevelState.view.data);
      setTemplate(parsedTemplate);
    } else {
      setTankLevel(tankLevelInitialState);
      setTemplate([]);
    }
  }, [tankLevelState.view.data]);

  useEffect(() => {
    if (tankLevelState.create.done) {
      showAlert({
        variant: 'success',
        title: 'Tank Level',
        description: 'Tank Level created!'
      });

      resetTankLevelState();
      navigate({
        pathname: paths.VESSEL_TANK_LEVELS.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (tankLevelState.create.error) {
      showAlert({
        variant: 'error',
        title: 'Tank Level',
        description: "Tank Level couldn't be created!"
      });
    }
  }, [tankLevelState.create]);

  useEffect(() => {
    if (tankLevelState.update.done) {
      showAlert({
        variant: 'success',
        title: 'Tank Level',
        description: 'Tank Level updated!'
      });

      resetTankLevelState();
      navigate({
        pathname: paths.VESSEL_TANK_LEVELS.absolutePath,
        search: `vesselId=${vesselId}`
      });
    }

    if (tankLevelState.update.error) {
      showAlert({
        variant: 'error',
        title: 'Tank Level',
        description: "Tank Level couldn't be updated!"
      });
    }
  }, [tankLevelState.update]);

  const onSliderChange = (value: number, index: number) => {
    let newLevels = template;
    newLevels[index] = {
      ...newLevels[index],
      value: value
    };

    setTankLevel((tank) => ({ ...tank, levels: newLevels }));
  };

  const returnDetailsList = () => {
    return (
      <Grid
        container
        spacing={0}
        display={'flex'}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'space-between'}
      >
        {template.map((tank, i) => {
          return (2 * i) % 2 === 0 ? (
            <Grid item key={i} xs={12} sm={12} md={5.8} lg={5.8} xl={5.8} marginBottom={'41px'}>
              <SliderTanks
                color={tank.color}
                value={tank.value}
                name={tank.label}
                max={tank.maxLevel}
                isDisabled={false}
                onChange={(_e, value, _at) => onSliderChange(value as number, i)}
              />
            </Grid>
          ) : (
            <></>
          );
        })}
      </Grid>
    );
  };

  const handleSubmit = () => {
    if (tankLevelTemplateId)
      createTankLevel({
        name: tankLevelTemplateState.view.data?.name || '',
        template: JSON.stringify(template),
        vessel_id: vesselId || ''
      });

    if (tankLevelId)
      updateTankLevel(tankLevelId, {
        name: tankLevel.name,
        template: JSON.stringify(template),
        vessel_id: tankLevel.vessel_id
      });
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || ''}
            middle={'Tank Levels'}
            current={tankLevelState.view.data?.name || ''}
          />
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={gridContainerStyle}>
        <TemplateBox minHeight={'calc(100vh - 240px)'} paperStyle={paperStyle}>
          <div>
            <div className="tank-details-top-container">
              <TitleGrid
                icon={<TankLevelsIcon className="icon-title" />}
                title={tankLevelState.view.data?.name || ''}
              />
            </div>
            {returnDetailsList()}
          </div>
          <ServiceActions
            onSave={handleSubmit}
            disabled={tankLevelState.create.loading || tankLevelState.update.loading}
          />
        </TemplateBox>
      </Grid>
    </>
  );
};

export default TankLevelEdit;

const gridContainerStyle = { marginTop: '71px', marginBottom: '49px' };
const paperStyle = { display: 'grid', alignContent: 'space-between' };
