import ServiceRecordActionTypes from '../actions/types/serviceRecord';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import {
  CreateServiceRecordDto,
  ServiceRecordAction,
  ServiceRecordInterface,
  ServiceRecordsStateInterface
} from '../../utils/types/serviceRecord';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<ServiceRecordInterface>()

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: ServiceRecordsStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  delete: actionInitialState
};

export const serviceRecordInitialState: ServiceRecordInterface = {
  id: '',
  title: '',
  vessel_id: '',
  storeable_asset_id: '',
  vessel: null,
  storeableAsset: null
};

export const createServiceRecordInitialState: CreateServiceRecordDto = {
  title: '',
  vessel_id: '',
  storeable_asset_id: ''
};

export const reducer = (
  state = initialState,
  action: ServiceRecordAction
): ServiceRecordsStateInterface => {
  switch (action.type) {
    case ServiceRecordActionTypes.CREATE_SERVICE_RECORD_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ServiceRecordActionTypes.CREATE_SERVICE_RECORD_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ServiceRecordActionTypes.CREATE_SERVICE_RECORD_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ServiceRecordActionTypes.LIST_SERVICE_RECORDS_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ServiceRecordActionTypes.LIST_SERVICE_RECORDS_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<ServiceRecordInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ServiceRecordActionTypes.LIST_SERVICE_RECORDS_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ServiceRecordActionTypes.DELETE_SERVICE_RECORD_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case ServiceRecordActionTypes.DELETE_SERVICE_RECORD_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case ServiceRecordActionTypes.DELETE_SERVICE_RECORD_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case ServiceRecordActionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
