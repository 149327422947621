import RoleEnum from '../enums/roles';
import { CreateAdminDto, UpdateAdminDto } from '../utils/types/admin';
import { Pagination } from '../utils/types/pagination';
import { SubmittedPasswordForm } from '../utils/types/user';
import BaseUsersService from './base-users';

export default class AdminsService extends BaseUsersService {
  role = {
    plural: 'admins',
    singular: 'admin'
  };

  findOne(id: string) {
    return this.getUser(this.role.singular, id);
  }

  list = async (pagination: Pagination) => {
    return this.getBaseUsersPaginated(this.role.plural, pagination);
  };

  approveSignUpRequest = async (id: string) => {
    await this.axios.post(`${this.role.singular}/sign-up/${id}/approve`);

    return true;
  };

  rejectSignUpRequest = async (id: string) => {
    await this.axios.post(`${this.role.singular}/sign-up/${id}/reject`);

    return true;
  };

  delete = async (id: string) => {
    return this.deleteUser(id, RoleEnum.ADMIN);
  };

  create = async (user: CreateAdminDto) => {
    const { data } = await this.axios.post(RoleEnum.ADMIN, user);

    return data;
  };

  edit = async (user: UpdateAdminDto) => {
    const { data } = await this.axios.put(`${RoleEnum.ADMIN}/${user.id}`, user);

    return data;
  };

  updatePassword = async (dto: SubmittedPasswordForm) => {
    const { data } = await this.axios.put(`${RoleEnum.ADMIN}/${dto.userId}/password`, {
      password: dto.password
    });

    return data;
  };
}
