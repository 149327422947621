import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ReactComponent as SearchIcon } from '../../assets/images/search-normal.svg';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

interface InputTextProps {
  label: string;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  value: string;
  validationErrors: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  isSearch?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  multiline?: boolean;
  minRows?: number;
  maxLength?: number;
}

const InputText = (props: InputTextProps) => {
  return (
    <FormControl style={{ width: props.width || '100%' }} error={!!props.validationErrors}>
      <FormLabel component="label" className={'regular'}>
        {props.label} {props.maxLength ? `(${props.value.length}/${props.maxLength})` : ''}
      </FormLabel>

      <TextField
        required
        disabled={props.disabled}
        type={props.type}
        multiline={props.multiline}
        minRows={props.minRows}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        error={!!props.validationErrors}
        helperText={props.validationErrors}
        inputProps={{
          maxLength: props.maxLength
        }}
        InputProps={{
          autoComplete: props.autoComplete ? 'true' : 'false',
          'aria-autocomplete': props.autoComplete ? 'inline' : 'none',
          endAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start">{!!props.isSearch ? <SearchIcon /> : ''}</IconButton>
              {!!props.validationErrors && <ErrorOutlineOutlinedIcon color="error" />}
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};

export default InputText;
