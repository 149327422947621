import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { CustomerContext } from '../../context/customerContext';
import UserForm from '../../components/user-form/UserForm';
import RoleEnum from '../../enums/roles';
import FormTypesEnum from '../../enums/formTypes';
import { createCustomerSchema, updateCustomerSchema } from '../customers/schema';
import './CustomerForm.scss';
import { NotificationContext } from '../../context/notificationContext';
import { UserInterface } from '../../utils/types/user';
import UpdatePasswordForm from '../../components/update-password-form/UpdatePasswordForm';

interface CustomerFormArgs {
  onClickClose: () => void;
  customer: UserInterface;
  type: FormTypesEnum;
}

const CustomerForm: FC<CustomerFormArgs> = ({
  onClickClose,
  customer,
  type
}: CustomerFormArgs): ReactElement => {
  const { createCustomer, editCustomer, updatePassword, state } = useContext(CustomerContext);
  const { showAlert } = useContext(NotificationContext);
  const [schemaForm, setSchemaForm] = useState(createCustomerSchema);

  useEffect(() => {
    if (type === FormTypesEnum.EDIT) setSchemaForm(updateCustomerSchema);
    else if (type === FormTypesEnum.CREATE) setSchemaForm(createCustomerSchema);
  }, [type]);

  const handleSubmit = (form: UserInterface) => {
    switch (type) {
      case FormTypesEnum.CREATE:
        createCustomer(
          {
            status: form.status,
            name: form.name,
            lastName: form.lastName,
            email: form.email,
            phone: form.phone
          },
          showAlert
        );
        break;
      case FormTypesEnum.EDIT:
        editCustomer(
          {
            id: customer.id,
            status: form.status,
            name: form.name,
            lastName: form.lastName,
            email: form.email,
            phone: form.phone
          },
          showAlert
        );
        break;
      default:
        throw new Error('Action not supported');
    }
  };

  const handleUpdatePasswordFormSubmit = (password: string) => {
    updatePassword(
      {
        userId: customer.id,
        password
      },
      showAlert
    );
  };

  return (
    <>
      <UserForm
        onClickClose={onClickClose}
        onSubmitForm={handleSubmit}
        user={customer}
        role={RoleEnum.CUSTOMER}
        schema={schemaForm}
        userState={state}
        type={type}
      />

      {type === FormTypesEnum.EDIT && (
        <UpdatePasswordForm
          variant="narrow"
          onSubmit={handleUpdatePasswordFormSubmit}
          containerBreakpoints={{ xs: 10, sm: 8, md: 7, lg: 7, xl: 7 }}
          buttonBreakpoints={{ xs: 10, sm: 8, md: 7, lg: 7, xl: 7 }}
          buttonText={'Save new password'}
        />
      )}
    </>
  );
};

export default CustomerForm;
