import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { FC, useEffect, useState } from 'react';
import './TanksSlider.scss';

type Marks = {
  value: number;
  label: string;
};

type Props = {
  color: string;
  value: number;
  name: string;
  max: number;
  onChange: (event: Event, value: number | number[], activeThumb: number) => void;
  isDisabled: boolean;
};

const TanksSlider: FC<Props> = ({ color, value, name, max, onChange, isDisabled }) => {
  const [newValue, setNewValue] = useState(0);

  const returnMarks = (): Marks[] => {
    let marks: Marks[] = [];
    const step = max / 20;

    for (let index = 0; index <= 20; index++) {
      marks.push({
        value: index * step,
        label: index === 0 || index === 20 ? `${index * step}` : ''
      });
    }
    return marks;
  };

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <div className="tanks-slider-container">
      <p className="tanks-slider-title">{name}</p>
      <Box sx={boxStyle}>
        <Slider
          disabled={isDisabled}
          onChange={onChange}
          style={{ color: color }}
          value={newValue}
          max={max}
          step={Math.round(max / 20)}
          marks={returnMarks()}
          valueLabelDisplay="on"
        />
      </Box>
    </div>
  );
};

export default TanksSlider;

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  '& > :not(style)': {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  }
};
