import ChecklistService, { CreateChecklistDto, UpdateChecklistDto } from '../../services/checklist';
import { Pagination } from '../../utils/types/pagination';
import ChecklistActionsTypes from './types/checklist';
import { errorPayload } from './utils/errorPayload';

export const createChecklist = (dispatch: any) => {
  return async (dto: CreateChecklistDto) => {
    dispatch({ type: ChecklistActionsTypes.CREATE_CHECKLIST_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      await checklistService.create(dto);

      dispatch({ type: ChecklistActionsTypes.CREATE_CHECKLIST_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistActionsTypes.CREATE_CHECKLIST_ERROR, payload });
    }
  };
};

export const listChecklists = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: ChecklistActionsTypes.LIST_CHECKLISTS_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      const checklists = await checklistService.list(pagination);

      dispatch({ type: ChecklistActionsTypes.LIST_CHECKLISTS_SUCCESS, payload: checklists });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ChecklistActionsTypes.LIST_CHECKLISTS_ERROR, payload });
    }
  };
};

export const listChecklistsHistory = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: ChecklistActionsTypes.LIST_CHECKLISTS_HISTORY_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      const checklists = await checklistService.listHistory(pagination);

      dispatch({
        type: ChecklistActionsTypes.LIST_CHECKLISTS_HISTORY_SUCCESS,
        payload: checklists
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ChecklistActionsTypes.LIST_CHECKLISTS_HISTORY_ERROR, payload });
    }
  };
};

export const viewChecklist = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: ChecklistActionsTypes.VIEW_CHECKLIST_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      const checklist = await checklistService.view(id);

      dispatch({ type: ChecklistActionsTypes.VIEW_CHECKLIST_SUCCESS, payload: checklist });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: ChecklistActionsTypes.VIEW_CHECKLIST_ERROR, payload });
    }
  };
};

export const updateChecklist = (dispatch: any) => {
  return async (id: string, dto: UpdateChecklistDto) => {
    dispatch({ type: ChecklistActionsTypes.UPDATE_CHECKLIST_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      await checklistService.update(id, dto);

      dispatch({ type: ChecklistActionsTypes.UPDATE_CHECKLIST_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistActionsTypes.UPDATE_CHECKLIST_ERROR, payload });
    }
  };
};

export const deleteChecklist = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: ChecklistActionsTypes.DELETE_CHECKLIST_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      await checklistService.delete(id);

      dispatch({ type: ChecklistActionsTypes.DELETE_CHECKLIST_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistActionsTypes.DELETE_CHECKLIST_ERROR, payload });
    }
  };
};

export const completeChecklist = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: ChecklistActionsTypes.COMPLETE_CHECKLIST_IN_PROGRESS });

    try {
      const checklistService = new ChecklistService();
      const result = await checklistService.complete(id);

      dispatch({ type: ChecklistActionsTypes.COMPLETE_CHECKLIST_SUCCESS, payload: result });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: ChecklistActionsTypes.COMPLETE_CHECKLIST_ERROR, payload });
    }
  };
};

export const resetChecklistState = (dispatch: any) => {
  return async () => dispatch({ type: ChecklistActionsTypes.RESET_STATE });
};
