import StorableAsset, { UploadStorableAssetDto } from '../../services/storableAsset';
import StorableAssetActionsTypes from './types/storableAsset';
import { errorPayload } from './utils/errorPayload';

export const viewStorableAsset = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: StorableAssetActionsTypes.VIEW_STORABLE_ASSET_IN_PROGRESS });

    try {
      const storableAssetService = new StorableAsset();
      const storableAsset = await storableAssetService.view(id);

      dispatch({
        type: StorableAssetActionsTypes.VIEW_STORABLE_ASSET_SUCCESS,
        payload: storableAsset
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: StorableAssetActionsTypes.VIEW_STORABLE_ASSET_ERROR, payload });
    }
  };
};

export const uploadStorableAsset = (dispatch: any) => {
  return async (dto: UploadStorableAssetDto) => {
    dispatch({ type: StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_IN_PROGRESS });

    try {
      const storableAssetService = new StorableAsset();
      const storableAsset = await storableAssetService.upload(dto);

      dispatch({
        type: StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_SUCCESS,
        payload: storableAsset
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_ERROR, payload });
    }
  };
};

export const requestStorableAssetDownloadUrl = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_IN_PROGRESS });

    try {
      const storableAssetService = new StorableAsset();
      const result = await storableAssetService.requestDownloadUrl(id);

      dispatch({
        type: StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({
        type: StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_ERROR,
        payload
      });
    }
  };
};

export const deleteStorableAsset = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: StorableAssetActionsTypes.DELETE_STORABLE_ASSET_IN_PROGRESS });

    try {
      const storableAssetService = new StorableAsset();
      const result = await storableAssetService.delete(id);

      dispatch({ type: StorableAssetActionsTypes.DELETE_STORABLE_ASSET_SUCCESS, payload: result });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: StorableAssetActionsTypes.DELETE_STORABLE_ASSET_ERROR, payload });
    }
  };
};

export const resetStorableAssetState = (dispatch: any) => {
  return async () => {
    dispatch({ type: StorableAssetActionsTypes.RESET_STATE });
  };
};
