import Joi from 'joi';

export const groupNameSchema = Joi.object({
  name: Joi.string().required()
}).options({
  abortEarly: false
});

export const groupFieldSchema = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().required().valid('text', 'checkBox')
}).options({
  abortEarly: false
});
