import Grid from '@mui/material/Grid';
import { FC, useContext, useEffect, useState } from 'react';
import { ReactComponent as UpcomingServiceIcon } from '../../../assets/images/clipboard-export-wrapper.svg';
import GridTable from '../../../components/grid/Grid';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { UpcomingServiceContext } from '../../../context/upcomingServiceContext';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import getUpcomingServicesGridColumns from './ListGridColumns';
import { defaultPagination } from '../../../utils/constants/defaults';
import { VesselContext } from '../../../context/vesselContext';
import { Pagination } from '../../../utils/types/pagination';
import { useQueryParams } from '../../../hooks/useQueryParams';

const paginationParams = {
  ...defaultPagination,
  attributes: [],
  join: ['creator', 'operator'],
  order: ['updatedAt:DESC']
};

const UpcomingServicesList: FC = () => {
  const query = useQueryParams();
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { listUpcomingServices, state: upcomingServiceState } = useContext(UpcomingServiceContext);
  const { showAlert } = useContext(NotificationContext);
  const [vesselId] = useState<string>(query.get('vesselId') || '');

  const pagination: Pagination = {
    ...paginationParams,
    filter: `vessel_id:${vesselId}`
  };

  useEffect(() => {
    listUpcomingServices(pagination);

    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (upcomingServiceState.delete.done) {
      listUpcomingServices(pagination);

      showAlert({
        variant: 'success',
        title: 'Upcoming Service',
        description: 'Upcoming Service deleted!'
      });
    } else if (upcomingServiceState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Upcoming Service',
        description: "Upcoming Service couldn't be deleted!"
      });
    }
  }, [upcomingServiceState.delete]);

  const columns = getUpcomingServicesGridColumns();

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || 'Vessel'}
            current={'Upcoming services'}
          />
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {upcomingServiceState?.list?.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'Upcoming services'} />
          ) : (
            <>
              <TitleGrid
                icon={<UpcomingServiceIcon className="icon-title" />}
                title={'Upcoming services'}
              />
              <GridTable
                loading={upcomingServiceState.list.loading}
                rows={upcomingServiceState?.list?.data?.results}
                columns={columns}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
    </>
  );
};

export default UpcomingServicesList;
