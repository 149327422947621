import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import { ReactElement } from 'react';
import { ReactComponent as UploadIcon } from '../../../assets/images/landscape-upload-image.svg';
import { VesselMediaFileInterface } from '../../../utils/types/vesselMediaFile';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import './VesselMediaFileItem.scss';

const Item = styled(Paper)((c) => ({
  display: 'flex',
  //@ts-ignore
  padding: c.theme.spacing(1),
  textAlign: 'center',
  //@ts-ignore
  color: c.theme.palette.text.secondary,
  height: 77,
  background: '#FFFFFF',
  borderRadius: '8px',
  marginBottom: '16px'
}));

interface VesselMediaFileItemProps {
  file: VesselMediaFileInterface;
  onClickDownload: (file: VesselMediaFileInterface) => void;
  onClickDelete: (file: VesselMediaFileInterface) => void;
}

const VesselMediaFileItem = (props: VesselMediaFileItemProps): ReactElement => {
  const handleClickDownload = () => {
    props.onClickDownload(props.file);
  };

  const handleClickDelete = () => {
    props.onClickDelete(props.file);
  };

  return (
    <Item elevation={0} className="vessel-media-item-container">
      <CardHeader
        avatar={<UploadIcon />}
        title={props.file.name}
        onClick={handleClickDownload}
        sx={{
          paddingLeft: 0,
          '.MuiCardHeader-content': {
            color: '#232735'
          }
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <p className="elements-number">.{props.file.storeable_asset.extension}</p>

        <Divider orientation="vertical" variant="middle" flexItem />

        <div style={{ marginLeft: '5px' }}>
          <IconButton onClick={handleClickDelete}>
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
    </Item>
  );
};

export default VesselMediaFileItem;
