import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/images/add-square.svg';
import FieldColor from '../../../components/fieldColor/FieldColor';
import InputText from '../../../components/form/InputText';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import WideBar from '../../../components/wide-bar/WideBar';
import { NotificationContext } from '../../../context/notificationContext';
import {
  tankLevelInitialState,
  tankLevelItemInitialState
} from '../../../context/reducers/tankLevelReducer';
import { TankLevelTemplateContext } from '../../../context/tankLevelTemplateContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import paths from '../../../utils/constants/paths';
import { validateForm } from '../../../utils/form';
import { TankLevelItemInterface } from '../../../utils/types/tankLevel';
import TemplateBox from '../TemplateBox';
import TankLevelItem from './TankLevelItem';
import './TankLevelsTemplate.scss';
import TankLevelsTemplateFieldForm from './TankLevelsTemplateFieldForm';
import { tankLevelTemplateSchema } from './tankLevelsSchema';

export type levelGroup = TankLevelItemInterface & { edit: boolean };

const TankLevelsTemplateForm: FC = (): ReactElement => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const {
    viewTankLevelTemplate,
    createTankLevelTemplate,
    updateTankLevelTemplate,
    resetTankLevelsTemplateState,
    state
  } = useContext(TankLevelTemplateContext);
  const { showAlert } = useContext(NotificationContext);

  const [tankLevelTemplateId] = useState<string>(query.get('id') || '');

  const [openModal, setOpenModal] = useState(false);
  const [templateName, setTemplateName] = useState<string>(tankLevelInitialState.name);
  const [newTankLevels, setNewTankLevels] = useState<TankLevelItemInterface[]>([]);
  const [selectedFieldForm, setSelectedFieldForm] = useState<levelGroup>({
    ...tankLevelItemInitialState,
    edit: false
  });
  const [validationErrors, setValidationErrors] = useState(tankLevelInitialState);

  useEffect(() => {
    setNewTankLevels([]);

    if (tankLevelTemplateId) viewTankLevelTemplate(tankLevelTemplateId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    setTemplateName(state.view.data?.name || '');
    setNewTankLevels(JSON.parse(state.view.data?.template || '[]'));
  }, [state.view.data]);

  useEffect(() => {
    if (state.create.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template created!'
      });

      resetTankLevelsTemplateState();
      navigate(paths.TEMPLATE_TANK_LEVELS.absolutePath);
    }

    if (state.create.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be created!"
      });
    }
  }, [state.create]);

  useEffect(() => {
    if (state.update.done) {
      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template edited!'
      });

      resetTankLevelsTemplateState();
      navigate(paths.TEMPLATE_TANK_LEVELS.absolutePath);
    }

    if (state.update.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be edited!"
      });
    }
  }, [state.update]);

  const getTankLevelInterface = (tankLevel: levelGroup): TankLevelItemInterface => {
    return {
      color: tankLevel.color,
      label: tankLevel.label,
      maxLevel: tankLevel.maxLevel,
      value: tankLevel.value
    };
  };

  const handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const { errorSummary } = validateForm(tankLevelTemplateSchema, {
      name: templateName,
      levels: newTankLevels
    });

    setValidationErrors(errorSummary);

    if (!!errorSummary.levels) setOpenModal(true);
    else if (!Object.values(errorSummary).length) {
      const template = {
        name: templateName,
        template: JSON.stringify(newTankLevels)
      };

      if (tankLevelTemplateId !== '') {
        updateTankLevelTemplate(tankLevelTemplateId, template);
      } else {
        createTankLevelTemplate(template);
      }
    }
  };

  const onSaveForm = (form: levelGroup) => {
    if (form.edit) {
      const tankLevelsTemp = [...newTankLevels];
      const selectedFieldFormIndex = newTankLevels?.findIndex(
        (tl) => tl.color === selectedFieldForm.color
      );
      tankLevelsTemp[selectedFieldFormIndex] = getTankLevelInterface(form);
      setNewTankLevels(tankLevelsTemp);
    } else {
      if (newTankLevels) setNewTankLevels([getTankLevelInterface(form), ...newTankLevels]);
      else setNewTankLevels([getTankLevelInterface(form)]);
    }
    setOpenModal(false);
  };

  const tankLevelsItems = newTankLevels?.map((tank, index) => {
    return (
      <div style={{ marginBottom: '22px' }} key={index}>
        <TankLevelItem
          content={<FieldColor color={tank.color} name={tank.label} />}
          onClickEdit={() => {
            setSelectedFieldForm({ ...tank, edit: true });
            setOpenModal(true);
          }}
        />
      </div>
    );
  });

  return (
    <>
      <TitlePage
        leftContainer={<BreadCrumb previous={'Tank levels template'} current={'Create a new'} />}
        rightContainer={
          <Button
            variant="contained"
            disabled={state.create.loading || state.update.loading}
            onClick={(e) => handleSubmitButton(e)}
          >
            Save
          </Button>
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '71px' }}>
        <TemplateBox minHeight={'calc(100vh - 240px)'}>
          <InputText
            name={'name'}
            label={'Tank levels template name'}
            value={templateName}
            validationErrors={validationErrors.name}
            onChange={(e) => setTemplateName(e.target.value)}
          />
          {tankLevelsItems && tankLevelsItems?.length > 0 && (
            <div style={{ width: '100%', marginTop: '40px' }}>{tankLevelsItems}</div>
          )}

          <div style={{ width: '100%' }}>
            <WideBar
              text={'Create a new tank'}
              icon={<AddIcon />}
              onClickIcon={() => {
                setOpenModal(true);
                setSelectedFieldForm({
                  ...tankLevelItemInitialState,
                  edit: false
                });
              }}
            />
          </div>
        </TemplateBox>
      </Grid>
      {openModal && (
        <TankLevelsTemplateFieldForm
          open={openModal}
          form={selectedFieldForm}
          onOpen={(open) => setOpenModal(open)}
          onSaveForm={(form) => onSaveForm(form)}
        />
      )}
    </>
  );
};

export default TankLevelsTemplateForm;
