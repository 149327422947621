import BlogsEntryService, {
  CreateBlogEntryDto,
  UpdateBlogEntryDto
} from '../../services/blogEntry';
import { Pagination } from '../../utils/types/pagination';
import BlogActionsTypes from './types/blogEntry';
import { errorPayload } from './utils/errorPayload';

export const createBlogEntry = (dispatch: any) => {
  return async (dto: CreateBlogEntryDto) => {
    dispatch({ type: BlogActionsTypes.CREATE_BLOG_ENTRY_IN_PROGRESS });

    try {
      const blogEntryService = new BlogsEntryService();
      await blogEntryService.create(dto);

      dispatch({ type: BlogActionsTypes.UPDATE_BLOG_ENTRY_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: BlogActionsTypes.UPDATE_BLOG_ENTRY_ERROR, payload });
    }
  };
};

export const listBlogEntries = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: BlogActionsTypes.LIST_BLOG_ENTRIES_IN_PROGRESS });

    try {
      const blogEntryService = new BlogsEntryService();
      const blogEntries = await blogEntryService.list(pagination);

      dispatch({ type: BlogActionsTypes.LIST_BLOG_ENTRIES_SUCCESS, payload: blogEntries });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: BlogActionsTypes.LIST_BLOG_ENTRIES_ERROR, payload });
    }
  };
};

export const viewBlogEntry = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: BlogActionsTypes.VIEW_BLOG_ENTRY_IN_PROGRESS });

    try {
      const blogEntryService = new BlogsEntryService();
      const blogEntry = await blogEntryService.view(id);

      dispatch({ type: BlogActionsTypes.VIEW_BLOG_ENTRY_SUCCESS, payload: blogEntry });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: BlogActionsTypes.VIEW_BLOG_ENTRY_ERROR, payload });
    }
  };
};

export const updateBlogEntry = (dispatch: any) => {
  return async (id: string, dto: UpdateBlogEntryDto) => {
    dispatch({ type: BlogActionsTypes.UPDATE_BLOG_ENTRY_IN_PROGRESS });

    try {
      const blogEntryService = new BlogsEntryService();
      await blogEntryService.update(id, dto);

      dispatch({ type: BlogActionsTypes.UPDATE_BLOG_ENTRY_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: BlogActionsTypes.UPDATE_BLOG_ENTRY_ERROR, payload });
    }
  };
};

export const deleteBlogEntry = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: BlogActionsTypes.DELETE_BLOG_ENTRY_IN_PROGRESS });

    try {
      const blogEntryService = new BlogsEntryService();
      await blogEntryService.delete(id);

      dispatch({ type: BlogActionsTypes.DELETE_BLOG_ENTRY_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: BlogActionsTypes.DELETE_BLOG_ENTRY_ERROR, payload });
    }
  };
};

export const resetBlogEntryState = (dispatch: any) => {
  return () => {
    dispatch({ type: BlogActionsTypes.RESET_STATE });
  };
};
