import Grid from '@mui/material/Grid';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import SwipeableDrawerHeader from '../../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import FieldContainerExternal from '../../../components/field-container/FieldContainer';
import { VesselMediaFileContext } from '../../../context/vesselMediaFileContext';
import { StorableAssetContext } from '../../../context/storableAssetContext';
import { ReactComponent as VesselMediaFilesIcon } from '../../../assets/images/service-records-wrapper.svg';
import { createVesselMediaFileInitialState } from '../../../context/reducers/vesselMediaFileReducer';
import InputStorableAssetMultiple from '../../../components/form/InputStorableAssetMultiple';
import { validateForm } from '../../../utils/form';
import { vesselMediaFileSchema } from './VesselMediaFileSchema';
import {
  CreateVesselMediaFileBulkDto,
  CreateVesselMediaFileDto
} from '../../../services/vesselMediaFile';
import Box from '@mui/system/Box';
import { StorableAssetInterface } from '../../../utils/types/storableAsset';

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={10} sm={8} md={7} lg={7} xl={7} />;
};

type VesselMediaFileFormProps = {
  vesselId: string;
  onClose: () => void;
};

const VesselMediaFileForm: FC<VesselMediaFileFormProps> = (props: VesselMediaFileFormProps) => {
  const inputStorableAssetMultipleRef = useRef<any>(null);

  const { createVesselMediaFileBulk, state: vesselMediaFileState } =
    useContext(VesselMediaFileContext);
  const { state: storableAssetState } = useContext(StorableAssetContext);

  const [storeableAssets, setStoreableAssets] = useState<StorableAssetInterface[]>([]);

  useEffect(() => {
    if (vesselMediaFileState.createBulk.done) {
      props.onClose();
    }
  }, [vesselMediaFileState.createBulk.done]);

  const handleOnUploadSuccess = (storableAsset: StorableAssetInterface) => {
    setStoreableAssets((prevState) => [...prevState, storableAsset]);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const form: CreateVesselMediaFileBulkDto = {
      vessel_id: props.vesselId,
      files: storeableAssets.map((storeableAsset) => {
        return {
          name: storeableAsset.filename,
          storeable_asset_id: storeableAsset.id
        };
      })
    };

    createVesselMediaFileBulk(form);
  };

  return (
    <div className="container-vessel-media-file-form">
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}>
        <SwipeableDrawerHeader
          disableButtons={
            vesselMediaFileState.createBulk.loading ||
            storableAssetState.requestDownloadUrl.loading ||
            storableAssetState.upload.loading
          }
          onClickClose={handleClose}
          onClickSave={handleSubmit}
          title={'Add new file'}
          icon={<VesselMediaFilesIcon className="icon-title" />}
        />
        <FieldContainer>
          <Box sx={{ flexGrow: 1 }}>
            <InputStorableAssetMultiple
              ref={inputStorableAssetMultipleRef}
              label={'Select files'}
              name={'storeable_asset_id'}
              value={null}
              assetType={'media'}
              vesselId={props.vesselId}
              visibility={'public'}
              validationErrors={undefined}
              automaticUpload={false}
              maxSelectedFiles={20}
              onUploadSuccess={handleOnUploadSuccess}
            />
          </Box>
        </FieldContainer>
      </Grid>
    </div>
  );
};

export default VesselMediaFileForm;
