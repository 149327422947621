import { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import paths from '../../../utils/constants/paths';
import TitlePage from '../../../components/title-page/TitlePage';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TemplateBox from '../TemplateBox';
import TitleGrid from '../../../components/grid/TitleGrid';
import GridTable from '../../../components/grid/Grid';
import AlertDialog from '../../../components/dialog/AlertDialog';
import { TankLevelTemplateContext } from '../../../context/tankLevelTemplateContext';
import getTankLevelGridColumns from './ListGridColumns';
import { ReactComponent as CheckListIcon } from '../../../assets/images/check-list-wrapper.svg';
import VesselEmptyListService from '../../vessels/VesselEmptyListService';
import { initialState } from '../../../context/reducers/tankLevelTemplateReducer';
import { NotificationContext } from '../../../context/notificationContext';
import { defaultPagination } from '../../../utils/constants/defaults';
import { TankLevelTemplateInterface } from '../../../utils/types/tankLevelTemplate';
import { Pagination } from '../../../utils/types/pagination';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';

const defaultAttributesParams = ['id', 'name', 'createdAt', 'updatedAt'];
const defaultOrderParams = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: ['creator', 'operator'],
  order: defaultOrderParams
};

const TankLevelsTemplateList: FC = (): ReactElement => {
  const navigate = useNavigate();

  const { listTankLevelTemplates, deleteTankLevelsTemplate, state } = useContext(TankLevelTemplateContext);
  const { showAlert } = useContext(NotificationContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TankLevelTemplateInterface>(initialState.view.data!);
  const [paginationParams, setPaginationParams] = useState<Pagination>(defaultPaginationParams);

  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (state.delete.done) {
      listTankLevelTemplatesPaginated(paginationParams);

      showAlert({
        variant: 'success',
        title: 'Template',
        description: 'Template deleted!'
      });
    }

    if (state.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Template',
        description: "Template couldn't be deleted!"
      });
    }
  }, [state.delete]);

  const onEditTankLevel = (tankLevel: any) => {
    navigate({
      pathname: paths.TEMPLATE_TANK_LEVELS_FORM.absolutePath,
      search: `id=${tankLevel.id}`
    });
  };

  const onDeleteTankLevel = (tankLevel: TankLevelTemplateInterface) => {
    setSelectedRow(tankLevel);
    setOpenDeleteDialog(true);
  };

  const handleDeleteTankLevel = () => {
    deleteTankLevelsTemplate(selectedRow.id);
    setOpenDeleteDialog(false);
  };

  const columns = getTankLevelGridColumns(onEditTankLevel, onDeleteTankLevel);

  useEffect(() => {
    listTankLevelTemplatesPaginated(paginationParams);
  }, [paginationParams]);

  const listTankLevelTemplatesPaginated = useCallback((paginationParams: Pagination) => {
    listTankLevelTemplates(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <TitlePage
        leftContainer={<BreadCrumb previous={'Templates'} current={'Tank levels'} />}
        rightContainer={
          <Button
            variant="outlined"
            onClick={() => navigate(paths.TEMPLATE_TANK_LEVELS_FORM.absolutePath)}
          >
            Create new template
          </Button>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {state.list.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'Tank levels templates'} />
          ) : (
            <>
              <TitleGrid
                icon={<CheckListIcon className="icon-title" />}
                title={'Tank levels templates'}
              />

              <MrtDataTable
                isLoading={state?.list.loading}
                isError={Boolean(state?.list.error)}
                columns={columns}
                rows={state?.list.data?.results}
                rowCount={state?.list.data?.pagination.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
      {openDeleteDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to remove <strong>{selectedRow.name}</strong>? There might
                  be vessels using this template
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={handleDeleteTankLevel}
          disableButtons={state.list.loading}
        />
      )}
    </>
  );
};

export default TankLevelsTemplateList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
