import TankLevelTemplate, {
  CreateTankLevelTemplateDto,
  UpdateTankLevelTemplateDto
} from '../../services/tankLevelTemplate';
import { Pagination } from '../../utils/types/pagination';
import TankLevelTemplateActionsTypes from './types/tankLevelTemplate';
import { errorPayload } from './utils/errorPayload';

export const createTankLevelTemplate = (dispatch: any) => {
  return async (dto: CreateTankLevelTemplateDto) => {
    dispatch({ type: TankLevelTemplateActionsTypes.CREATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS });

    try {
      const tankLevelTemplateService = new TankLevelTemplate();
      await tankLevelTemplateService.create(dto);

      dispatch({ type: TankLevelTemplateActionsTypes.CREATE_TANK_LEVEL_TEMPLATE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelTemplateActionsTypes.CREATE_TANK_LEVEL_TEMPLATE_ERROR, payload });
    }
  };
};

export const listTankLevelTemplates = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: TankLevelTemplateActionsTypes.LIST_TANK_LEVEL_TEMPLATES_IN_PROGRESS });

    try {
      const tankLevelTemplateService = new TankLevelTemplate();
      const tankLevelTemplates = await tankLevelTemplateService.findAll(pagination);

      dispatch({
        type: TankLevelTemplateActionsTypes.LIST_TANK_LEVEL_TEMPLATES_SUCCESS,
        payload: tankLevelTemplates
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelTemplateActionsTypes.LIST_TANK_LEVEL_TEMPLATES_ERROR, payload });
    }
  };
};

export const viewTankLevelTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: TankLevelTemplateActionsTypes.VIEW_TANK_LEVEL_TEMPLATE_IN_PROGRESS });

    try {
      const tankLevelTemplateService = new TankLevelTemplate();
      const tankLevelTemplate = await tankLevelTemplateService.view(id);

      dispatch({
        type: TankLevelTemplateActionsTypes.VIEW_TANK_LEVEL_TEMPLATE_SUCCESS,
        payload: tankLevelTemplate
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelTemplateActionsTypes.VIEW_TANK_LEVEL_TEMPLATE_ERROR, payload });
    }
  };
};

export const updateTankLevelTemplate = (dispatch: any) => {
  return async (id: string, dto: UpdateTankLevelTemplateDto) => {
    dispatch({ type: TankLevelTemplateActionsTypes.UPDATE_TANK_LEVEL_TEMPLATE_IN_PROGRESS });

    try {
      const tankLevelTemplateService = new TankLevelTemplate();
      await tankLevelTemplateService.update(id, dto);

      dispatch({ type: TankLevelTemplateActionsTypes.UPDATE_TANK_LEVEL_TEMPLATE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelTemplateActionsTypes.UPDATE_TANK_LEVEL_TEMPLATE_ERROR, payload });
    }
  };
};

export const deleteTankLevelsTemplate = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: TankLevelTemplateActionsTypes.DELETE_TANK_LEVEL_TEMPLATE_IN_PROGRESS });

    try {
      const result = await new TankLevelTemplate().deleteTankLevelTemplate(id);

      dispatch({
        type: TankLevelTemplateActionsTypes.DELETE_TANK_LEVEL_TEMPLATE_SUCCESS,
        payload: result
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: TankLevelTemplateActionsTypes.DELETE_TANK_LEVEL_TEMPLATE_ERROR, payload });
    }
  };
};

export const resetTankLevelsTemplateState = (dispatch: any) => {
  return async () => {
    dispatch({ type: TankLevelTemplateActionsTypes.RESET_STATE });
  };
};
