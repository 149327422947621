import { Pagination } from '../utils/types/pagination';
import {
  CreateVesselInterface,
  ListVesselsInterface,
  VesselInterface
} from '../utils/types/vessel';
import ApiService from './api';

export default class VesselsService extends ApiService {
  createVessel = async (attributes: CreateVesselInterface) => {
    const { data } = await this.axios.post('vessel', attributes);

    return data;
  };

  findAll = async (pagination: Pagination): Promise<ListVesselsInterface[]> => {
    const { data } = await this.axios.get('vessels', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  getVessel = async (
    id: string,
    params?: Record<string, string | number | boolean>
  ): Promise<VesselInterface> => {
    const { data } = await this.axios.get(`vessel/${id}`, {
      params
    });

    return data;
  };

  updateVessel = async (id: string, attributes: VesselInterface) => {
    const { data } = await this.axios.put(`vessel/${id}`, attributes);

    return data;
  };

  deleteVessel = async (id: string) => {
    await this.axios.delete(`vessel/${id}`);
  };
}
