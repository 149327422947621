import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import FileUploader from '../fileUploader/FileUploader';
import { StorableAssetContext } from '../../context/storableAssetContext';
import { StorableAssetType, StorableAssetVisibility } from '../../utils/types/storableAsset';
import { Alert, Box, Card, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { documentTypes, mediaTypes } from '../../utils/lib';

interface InputStorableAssetProps {
  label: string;
  name: string;
  value: string | null;
  vesselId: string;
  assetType: StorableAssetType;
  visibility: StorableAssetVisibility;
  validationErrors?: string;
  automaticUpload?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type AlertType = {
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
};

const InputStorableAsset = forwardRef((props: InputStorableAssetProps, ref) => {
  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<string>('');
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [alert, setAlert] = useState<AlertType | undefined>(undefined);
  const { viewStorableAsset, uploadStorableAsset, resetStorableAssetState, state } =
    useContext(StorableAssetContext);
  const maxFileSizeInBytes = 20 * 1024 * 1024;

  const upload = () => {
    if (!file) return;

    uploadStorableAsset({
      vesselId: props.vesselId,
      assetType: props.assetType,
      visibility: props.visibility,
      file: file
    });
  };

  useEffect(() => {
    return () => {
      resetStorableAssetState();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    upload: () => upload()
  }));

  const getAllowedFileTypes = (assetType: StorableAssetType) => {
    const imageTypes = 'image/*';
    const documentTypesMap = documentTypes.map((type) => `.${type}`);
    const mediaTypesMap = mediaTypes.map((type) => `.${type}`);

    switch (assetType) {
      case 'vessel-picture':
        return imageTypes;
      case 'documents':
        return documentTypesMap.join(',');
      case 'media':
        return [...documentTypesMap, ...mediaTypesMap, imageTypes].join(',');
      case 'service-records':
        return documentTypesMap.join(',');
      case 'blog-image':
        return imageTypes;
      default:
        return '*/*';
    }
  };

  const isImageType = (mimeType: string) => {
    return mimeType.includes('image');
  };

  useEffect(() => {
    if (props.value) viewStorableAsset(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!file) return;
    if (props.automaticUpload) upload();
  }, [file]);

  useEffect(() => {
    if (state.upload.loading) {
      setAlert({
        severity: 'info',
        text: 'Uploading file...'
      });
    }

    if (!state?.upload?.data) return;

    if (!state.upload.error && state.upload.done) {
      setAlert({
        severity: 'success',
        text: 'File uploaded successfully!'
      });
    }

    if (state.upload.error) {
      setAlert({
        severity: 'error',
        text: 'Error uploading file!'
      });
    }

    if (props.onChange) {
      props.onChange({
        target: {
          name: props.name,
          value: state.upload.data.id
        }
      } as any);
    }
  }, [state.upload]);

  useEffect(() => {
    setFileType(state?.view.data?.mime_type || '');
    setFileSize(state?.view.data?.size || 0);
    setFileUrl(state?.view.data?.download_url || '');
  }, [state.view]);

  const handleChange = (files: FileList) => {
    const selectedFile = files[0];
    const url = URL.createObjectURL(selectedFile);

    setFile(selectedFile);
    setFileType(selectedFile.type);
    setFileSize(selectedFile.size);
    setFileUrl(url);
  };

  const handleDelete = () => {
    setFile(undefined);
  };

  return (
    <>
      <FileUploader
        title={props.label}
        fileName={file?.name}
        fileUrl={fileUrl}
        accept={getAllowedFileTypes(props.assetType)}
        maxFileSizeInBytes={maxFileSizeInBytes}
        validationErrors={props.validationErrors}
        onChange={handleChange}
        onDelete={handleDelete}
      />

      {alert && (
        <Grid container sx={{ marginBottom: '11px' }}>
          <Grid item xs={12}>
            <Alert severity={alert.severity} onClose={() => setAlert(undefined)}>
              {alert.text}
            </Alert>
          </Grid>
        </Grid>
      )}

      {fileType && fileSize && (
        <Grid container sx={{ marginBottom: '11px' }}>
          <Grid item xs={12}>
            <Box>
              <>
                <Typography variant="caption" component="p" textAlign="left">
                  <b>File type:</b> {fileType}
                </Typography>
                <Typography variant="caption" component="p" textAlign="left">
                  <b>File size (KB | MB):</b> {(fileSize / 1000).toFixed(2)} |{' '}
                  {(fileSize / 1000000).toFixed(2)}
                </Typography>
              </>
            </Box>
          </Grid>
        </Grid>
      )}

      {isImageType(fileType) && props.visibility === 'public' && (
        <Grid container sx={{ marginBottom: '11px' }}>
          <Card variant="outlined">
            <CardHeader subheader="Preview" />
            <CardMedia component="img" image={fileUrl} alt="" />
          </Card>
        </Grid>
      )}
    </>
  );
});

export default InputStorableAsset;
