import { FC, ReactElement } from 'react';
import './FieldColor.scss';

type Props = {
  color: string;
  name: string;
};

const FieldColor: FC<Props> = ({ color, name }): ReactElement => {
  return (
    <div className="field-color-container">
      <span className="dot" style={{ backgroundColor: color }}></span>
      <span>{name}</span>
    </div>
  );
};

export default FieldColor;
