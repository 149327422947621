import { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import Header from '../headers/header-unauthorized-screens/Header';
import paths from '../../utils/constants/paths';
import './HttpResponse.scss';

type HttpResponseProps = {
  number: string;
  text: string;
};

const HttpResponse = ({ number, text }: HttpResponseProps) => {
  useEffect(() => {
    document.body.style.backgroundColor = '#101934';
  }, []);

  return (
    <>
      <Header />
      <Grid xs={6} sm={6} md={6} lg={6} xl={10} item className="not-found-container">
        <span className="page-number">{number}</span>
        <span className="page-text">{text}</span>
        <Button
          variant="contained"
          type="button"
          href={paths.SIGN_IN.absolutePath}
          className="page-button"
        >
          Back to Homepage
        </Button>
      </Grid>
    </>
  );
};

export default HttpResponse;
