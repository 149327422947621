import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet, useNavigate } from 'react-router-dom';
import Menu from '../../components/menu/Menu';
import TemporalAlert from '../../components/temporal-alert/TemporalAlert';
import { useAuthenticator } from '@aws-amplify/ui-react';
import './PrivateLayout.scss';
import { useEffect } from 'react';
import paths from '../../utils/constants/paths';

export default function PrivateLayout() {
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (route !== 'authenticated') {
      navigate(paths.SIGN_IN.absolutePath);
    }
  }, [route]);

  return (
    <div className="main">
      <Menu />
      <Container className="main-container">
        <Box component="main">
          <Outlet />
        </Box>
      </Container>
      <TemporalAlert />
    </div>
  );
}
