import HumanReadableDate from '../../../../components/HumanReadableDate';
import ShowMoreText from '../../../../components/show-more-text';
import { CrewRequestInterface } from '../../../../utils/types/crewRequest';
import { MRT_ColumnDef } from 'material-react-table';

export const getGridColumns = (): Array<MRT_ColumnDef<CrewRequestInterface>> => [
    {
        accessorKey: 'createdAt',
        header: 'Request day',
        Cell: ({ cell }) => {
            const date = cell.getValue<string>();

            return <HumanReadableDate value={date} />
        }
    },
    {
        accessorKey: 'type',
        header: 'Request type'
    },
    {
        accessorKey: 'creation_notes',
        header: 'Notes',
        Cell: ({ cell }) => {
            const text = cell.getValue<string>();

            return <ShowMoreText text={text} slice={200} />
        }
    },
    {
        accessorKey: 'notes',
        header: 'Rejection reason',
        Cell: ({ cell }) => {
            const text = cell.getValue<string>();

            return <ShowMoreText text={text} slice={200} />
        }
    },
    {
        accessorKey: 'schedule_date',
        header: 'Time to leave the dock',
        Cell: ({ cell }) => {
            const date = cell.getValue<string>();

            return <HumanReadableDate value={date} />
        }
    }
];
