import Grid from '@mui/material/Grid';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as UscgSafetyIcon } from '../../../assets/images/uscg-safety-wrapper.svg';
import GridTable from '../../../components/grid/Grid';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { NotificationContext } from '../../../context/notificationContext';
import { UscgSafetyContext } from '../../../context/uscgSafetyContext';
import { VesselContext } from '../../../context/vesselContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { defaultPagination } from '../../../utils/constants/defaults';
import { Pagination } from '../../../utils/types/pagination';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import getUscgSafetyGridColumns from './ListGridColumns';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';

const defaultAttributesParams: string[] = [];
const defaultOrderParams: string[] = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: ['creator', 'operator'],
  order: defaultOrderParams
};

const UscgSafetyList: FC = () => {
  const query = useQueryParams();

  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { listUscgSafety, state: uscgSafetyState } = useContext(UscgSafetyContext);
  const { showAlert } = useContext(NotificationContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${vesselId}`
  });

  useEffect(() => {
    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (uscgSafetyState.delete.done) {
      listUscgSafetyPaginated(paginationParams);

      showAlert({
        variant: 'success',
        title: 'USCG Safety',
        description: 'USCG Safety deleted!'
      });
    } else if (uscgSafetyState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'USCG Safety',
        description: "USCG Safety couldn't be deleted!"
      });
    }
  }, [uscgSafetyState.delete]);

  const columns = getUscgSafetyGridColumns();

  useEffect(() => {
    listUscgSafetyPaginated(paginationParams);
  }, [paginationParams]);

  const listUscgSafetyPaginated = useCallback((paginationParams: Pagination) => {
    listUscgSafety(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb previous={vesselState.view.data?.name || 'Vessel'} current={'USCG Safety'} />
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {uscgSafetyState?.list?.data?.results?.length === 0 ? (
            <VesselEmptyListService serviceName={'USCG Safety'} />
          ) : (
            <>
              <TitleGrid icon={<UscgSafetyIcon className="icon-title" />} title={'USCG Safety'} />

              <MrtDataTable
                isLoading={uscgSafetyState.list.loading}
                isError={Boolean(uscgSafetyState.list.error)}
                columns={columns}
                rows={uscgSafetyState?.list?.data?.results}
                rowCount={uscgSafetyState?.list?.data?.pagination?.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
    </>
  );
};

export default UscgSafetyList;
