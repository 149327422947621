import { ChangeEvent, FC, ReactElement, useRef, useEffect, useState } from 'react';
import './FileUploader.scss';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';

type FileUploaderProps = {
  title?: string;
  fileName?: string;
  fileUrl?: string;
  accept?: string;
  multiple?: boolean;
  maxSelectedFiles?: number;
  maxFileSizeInBytes?: number;
  validationErrors?: string;
  onChange: (filesSelected: FileList) => void;
  onDelete?: () => void;
};

const FileUploader: FC<FileUploaderProps> = (props: FileUploaderProps): ReactElement => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleClick = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValidationErrors([]);

    if (!event.target.files) return;

    const filesArray = Array.from(event.target.files);

    filesArray.length &&
      filesArray.forEach((file) => {
        if (props.maxFileSizeInBytes && file.size > props.maxFileSizeInBytes) {
          setValidationErrors((prev) => [
            ...prev,
            `File "${file.name}" exceeds the maximum allowed size`
          ]);

          return;
        }
      });

    props.onChange(event.target.files);
  };

  return (
    <>
      <div className={`file-uploader-component`} onClick={handleClick}>
        <div className={`file-uploader-input ${validationErrors.length && 'error'}`}>
          <Typography variant="inherit" component="p" textAlign="left">
            <b>{props.title}</b>
          </Typography>
          {props.multiple && (
            <Typography variant="caption" component="p" textAlign="left">
              <b>Max files allowed:</b> {props.maxSelectedFiles ?? 1} files
            </Typography>
          )}
          <Typography variant="caption" component="p" textAlign="left">
            <b>Allowed types:</b> {props.accept ?? '*/*'}
          </Typography>
          {props.maxFileSizeInBytes && (
            <Typography variant="caption" component="p" textAlign="left">
              <b>Max file size:</b> {(props.maxFileSizeInBytes / 1000000).toFixed(1)} MB
            </Typography>
          )}

          <input
            type="file"
            accept={props.accept ?? '*/*'}
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            multiple={props.multiple}
            max={props.maxFileSizeInBytes}
          />
        </div>

        {validationErrors.map((err) => (
          <FormHelperText error>{err}</FormHelperText>
        ))}
      </div>
    </>
  );
};

export default FileUploader;
