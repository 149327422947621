import UscgSafetyService, { CreateUscgSafetyDto } from '../../services/uscgSafety';
import { Pagination } from '../../utils/types/pagination';
import UscgSafetyActionsTypes from './types/uscgSafety';
import { errorPayload } from './utils/errorPayload';

export const createUscgSafety = (dispatch: any) => {
  return async (dto: CreateUscgSafetyDto) => {
    dispatch({ type: UscgSafetyActionsTypes.CREATE_USCG_SAFETY_IN_PROGRESS });

    try {
      const uscgSafetyService = new UscgSafetyService();
      const result = await uscgSafetyService.create(dto);

      dispatch({ type: UscgSafetyActionsTypes.CREATE_USCG_SAFETY_SUCCESS, payload: result });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: UscgSafetyActionsTypes.CREATE_USCG_SAFETY_ERROR, payload });
    }
  };
};

export const listUscgSafety = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: UscgSafetyActionsTypes.LIST_USCG_SAFETY_IN_PROGRESS });

    try {
      const uscgSafetyService = new UscgSafetyService();
      const uscgSafetyPaginated = await uscgSafetyService.list(pagination);

      dispatch({
        type: UscgSafetyActionsTypes.LIST_USCG_SAFETY_SUCCESS,
        payload: uscgSafetyPaginated
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: UscgSafetyActionsTypes.LIST_USCG_SAFETY_ERROR, payload });
    }
  };
};

export const viewUscgSafety = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: UscgSafetyActionsTypes.VIEW_USCG_SAFETY_IN_PROGRESS });

    try {
      const uscgSafetyService = new UscgSafetyService();
      const uscgSafety = await uscgSafetyService.view(id);

      dispatch({ type: UscgSafetyActionsTypes.VIEW_USCG_SAFETY_SUCCESS, payload: uscgSafety });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: UscgSafetyActionsTypes.VIEW_USCG_SAFETY_ERROR, payload });
    }
  };
};

export const deleteUscgSafety = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: UscgSafetyActionsTypes.DELETE_USCG_SAFETY_IN_PROGRESS });

    try {
      const uscgSafetyService = new UscgSafetyService();
      const result = await uscgSafetyService.delete(id);

      dispatch({ type: UscgSafetyActionsTypes.DELETE_USCG_SAFETY_SUCCESS, payload: result });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: UscgSafetyActionsTypes.DELETE_USCG_SAFETY_ERROR, payload });
    }
  };
};

export const resetUscgSafetyState = (dispatch: any) => {
  return async () => dispatch({ type: UscgSafetyActionsTypes.RESET_STATE });
};
