enum SystemInfoTemplateActionsTypes {
  CREATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS = 'CREATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS',
  CREATE_SYSTEM_INFO_TEMPLATE_SUCCESS = 'CREATE_SYSTEM_INFO_TEMPLATE_SUCCESS',
  CREATE_SYSTEM_INFO_TEMPLATE_ERROR = 'CREATE_SYSTEM_INFO_TEMPLATE_ERROR',

  LIST_SYSTEM_INFO_TEMPLATES_IN_PROGRESS = 'LIST_SYSTEM_INFO_TEMPLATES_IN_PROGRESS',
  LIST_SYSTEM_INFO_TEMPLATES_SUCCESS = 'LIST_SYSTEM_INFO_TEMPLATES_SUCCESS',
  LIST_SYSTEM_INFO_TEMPLATES_ERROR = 'LIST_SYSTEM_INFO_TEMPLATES_ERROR',

  VIEW_SYSTEM_INFO_TEMPLATE_IN_PROGRESS = 'VIEW_SYSTEM_INFO_TEMPLATE_IN_PROGRESS',
  VIEW_SYSTEM_INFO_TEMPLATE_SUCCESS = 'VIEW_SYSTEM_INFO_TEMPLATE_SUCCESS',
  VIEW_SYSTEM_INFO_TEMPLATE_ERROR = 'VIEW_SYSTEM_INFO_TEMPLATE_ERROR',

  UPDATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS = 'UPDATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS',
  UPDATE_SYSTEM_INFO_TEMPLATE_SUCCESS = 'UPDATE_SYSTEM_INFO_TEMPLATE_SUCCESS',
  UPDATE_SYSTEM_INFO_TEMPLATE_ERROR = 'UPDATE_SYSTEM_INFO_TEMPLATE_ERROR',

  DELETE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS = 'DELETE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS',
  DELETE_SYSTEM_INFO_TEMPLATE_SUCCESS = 'DELETE_SYSTEM_INFO_TEMPLATE_SUCCESS',
  DELETE_SYSTEM_INFO_TEMPLATE_ERROR = 'DELETE_SYSTEM_INFO_TEMPLATE_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default SystemInfoTemplateActionsTypes;
