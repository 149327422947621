import { FC, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { BlogEntryContext } from '../../context/blogEntryContext';
import GridHeader from '../../components/grid-header/GridHeader';
import GridTable from '../../components/grid/Grid';
import AlertDialog from '../../components/dialog/AlertDialog';
import { BlogEntryInterface } from '../../utils/types/blogEntry';
import paths from '../../utils/constants/paths';
import listBlogEntriesGridColumns from './ListGridColumns';
import BlogPreviewModal from './BlogPreviewModal';
import { defaultPagination } from '../../utils/constants/defaults';
import { NotificationContext } from '../../context/notificationContext';
import { debounce } from '../../utils/lib';
import { Pagination } from '../../utils/types/pagination';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../components/mrt-data-table';

const defaultAttributesParams = ['id', 'title', 'createdAt', 'updatedAt'];
const defaultOrderParams = ['updatedAt:DESC']
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  order: defaultOrderParams
};

const BlogEntryList: FC = (): ReactElement => {
  const navigate = useNavigate();
  const { listBlogEntries, deleteBlogEntry, state: blogEntryState } = useContext(BlogEntryContext);
  const { showAlert } = useContext(NotificationContext);

  const [textSearch] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [selectedBlogEntry, setSelectedBlogEntry] = useState<BlogEntryInterface | undefined>();
  const [paginationParams, setPaginationParams] = useState<Pagination>(defaultPaginationParams);

  useEffect(() => {
    document.body.style.backgroundColor = '#E7E7E9';

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (blogEntryState.delete.done) {
      listBlogEntries(paginationParams);

      showAlert({
        variant: 'success',
        title: 'Done',
        description: 'Blog Entry deleted!'
      });
    } else if (blogEntryState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "Blog Entry couldn't be deleted!"
      });
    }
  }, [blogEntryState.delete]);

  useEffect(() => {
    listBlogs(paginationParams);
  }, [paginationParams]);

  const listBlogs = useCallback((paginationParams: Pagination) => {
    listBlogEntries(paginationParams);
  }, [paginationParams]);

  const handleSearch = debounce((inputVal) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      search: `title:${inputVal}`
    }));
  }, 500)

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  const onEditBlog = (blog: BlogEntryInterface) => {
    navigate(
      {
        pathname: paths.BLOG_ENTRY_FORM.absolutePath,
        search: `id=${blog.id}`
      },
      { state: { blog } }
    );
  };

  const onViewBlog = (blog: BlogEntryInterface) => {
    setSelectedBlogEntry(blog);
    setOpenPreview(true);
  };

  const onClosePreview = () => {
    setSelectedBlogEntry(undefined);
    setOpenPreview(true);
  };

  const onDeleteBlogEntry = (blog: BlogEntryInterface) => {
    setSelectedBlogEntry(blog);
    setOpenDeleteDialog(true);
  };

  const onDeclineDeleteBlogEntry = () => {
    setSelectedBlogEntry(undefined);
    setOpenDeleteDialog(false);
  };

  const onConfirmDeleteBlogEntry = () => {
    setOpenDeleteDialog(false);

    if (selectedBlogEntry && selectedBlogEntry.id) {
      deleteBlogEntry(selectedBlogEntry.id);
    }
  };

  const columns = listBlogEntriesGridColumns(onViewBlog, onEditBlog, onDeleteBlogEntry);

  return (
    <>
      <GridHeader
        searchValue={textSearch}
        title={'Post list'}
        buttonText={'New post'}
        placeholder={'Search post'}
        onChangeSearch={handleSearch}
        onClick={() => navigate(paths.BLOG_ENTRY_FORM.absolutePath)}
      />

      <MrtDataTable
        isLoading={blogEntryState?.list.loading}
        isError={Boolean(blogEntryState?.list.error)}
        columns={columns}
        rows={blogEntryState?.list?.data?.results}
        rowCount={blogEntryState?.list?.data?.pagination?.total}
        initialPagination={{
          pageIndex: paginationParams.page - 1,
          pageSize: paginationParams.perPage
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
      />

      {openDeleteDialog && selectedBlogEntry && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={{ color: '#1F1F1F', fontSize: '14px' }}>
                  Are you sure you want to remove the blog entry{' '}
                  <strong>{selectedBlogEntry.title}</strong>?
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={onDeclineDeleteBlogEntry}
          onSave={onConfirmDeleteBlogEntry}
          disableButtons={blogEntryState.delete.loading}
        />
      )}

      {openPreview && selectedBlogEntry && (
        <BlogPreviewModal open={openPreview} onClose={onClosePreview} blog={selectedBlogEntry} />
      )}
    </>
  );
};

export default BlogEntryList;
