import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckListIcon } from '../../../assets/images/check-list-wrapper.svg';
import AlertDialog from '../../../components/dialog/AlertDialog';
import LoadTemplateDialog from '../../../components/dialog/LoadTemplateDialog';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { ChecklistContext } from '../../../context/checklistContext';
import { ChecklistTemplateContext } from '../../../context/checklistTemplateContext';
import { NotificationContext } from '../../../context/notificationContext';
import { checklistInitialState } from '../../../context/reducers/checklistReducer';
import paths from '../../../utils/constants/paths';
import { ChecklistInterface } from '../../../utils/types/checklist';
import { ChecklistTemplateInterface } from '../../../utils/types/checklistTemplate';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import getChecklistGridColumns from './ListGridColumns';
import { Pagination } from '../../../utils/types/pagination';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { defaultPagination } from '../../../utils/constants/defaults';
import { VesselContext } from '../../../context/vesselContext';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import MrtDataTable from '../../../components/mrt-data-table';

const initialValues = {
  ...checklistInitialState
};

const defaultAttributesParams: string[] = [];
const defaultOrderParams = ['updatedAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  join: ['creator'],
  order: defaultOrderParams
};

const CheckList: FC = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const { showAlert } = useContext(NotificationContext);
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const { deleteChecklist, listChecklists, state: checklistState } = useContext(ChecklistContext);
  const { listChecklistTemplates, state: templatesState } = useContext(ChecklistTemplateContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLoadTemplateDialog, setOpenLoadTemplateDialog] = useState(false);
  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [selectedChecklist, setSelectedChecklist] = useState<ChecklistInterface>(initialValues);
  const [paginationParams, setPaginationParams] = useState<Pagination>({
    ...defaultPaginationParams,
    filter: `vessel_id:${vesselId},completed_by:null`
  });

  useEffect(() => {
    listChecklistsPaginated(paginationParams);
    listChecklistTemplates({
      ...defaultPagination,
      perPage: 100
    });

    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    if (checklistState.delete.done) {
      listChecklistsPaginated(paginationParams);

      showAlert({
        variant: 'success',
        title: 'Checklist',
        description: 'Checklist deleted!'
      });
    } else if (checklistState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Checklist',
        description: "Checklist couldn't be deleted!"
      });
    }
  }, [checklistState.delete]);

  const onDeleteChecklist = (checklist: ChecklistInterface) => {
    setSelectedChecklist(checklist);
    setOpenDeleteDialog(true);
  };

  const onEditChecklist = (checklist: ChecklistInterface) => {
    navigate({
      pathname: paths.VESSEL_CHECKLIST_EDIT.absolutePath,
      search: `id=${checklist.id}&vesselId=${vesselId}`
    });
  };

  const handleClose = (_event: any, reason: 'escapeKeyDown' | 'backdropClick') => {
    if (reason === 'backdropClick') setOpenLoadTemplateDialog(false);
  };

  const handleSelectTemplate = (template: ChecklistTemplateInterface) => {
    setOpenLoadTemplateDialog(false);
    navigate({
      pathname: paths.VESSEL_CHECKLIST_EDIT.absolutePath,
      search: `checklistTemplateId=${template.id}&vesselId=${vesselId}`
    });
  };

  const handleDeleteChecklist = () => {
    setOpenDeleteDialog(false);
    deleteChecklist(selectedChecklist.id);
  };

  const columns = getChecklistGridColumns(onEditChecklist, onDeleteChecklist);

  const listChecklistsPaginated = useCallback((paginationParams: Pagination) => {
    listChecklists(paginationParams);
  }, [paginationParams]);

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      }
    });
  }

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length ?
      sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`) :
      defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      }
    });
  }

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb previous={vesselState.view.data?.name || ''} current={'Checklist'} />
        }
        rightContainer={
          <Button variant="outlined" onClick={() => setOpenLoadTemplateDialog(true)}>
            Load from template
          </Button>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox height={'calc(100vh - 240px)'}>
          {checklistState.list.data?.results.length === 0 ? (
            <VesselEmptyListService serviceName={'Checklist'} />
          ) : (
            <>
              <TitleGrid icon={<CheckListIcon className="icon-title" />} title={'Checklist'} />
              <MrtDataTable
                isLoading={templatesState?.list.loading}
                isError={Boolean(templatesState?.list.error)}
                columns={columns}
                rows={checklistState.list.data?.results}
                rowCount={checklistState.list.data?.pagination.total}
                initialPagination={{
                  pageIndex: paginationParams.page - 1,
                  pageSize: paginationParams.perPage
                }}
                onPaginationChange={handlePaginationChange}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </TemplateBox>
      </Grid>
      {openDeleteDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to remove <strong>{selectedChecklist.name}</strong>?
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={handleDeleteChecklist}
        />
      )}

      {openLoadTemplateDialog && (
        <LoadTemplateDialog
          list={templatesState?.list?.data?.results || []}
          open={openLoadTemplateDialog}
          createTemplatePath={paths.TEMPLATE_CHECKLIST_FORM.absolutePath}
          onClose={(e, reason) => handleClose(e, reason)}
          onLoad={handleSelectTemplate}
        />
      )}
    </>
  );
};

export default CheckList;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
