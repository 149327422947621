import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { checklistGroupInitialState } from '../../../context/reducers/checklistReducer';
import { validateForm, validateGroupFieldsForm } from '../../../utils/form';
import { ChecklistGroup, ChecklistItemInterface } from '../../../utils/types/checklist';
import TemplateBox from '../TemplateBox';
import { GroupType } from './CheckListTemplateForm';
import ChecklistForm from './ChecklistForm';
import './ChecklistTemplate.scss';
import { groupFieldSchema, groupNameSchema } from './checklistSchema';

type Props = {
  saveGroup: (groupInfo: GroupType) => void;
  groupInfo?: { group: ChecklistGroup; edit: boolean };
};

const CheckListTemplateCreateGroup: FC<Props> = ({ saveGroup, groupInfo }): ReactElement => {
  const ref = useRef<{ getGroupInfo(): void }>(null);
  const [newGroupName, setNewGroupName] = useState<string>(checklistGroupInitialState.name);
  const [newGroupFields, setNewGroupFields] = useState<ChecklistItemInterface[]>(
    checklistGroupInitialState.fields
  );
  const [errorsMessages, setErrorsMessages] = useState({
    name: '',
    fields: [
      {
        name: '',
        type: '',
        value: '',
        status: ''
      }
    ]
  });

  useEffect(() => {
    if (groupInfo) {
      setNewGroupName(groupInfo.group.name);
      setNewGroupFields(groupInfo.group.fields);
    }
  }, [groupInfo]);

  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  const handleGroupInfo = (name: string, fields: ChecklistItemInterface[]) => {
    const { errorSummary: nameError } = validateForm(groupNameSchema, { name });
    const { errorSummary: fieldsErrors } = validateGroupFieldsForm(groupFieldSchema, fields);
    setNewGroupName(name);
    setNewGroupFields(fields);
    setErrorsMessages({ name: nameError, fields: fieldsErrors });
    saveGroup({ name: name, fields: fields, edit: groupInfo?.edit });
  };

  const onSaveGroup = () => {
    if (ref.current) ref.current.getGroupInfo();
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={'Checklist template'}
            middle="Create a new"
            current={'Create group'}
          />
        }
        rightContainer={
          <Button variant="contained" onClick={() => onSaveGroup()}>
            Save group
          </Button>
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '65px' }}>
        <TemplateBox minHeight={'calc(100vh - 240px)'}>
          <Grid container display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <div style={{ width: '100%' }}>
              <ChecklistForm
                ref={ref}
                errorsMessages={errorsMessages}
                groupInfo={(name, fields) => handleGroupInfo(name, fields)}
                group={{ name: newGroupName, fields: newGroupFields }}
              />
            </div>
          </Grid>
        </TemplateBox>
      </Grid>
    </>
  );
};

export default CheckListTemplateCreateGroup;
