enum AdminsActionsTypes {
  FETCH_ADMINS_IN_PROGRESS = 'FETCH_ADMINS_IN_PROGRESS',
  FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS',
  FETCH_ADMINS_ERROR = 'FETCH_ADMINS_ERROR',

  LIST_ADMIN_SIGN_UP_REQUESTS_IN_PROGRESS = 'LIST_ADMIN_SIGN_UP_REQUESTS_IN_PROGRESS',
  LIST_ADMIN_SIGN_UP_REQUESTS_SUCCESS = 'LIST_ADMIN_SIGN_UP_REQUESTS_SUCCESS',
  LIST_ADMIN_SIGN_UP_REQUESTS_ERROR = 'LIST_ADMIN_SIGN_UP_REQUESTS_ERROR',

  FETCH_ADMIN_IN_PROGRESS = 'FETCH_ADMIN_IN_PROGRESS',
  FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS',
  FETCH_ADMIN_ERROR = 'FETCH_ADMIN_ERROR',

  DELETE_ADMIN_IN_PROGRESS = 'DELETE_ADMIN_IN_PROGRESS',
  DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR',

  CREATE_ADMIN_IN_PROGRESS = 'CREATE_ADMIN_IN_PROGRESS',
  CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR',

  APPROVE_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS = 'APPROVE_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS',
  APPROVE_ADMIN_SIGN_UP_REQUEST_SUCCESS = 'APPROVE_ADMIN_SIGN_UP_REQUEST_SUCCESS',
  APPROVE_ADMIN_SIGN_UP_REQUEST_ERROR = 'APPROVE_ADMIN_SIGN_UP_REQUEST_ERROR',

  REJECT_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS = 'REJECT_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS',
  REJECT_ADMIN_SIGN_UP_REQUEST_SUCCESS = 'REJECT_ADMIN_SIGN_UP_REQUEST_SUCCESS',
  REJECT_ADMIN_SIGN_UP_REQUEST_ERROR = 'REJECT_ADMIN_SIGN_UP_REQUEST_ERROR',

  EDIT_ADMIN_IN_PROGRESS = 'EDIT_ADMIN_IN_PROGRESS',
  EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS',
  EDIT_ADMIN_ERROR = 'EDIT_ADMIN_ERROR',

  EDIT_ADMIN_PASSWORD_IN_PROGRESS = 'EDIT_ADMIN_PASSWORD_IN_PROGRESS',
  EDIT_ADMIN_PASSWORD_SUCCESS = 'EDIT_ADMIN_PASSWORD_SUCCESS',
  EDIT_ADMIN_PASSWORD_ERROR = 'EDIT_ADMIN_PASSWORD_ERROR',

  RESET_STATE_ERROR = 'RESET_STATE_ERROR'
}

export default AdminsActionsTypes;
