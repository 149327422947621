import {
  SystemInfoTemplateAction,
  SystemInfoTemplateInterface,
  SystemInfoTemplateItem,
  SystemInfoTemplateStateInterface
} from '../../utils/types/systemInfoTemplate';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import SystemInfoTemplateActionsTypes from '../actions/types/systemInfoTemplate';
import { getInitialPaginationState } from '../../utils/constants/states';

const initialPaginationState = getInitialPaginationState<SystemInfoTemplateInterface>()

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const initialState: SystemInfoTemplateStateInterface = {
  create: actionInitialState,
  list: { ...actionInitialState, data: initialPaginationState },
  view: {
    ...actionInitialState,
    data: {
      id: '',
      name: '',
      template: '',
      created_by: ''
    }
  },
  update: actionInitialState,
  delete: actionInitialState,
  complete: actionInitialState
};

export const systemInfoTemplateItemInitialState: SystemInfoTemplateItem = {
  label: '',
  field: {
    type: 'text'
  }
};

export const systemInfoTemplateInitialState: SystemInfoTemplateInterface = {
  id: '',
  name: '',
  created_by: '',
  template: ''
};

export const reducer = (
  state = initialState,
  action: SystemInfoTemplateAction
): SystemInfoTemplateStateInterface => {
  switch (action.type) {
    case SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        create: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_SUCCESS:
      return {
        ...state,
        create: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoTemplateActionsTypes.CREATE_SYSTEM_INFO_TEMPLATE_ERROR:
      return {
        ...state,
        create: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_IN_PROGRESS:
      return {
        ...state,
        list: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: {
          data: action.payload as PaginatedListInterface<SystemInfoTemplateInterface>,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoTemplateActionsTypes.LIST_SYSTEM_INFO_TEMPLATES_ERROR:
      return {
        ...state,
        list: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoTemplateActionsTypes.VIEW_SYSTEM_INFO_TEMPLATE_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        update: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoTemplateActionsTypes.UPDATE_SYSTEM_INFO_TEMPLATE_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case SystemInfoTemplateActionsTypes.DELETE_SYSTEM_INFO_TEMPLATE_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case SystemInfoTemplateActionsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
