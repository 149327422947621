enum DocumentsActionsTypes {
  CREATE_DOCUMENT_IN_PROGRESS = 'CREATE_DOCUMENT_IN_PROGRESS',
  CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR',

  LIST_DOCUMENTS_IN_PROGRESS = 'LIST_DOCUMENTS_IN_PROGRESS',
  LIST_DOCUMENTS_SUCCESS = 'LIST_DOCUMENTS_SUCCESS',
  LIST_DOCUMENTS_ERROR = 'LIST_DOCUMENTS_ERROR',

  DELETE_DOCUMENT_IN_PROGRESS = 'DELETE_DOCUMENT_IN_PROGRESS',
  DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default DocumentsActionsTypes;
