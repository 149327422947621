import { Reducer } from 'react';
import CustomerActionsTypes from '../actions/types/customer';
import { CustomerActionInterface } from '../../utils/types/customer';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { errorInitialState, getInitialPaginationState } from '../../utils/constants/states';
import { PasswordFormInterface, UserInterface, UsersStateInterface } from '../../utils/types/user';

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

const initialPaginationState = getInitialPaginationState<UserInterface>()

export const userInitialState: UserInterface = {
  id: '',
  status: 'DISABLED',
  name: '',
  lastName: '',
  email: '',
  phone: '',
  createdAt: '',
};

export const updatePasswordInitialState: PasswordFormInterface = {
  userId: '',
  password: '',
  confirmPassword: ''
};

export const initialState: UsersStateInterface = {
  listSignUpRequests: initialPaginationState,
  approveSignUpRequest: actionInitialState,
  rejectSignUpRequest: actionInitialState,
  paginated: initialPaginationState,
  selected: userInitialState,
  isLoading: false,
  error: errorInitialState
};

export const reducer: Reducer<UsersStateInterface, CustomerActionInterface> = (
  state = initialState,
  action: CustomerActionInterface
): UsersStateInterface => {
  switch (action.type) {
    case CustomerActionsTypes.CREATE_CUSTOMER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case CustomerActionsTypes.CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case CustomerActionsTypes.FETCH_CUSTOMERS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paginated: action.payload as PaginatedListInterface<UserInterface>,
        error: errorInitialState
      };
    case CustomerActionsTypes.FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        isLoading: false,
        paginated: initialPaginationState,
        error: action.payload as ErrorInterface
      };

    case CustomerActionsTypes.LIST_CUSTOMER_SIGN_UP_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.LIST_CUSTOMER_SIGN_UP_REQUESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listSignUpRequests: action.payload as PaginatedListInterface<UserInterface>,
        error: errorInitialState
      };
    case CustomerActionsTypes.LIST_CUSTOMER_SIGN_UP_REQUESTS_ERROR:
      return {
        ...state,
        isLoading: false,
        listSignUpRequests: initialPaginationState,
        error: action.payload as ErrorInterface
      };

    case CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS:
      return {
        ...state,
        approveSignUpRequest: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        approveSignUpRequest: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CustomerActionsTypes.APPROVE_CUSTOMER_SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        approveSignUpRequest: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_IN_PROGRESS:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case CustomerActionsTypes.REJECT_CUSTOMER_SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case CustomerActionsTypes.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload as unknown as UserInterface,
        error: errorInitialState
      };
    case CustomerActionsTypes.FETCH_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        selected: initialState.selected,
        error: action.payload as ErrorInterface
      };
    case CustomerActionsTypes.DELETE_CUSTOMER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case CustomerActionsTypes.DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case CustomerActionsTypes.EDIT_CUSTOMER_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case CustomerActionsTypes.RESET_STATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    default:
      return state;
  }
};
