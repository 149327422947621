import { PaginatedResultInterface } from '../interfaces/PaginatedResultInterface';
import { ChecklistInterface } from '../utils/types/checklist';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type CreateChecklistTemplateDto = {
  name: string;
  template: string;
};

export type UpdateChecklistTemplateDto = {
  name: string;
  template: string;
};

export default class ChecklistTemplateService extends ApiService {
  findAll = async (
    pagination: Pagination
  ): Promise<PaginatedResultInterface<ChecklistInterface[]>> => {
    const { data } = await this.axios.get('checklist-templates', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  getChecklistTemplate = async (id: string) => {
    const { data } = await this.axios.get(`checklist-template/${id}`);
    return data;
  };

  create = async (dto: CreateChecklistTemplateDto) => {
    const { data } = await this.axios.post('checklist-template', dto);
    return data;
  };

  update = async (id: string, dto: UpdateChecklistTemplateDto) => {
    const { data } = await this.axios.put(`checklist-template/${id}`, dto);

    return data;
  };

  deleteChecklist = async (id: string) => {
    const { data } = await this.axios.delete(`checklist-template/${id}`);
    return data;
  };
}
