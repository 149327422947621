enum ServiceRecordsActionsTypes {
  CREATE_SERVICE_RECORD_IN_PROGRESS = 'CREATE_SERVICE_RECORD_IN_PROGRESS',
  CREATE_SERVICE_RECORD_SUCCESS = 'CREATE_SERVICE_RECORD_SUCCESS',
  CREATE_SERVICE_RECORD_ERROR = 'CREATE_SERVICE_RECORD_ERROR',

  LIST_SERVICE_RECORDS_IN_PROGRESS = 'LIST_SERVICE_RECORDS_IN_PROGRESS',
  LIST_SERVICE_RECORDS_SUCCESS = 'LIST_SERVICE_RECORDS_SUCCESS',
  LIST_SERVICE_RECORDS_ERROR = 'LIST_SERVICE_RECORDS_ERROR',

  DELETE_SERVICE_RECORD_IN_PROGRESS = 'DELETE_SERVICE_RECORD_IN_PROGRESS',
  DELETE_SERVICE_RECORD_SUCCESS = 'DELETE_SERVICE_RECORD_SUCCESS',
  DELETE_SERVICE_RECORD_ERROR = 'DELETE_SERVICE_RECORD_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default ServiceRecordsActionsTypes;
