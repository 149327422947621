import { ReactComponent as SquareIcon } from '../../../../assets/images/task-square.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-circle.svg';
import HumanReadableDate from '../../../../components/HumanReadableDate';
import { CrewRequestInterface } from '../../../../utils/types/crewRequest';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import ShowMoreText from '../../../../components/show-more-text';

export const getGridColumns = (
    onAccept: (row: CrewRequestInterface) => void,
    onReject: (row: CrewRequestInterface) => void
): Array<MRT_ColumnDef<CrewRequestInterface>> => [
        {
            accessorKey: 'createdAt',
            header: 'Request day',
            Cell: ({ cell }) => {
                const date = cell.getValue<string>();

                return <HumanReadableDate value={date} />
            }
        },
        {
            accessorKey: 'type',
            header: 'Request type'
        },
        {
            accessorKey: 'creation_notes',
            header: 'Notes',
            Cell: ({ cell }) => {
                const text = cell.getValue<string>();

                return <ShowMoreText text={text} slice={200} />
            }
        },
        {
            accessorKey: 'schedule_date',
            header: 'Time to leave the dock',
            Cell: ({ cell }) => {
                const date = cell.getValue<string>();

                return <HumanReadableDate value={date} />
            }
        },
        {
            header: 'Actions',
            enableSorting: false,
            Cell: ({ cell }) => (
                <>
                    <IconButton
                        color="secondary"
                        aria-label="Edit"
                        onClick={() => onAccept(cell.row.original)}
                    >
                        <SquareIcon />
                    </IconButton>

                    <IconButton
                        color="secondary"
                        aria-label="Delete"
                        onClick={() => onReject(cell.row.original)}
                    >
                        <CloseIcon />
                    </IconButton>
                </>
            )
        },
    ];
