import { useState } from "react"

type ShowMoreTextProps = {
    slice?: number
    text?: string
}

const defaultSlice = 150

export default function ShowMoreText(props: ShowMoreTextProps) {
    const [isShowMore, setShowMore] = useState(false)

    return (
        <>
            {(
                props.text &&
                props.text.length >= (props.slice || defaultSlice)
            ) ?
                (<p>
                    {isShowMore ? props.text : props.text.slice(0, props.slice || defaultSlice)}
                    {" "}
                    <a onClick={() => setShowMore(!isShowMore)} style={{ color: '#273f82', cursor: 'pointer' }}>
                        {isShowMore ? "view less" : "... view more"}
                    </a>
                </p>) :
                (<p>{props.text}</p>)
            }
        </>
    )
}