import { TankLevelColorsEnum } from '../../../../utils/types/tankLevel';

const colorCodes = {
  [TankLevelColorsEnum.GREEN]: '#04AA5E',
  [TankLevelColorsEnum.BLUE]: '#407BFF',
  [TankLevelColorsEnum.ORANGE]: '#FF8B00',
  [TankLevelColorsEnum.RED]: '#F00',
  [TankLevelColorsEnum.PINK]: '#FF8F73',
  [TankLevelColorsEnum.BLACK]: '#151515'
};

export const defaultColorCode = colorCodes[TankLevelColorsEnum.GREEN];

export const tankLevelColors = [
  { color: colorCodes[TankLevelColorsEnum.GREEN], name: TankLevelColorsEnum.GREEN },
  { color: colorCodes[TankLevelColorsEnum.BLUE], name: TankLevelColorsEnum.BLUE },
  { color: colorCodes[TankLevelColorsEnum.ORANGE], name: TankLevelColorsEnum.ORANGE },
  { color: colorCodes[TankLevelColorsEnum.RED], name: TankLevelColorsEnum.RED },
  { color: colorCodes[TankLevelColorsEnum.PINK], name: TankLevelColorsEnum.PINK },
  { color: colorCodes[TankLevelColorsEnum.BLACK], name: TankLevelColorsEnum.BLACK }
];
