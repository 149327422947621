import { UserBasicInfo } from '../utils/types/user';
import ApiService from './api';

export type UpdateMyProfileInfo = UserBasicInfo;
export type UpdateMyProfilePassword = { password: string };

export default class MyProfileService extends ApiService {
  viewInfo = async () => {
    const { data } = await this.axios.get(`admin/profile/info`);

    return data;
  };

  updateInfo = async (dto: UpdateMyProfileInfo) => {
    const { data } = await this.axios.put(`admin/profile/info`, dto);

    return data;
  };

  updatePassword = async (dto: UpdateMyProfilePassword) => {
    const { data } = await this.axios.put(`admin/profile/password`, dto);

    return data;
  };
}
