import { BlogEntryInterface } from '../utils/types/blogEntry';
import { Pagination } from '../utils/types/pagination';
import ApiService from './api';

export type CreateBlogEntryDto = Omit<
  BlogEntryInterface,
  'id' | 'updatedAt' | 'createdAt' | 'deletedAt'
>;
export type UpdateBlogEntryDto = Omit<
  BlogEntryInterface,
  'id' | 'updatedAt' | 'createdAt' | 'deletedAt'
>;

export default class BlogsEntryService extends ApiService {
  create = async (dto: CreateBlogEntryDto) => {
    const { data } = await this.axios.post('blog-entry', dto);

    return data;
  };

  list = async (pagination: Pagination) => {
    const { data } = await this.axios.get('blog-entries', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`blog-entry/${id}`);

    return data;
  };

  update = async (id: string, dto: UpdateBlogEntryDto) => {
    const { data } = await this.axios.put(`blog-entry/${id}`, dto);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`blog-entry/${id}`);

    return data;
  };
}
