import moment from 'moment';
import { ServiceRecordInterface } from '../../../utils/types/serviceRecord';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-document.svg';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';

const getServiceRecordGridColumns = (
  onDownload: (serviceRecord: ServiceRecordInterface) => void,
  onDelete: (serviceRecord: ServiceRecordInterface) => void
): Array<MRT_ColumnDef<ServiceRecordInterface>> => [
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const formattedDate = moment(date).format('DD/MM/YYYY')

        return (
          <span style={{ color: '#52659B', fontWeight: 500 }}>
            {formattedDate}
          </span>
        )
      }
    },
    {
      accessorKey: 'title',
      header: 'Title'
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Download"
            onClick={() => onDownload(cell.row.original)}
          >
            <DownloadIcon />
          </IconButton>

          <IconButton
            color="secondary"
            aria-label="Delete"
            onClick={() => onDelete(cell.row.original)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getServiceRecordGridColumns;
