import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { FC } from 'react';

type Props = {
  children: any;
  height?: number | string;
  minHeight?: number | string;
  boxStyle?: {};
  paperStyle?: {};
};

const TemplateBox: FC<Props> = ({ children, height, minHeight, boxStyle, paperStyle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 0,
          overflow: 'auto',
          width: '100%',
          minHeight: minHeight ?? 0,
          height: height ?? 'auto', // 'auto', // 135, //250
          background: '#E7E7E9 !important',
          borderRadius: '8px !important',
          padding: '24px',
          ...boxStyle
        }
      }}
    >
      <Paper elevation={0} sx={{ ...paperStyle }}>
        {children}
      </Paper>
    </Box>
  );
};

export default TemplateBox;
