import { FC, ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Card, CardContent, CardHeader, CardMedia, DialogActions, IconButton } from '@mui/material';
import { BlogEntryInterface } from '../../utils/types/blogEntry';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type Props = {
  open: boolean;
  onClose: () => void;
  blog: BlogEntryInterface;
};

const BlogPreviewModal: FC<Props> = ({ open, onClose, blog }): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          height: 'auto'
        }
      }}
    >
      <DialogActions>
        <IconButton>
          <HighlightOffIcon onClick={onClose} />
        </IconButton>
      </DialogActions>
      <DialogContent sx={{ padding: '0 34px' }}>
        <Card elevation={0}>
          <CardMedia
            component="img"
            height="200"
            image={blog.storeable_asset?.download_url}
            alt="Paella dish"
            sx={{
              borderRadius: '4px'
            }}
          />
          <CardHeader
            title={blog.title}
            subheader={blog.excerpt}
            sx={{
              padding: '16px 0',
              '.MuiCardHeader-subheader': {
                marginTop: '10px'
              }
            }}
          />
          <CardContent sx={{ padding: '16px 0' }}>
            <div dangerouslySetInnerHTML={{ __html: blog.text || '' }} />
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default BlogPreviewModal;
