// import { Checkbox } from '@mui/material'
import { GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid';
import moment from 'moment';
// import NumberSlot from '../../components/number-slot/NumberSlot'
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-circle.svg';
import { UserInterface } from '../../utils/types/user';
import { MRT_ColumnDef } from 'material-react-table';
import IconButton from '@mui/material/IconButton';

const getCustomerGridColumns = (
  onEditUser: (user: UserInterface) => void,
  onDeleteUser: (user: UserInterface) => void
): Array<MRT_ColumnDef<UserInterface>> => [
  {
    accessorKey: 'createdAt',
    header: 'Date',
    Cell: ({ cell }) => {
      const date = new Date(cell.getValue<string>());
      const humanReadableDate = moment(date).fromNow();

      return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
    }
  },
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'email',
    header: 'Email'
  },
  {
    accessorKey: 'phone',
    header: 'Phone'
  },
  {
    header: 'Actions',
    enableSorting: false,
    Cell: ({ cell }) => (
      <>
        <IconButton
          color="secondary"
          aria-label="Edit"
          onClick={() => onEditUser(cell.row.original)}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          color="secondary"
          aria-label="Delete"
          onClick={() => onDeleteUser(cell.row.original)}
        >
          <CloseIcon />
        </IconButton>
      </>
    )
  }
];

export default getCustomerGridColumns;
