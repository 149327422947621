import UpcomingService, {
  CreateUpcomingServiceDto,
  UpdateUpcomingServiceDto
} from '../../services/upcomingService';
import { Pagination } from '../../utils/types/pagination';
import UpcomingServicesActionsTypes from './types/upcomingService';
import { errorPayload } from './utils/errorPayload';

export const createUpcomingService = (dispatch: any) => {
  return async (dto: CreateUpcomingServiceDto) => {
    dispatch({ type: UpcomingServicesActionsTypes.CREATE_UPCOMING_SERVICE_IN_PROGRESS });

    try {
      const upcomingServiceService = new UpcomingService();
      await upcomingServiceService.create(dto);

      dispatch({ type: UpcomingServicesActionsTypes.CREATE_UPCOMING_SERVICE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({
        type: UpcomingServicesActionsTypes.CREATE_UPCOMING_SERVICE_ERROR,
        payload
      });
    }
  };
};

export const listUpcomingServices = (dispatch: any) => {
  return async (pagination: Pagination) => {
    dispatch({ type: UpcomingServicesActionsTypes.LIST_UPCOMING_SERVICES_IN_PROGRESS });

    try {
      const upcomingServiceService = new UpcomingService();
      const upcomingServices = await upcomingServiceService.list(pagination);

      dispatch({
        type: UpcomingServicesActionsTypes.LIST_UPCOMING_SERVICES_SUCCESS,
        payload: upcomingServices
      });
    } catch (error: any) {
      const payload = {
        code: error.code,
        text: error.message
      };

      dispatch({ type: UpcomingServicesActionsTypes.LIST_UPCOMING_SERVICES_ERROR, payload });
    }
  };
};

export const viewUpcomingService = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: UpcomingServicesActionsTypes.VIEW_UPCOMING_SERVICE_IN_PROGRESS });

    try {
      const upcomingServiceService = new UpcomingService();
      const upcomingService = await upcomingServiceService.view(id);

      dispatch({
        type: UpcomingServicesActionsTypes.VIEW_UPCOMING_SERVICE_SUCCESS,
        payload: upcomingService
      });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: UpcomingServicesActionsTypes.VIEW_UPCOMING_SERVICE_ERROR, payload });
    }
  };
};

export const updateUpcomingService = (dispatch: any) => {
  return async (id: string, dto: UpdateUpcomingServiceDto) => {
    dispatch({ type: UpcomingServicesActionsTypes.UPDATE_UPCOMING_SERVICE_IN_PROGRESS });

    try {
      const upcomingServiceService = new UpcomingService();
      await upcomingServiceService.update(id, dto);

      dispatch({ type: UpcomingServicesActionsTypes.UPDATE_UPCOMING_SERVICE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: UpcomingServicesActionsTypes.UPDATE_UPCOMING_SERVICE_ERROR, payload });
    }
  };
};

export const deleteUpcomingService = (dispatch: any) => {
  return async (id: string) => {
    dispatch({ type: UpcomingServicesActionsTypes.DELETE_UPCOMING_SERVICE_IN_PROGRESS });

    try {
      const upcomingService = new UpcomingService();
      await upcomingService.delete(id);

      dispatch({ type: UpcomingServicesActionsTypes.DELETE_UPCOMING_SERVICE_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: UpcomingServicesActionsTypes.DELETE_UPCOMING_SERVICE_ERROR, payload });
    }
  };
};

export const resetUpcomingServiceState = (dispatch: any) => {
  return async () => dispatch({ type: UpcomingServicesActionsTypes.RESET_STATE });
};
