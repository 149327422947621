import Grid from '@mui/material/Grid';
import { FC, useContext, useEffect, useState } from 'react';
import { ReactComponent as CrewRequestIcon } from '../../../assets/images/crew.svg';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import { VesselContext } from '../../../context/vesselContext';
import { useQueryParams } from '../../../hooks/useQueryParams';
import TemplateBox from '../../form-templates/TemplateBox';
import CreatedCrewRequestsTable from './created/table';
import AcceptedCrewRequestsTable from './accepted/table';
import RejectedCrewRequestsTable from './rejected/table';
import CancelledCrewRequestsTable from './cancelled/table';

const CrewRequestsList: FC = () => {
  const query = useQueryParams();

  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const [vesselId] = useState<string>(query.get('vesselId') || '');

  useEffect(() => {
    if (!vesselState.view.data?.id) viewVessel(vesselId);

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb
            previous={vesselState.view.data?.name || 'Vessel'}
            current={'Crew member / Make ready request'}
          />
        }
        rightContainer={<></>}
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '49px' }}
      >
        <TemplateBox minHeight={'calc(100vh - 240px)'}>
          <TitleGrid
            icon={<CrewRequestIcon className="icon-title" />}
            title={'Crew member / Make ready request'}
          />
          {vesselId && (
            <>
              <CreatedCrewRequestsTable vesselId={vesselId} />
              <AcceptedCrewRequestsTable vesselId={vesselId} />
              <RejectedCrewRequestsTable vesselId={vesselId} />
              <CancelledCrewRequestsTable vesselId={vesselId} />
            </>
          )}
        </TemplateBox>
      </Grid>
    </>
  );
};

export default CrewRequestsList;
