enum UpcomingServicesActionsTypes {
  CREATE_UPCOMING_SERVICE_IN_PROGRESS = 'CREATE_UPCOMING_SERVICE_IN_PROGRESS',
  CREATE_UPCOMING_SERVICE_SUCCESS = 'CREATE_UPCOMING_SERVICE_SUCCESS',
  CREATE_UPCOMING_SERVICE_ERROR = 'CREATE_UPCOMING_SERVICE_ERROR',

  LIST_UPCOMING_SERVICES_IN_PROGRESS = 'LIST_UPCOMING_SERVICES_IN_PROGRESS',
  LIST_UPCOMING_SERVICES_SUCCESS = 'LIST_UPCOMING_SERVICES_SUCCESS',
  LIST_UPCOMING_SERVICES_ERROR = 'LIST_UPCOMING_SERVICES_ERROR',

  VIEW_UPCOMING_SERVICE_IN_PROGRESS = 'VIEW_UPCOMING_SERVICE_IN_PROGRESS',
  VIEW_UPCOMING_SERVICE_SUCCESS = 'VIEW_UPCOMING_SERVICE_SUCCESS',
  VIEW_UPCOMING_SERVICE_ERROR = 'VIEW_UPCOMING_SERVICE_ERROR',

  UPDATE_UPCOMING_SERVICE_IN_PROGRESS = 'UPDATE_UPCOMING_SERVICE_IN_PROGRESS',
  UPDATE_UPCOMING_SERVICE_SUCCESS = 'UPDATE_UPCOMING_SERVICE_SUCCESS',
  UPDATE_UPCOMING_SERVICE_ERROR = 'UPDATE_UPCOMING_SERVICE_ERROR',

  DELETE_UPCOMING_SERVICE_IN_PROGRESS = 'DELETE_UPCOMING_SERVICE_IN_PROGRESS',
  DELETE_UPCOMING_SERVICE_SUCCESS = 'DELETE_UPCOMING_SERVICE_SUCCESS',
  DELETE_UPCOMING_SERVICE_ERROR = 'DELETE_UPCOMING_SERVICE_ERROR',

  RESET_STATE = 'RESET_STATE'
}

export default UpcomingServicesActionsTypes;
