import { GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download-document.svg';
import { ChecklistInterface } from '../../../utils/types/checklist';
import { MRT_ColumnDef } from 'material-react-table';
import { UserInterface } from '../../../utils/types/user';
import IconButton from '@mui/material/IconButton';

const getChecklistHistoryGridColumns = (
  onClickDownload: (row: ChecklistInterface) => void
): Array<MRT_ColumnDef<ChecklistInterface>> => [
    {
      accessorKey: 'name',
      header: 'Name'
    },
    {
      accessorKey: 'creator',
      header: 'Created by',
      Cell: ({ cell }) => {
        const creator = cell.getValue<UserInterface>();

        return (
          <span>
            {creator ? creator.name + ' ' + creator.lastName : ''}
          </span>
        );
      }
    },
    {
      accessorKey: 'operator',
      header: 'Completed By',
      Cell: ({ cell }) => {
        const operator = cell.getValue<UserInterface>();

        return (
          <span>
            {operator ? operator.name + ' ' + operator.lastName : ''}
          </span>
        );
      }
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated at',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue<string>());
        const humanReadableDate = moment(date).fromNow();

        return <span style={{ color: '#52659B', fontWeight: 500 }}>{humanReadableDate}</span>;
      }
    },
    {
      header: 'Actions',
      enableSorting: false,
      Cell: ({ cell }) => (
        <>
          <IconButton
            color="secondary"
            aria-label="Download"
            onClick={() => onClickDownload(cell.row.original)}
          >
            <DownloadIcon />
          </IconButton>
        </>
      )
    }
  ];

export default getChecklistHistoryGridColumns;
