function UnderMaintenance() {
  return (
    <div>
      <br />
      <h2>Under maintenance</h2>
    </div>
  );
}

export default UnderMaintenance;
