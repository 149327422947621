import { ChecklistInterface } from '../utils/types/checklist';
import ApiService from './api';
import { Pagination } from '../utils/types/pagination';
import { PaginatedResultInterface } from '../interfaces/PaginatedResultInterface';

export type CreateChecklistDto = Omit<
  ChecklistInterface,
  'id' | 'created_by' | 'completed_by' | 'createdAt' | 'updatedAt'
>;
export type UpdateChecklistDto = Omit<
  ChecklistInterface,
  'id' | 'template_id' | 'created_by' | 'completed_by' | 'createdAt' | 'updatedAt'
>;

export default class ChecklistService extends ApiService {
  create = async (dto: CreateChecklistDto) => {
    const { data } = await this.axios.post(`checklist`, dto);

    return data;
  };

  list = async (
    pagination: Pagination
  ): Promise<PaginatedResultInterface<ChecklistInterface[]>> => {
    const { data } = await this.axios.get('checklists', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  listHistory = async (
    pagination: Pagination
  ): Promise<PaginatedResultInterface<ChecklistInterface[]>> => {
    const { data } = await this.axios.get('checklists/history', {
      params: {
        attributes: pagination.attributes.join(','),
        page: pagination.page,
        perPage: pagination.perPage,
        join: pagination.join?.join(','),
        searchTerms: pagination.search,
        filterTerms: pagination.filter,
        order: pagination.order?.join(',')
      }
    });

    return data;
  };

  view = async (id: string) => {
    const { data } = await this.axios.get(`checklist/${id}`);

    return data;
  };

  update = async (id: string, dto: UpdateChecklistDto) => {
    const { data } = await this.axios.put(`checklist/${id}`, dto);

    return data;
  };

  delete = async (id: string) => {
    const { data } = await this.axios.delete(`checklist/${id}`);

    return data;
  };

  complete = async (id: string) => {
    const { data } = await this.axios.put(`checklist/complete/${id}`);

    return data;
  };
}
