import Joi from 'joi';
import { userFormSchema } from '../../utils/schemas';

export const createCustomerSchema = Joi.object({
  ...userFormSchema,
  date: Joi.any(),
  vessels: Joi.any()
}).options({
  abortEarly: false
});

export const updateCustomerSchema = Joi.object({
  ...userFormSchema,
  date: Joi.any(),
  vessels: Joi.any()
}).options({
  abortEarly: false
});
