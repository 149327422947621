import { getCurrentUser } from 'aws-amplify/auth';
import MyProfileService, {
  UpdateMyProfileInfo,
  UpdateMyProfilePassword
} from '../../services/myProfile';
import MyProfileActionsTypes from './types/myProfile';
import { errorPayload } from './utils/errorPayload';

export const viewMyProfileInfo = (dispatch: any) => {
  return async () => {
    dispatch({ type: MyProfileActionsTypes.VIEW_INFO_IN_PROGRESS });

    try {
      const myProfilesService = new MyProfileService();
      const myProfileInfo = await myProfilesService.viewInfo();

      dispatch({ type: MyProfileActionsTypes.VIEW_INFO_SUCCESS, payload: myProfileInfo });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: MyProfileActionsTypes.VIEW_INFO_ERROR, payload });
    }
  };
};

export const updateMyProfileInfo = (dispatch: any) => {
  return async (dto: UpdateMyProfileInfo) => {
    dispatch({ type: MyProfileActionsTypes.UPDATE_INFO_IN_PROGRESS });

    try {
      const myProfilesService = new MyProfileService();
      await myProfilesService.updateInfo(dto);
      await getCurrentUser();

      dispatch({ type: MyProfileActionsTypes.UPDATE_INFO_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: MyProfileActionsTypes.UPDATE_INFO_ERROR, payload });
    }
  };
};

export const updateMyProfilePassword = (dispatch: any) => {
  return async (dto: UpdateMyProfilePassword) => {
    dispatch({ type: MyProfileActionsTypes.UPDATE_PASSWORD_IN_PROGRESS });

    try {
      const myProfilesService = new MyProfileService();
      await myProfilesService.updatePassword(dto);

      dispatch({ type: MyProfileActionsTypes.UPDATE_PASSWORD_SUCCESS });
    } catch (error: any) {
      const payload = errorPayload(error);

      dispatch({ type: MyProfileActionsTypes.UPDATE_PASSWORD_ERROR, payload });
    }
  };
};
