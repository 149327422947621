import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { FC, ReactElement } from 'react';
import './Loader.scss';

const Loader: FC = (): ReactElement => {
  return (
    <>
      <Box
        id="loader"
        sx={{
          width: '100vw',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: 10000,
          visibility: 'hidden'
        }}
      >
        <LinearProgress />
      </Box>
    </>
  );
};

export default Loader;
