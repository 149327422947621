import Joi from 'joi';
import { passwordField, confirmPasswordField } from '../../utils/schemas';

export const schema = Joi.object({
  userId: Joi.string().optional().allow(''),
  password: passwordField.required(),
  confirmPassword: confirmPasswordField.required()
}).options({
  abortEarly: false
});
