import { FC, ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

interface LoadTemplateDialogArgs {
  list: any[];
  open: boolean;
  createTemplatePath: string;
  onLoad: (template: any) => void;
  onClose: (e: any, reason: any) => void;
}

const LoadTemplateDialog: FC<LoadTemplateDialogArgs> = ({
  list,
  open,
  createTemplatePath,
  onLoad,
  onClose
}): ReactElement => {
  const navigate = useNavigate();

  const templates = list.map((template, index) => {
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '15px',
          alignItems: 'center'
        }}
      >
        <span style={{ fontSize: '12px' }}>{template.name}</span>
        <Button variant="outlined" type="button" onClick={() => onLoad(template)}>
          Load Template
        </Button>
      </div>
    );
  });

  return (
    <Dialog open={open} onClose={onClose} sx={dialogStyle}>
      <DialogTitle
        style={{ display: 'flex', color: '#1F3268', marginTop: '20px', fontSize: '18px' }}
      >
        Load from template
      </DialogTitle>
      <DialogContent style={{ padding: '0 0 27px' }}>
        <div style={{ margin: '0 25px 0 25px', color: '#000' }}>{templates}</div>
      </DialogContent>
      <div style={dividerStyle} />
      <DialogActions style={{ marginRight: '28px', padding: '0 0 35px 0' }}>
        <Button
          variant="contained"
          type="button"
          autoFocus
          onClick={() => navigate(createTemplatePath)}
        >
          Create a new template
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadTemplateDialog;

const dialogStyle = {
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px',
    width: '480px',
    minHeight: '279px'
  }
};

const dividerStyle = {
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '1px',
  backgroundColor: '#eee',
  marginBottom: '27px'
};
