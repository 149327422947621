import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './InputWysiwyg.scss';
import FormHelperText from '@mui/material/FormHelperText';

interface InputWysiwygProps {
  label: string;
  name?: string;
  type?: string;
  value: string;
  validationErrors: any;
  onChange: (name: string, value: string) => void;
  width?: string;
  isSearch?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
}

const toolBarOptions = {
  options: ['inline', 'colorPicker', 'list', 'link', 'remove', 'history'],
  list: {
    options: ['unordered', 'ordered']
  },
  inline: {
    options: ['bold', 'italic', 'underline']
  }
};

const InputWysiwyg = (props: InputWysiwygProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (draftToHtml(convertToRaw(editorState.getCurrentContent())) === props.value) return;

    setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromBlockArray(htmlToDraft(props.value || '').contentBlocks),
        'backspace-character'
      )
    );
  }, [props.value]);

  const onEditorStateChange = (editorState: EditorState) => {
    const newContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setEditorState(editorState);
    props.onChange(props.name || '', newContent);
  };

  return (
    <FormControl style={{ width: props.width || '100%' }} error={!!props.validationErrors}>
      <FormLabel component="label" className={'regular'}>
        {props.label}
      </FormLabel>

      <Editor
        toolbar={toolBarOptions}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName={!!props.validationErrors ? 'error' : ''}
        editorClassName="editor"
        onEditorStateChange={onEditorStateChange}
      />

      {props.validationErrors && (
        <FormHelperText error={!!props.validationErrors}>{props.validationErrors}</FormHelperText>
      )}
    </FormControl>
  );
};

export default InputWysiwyg;
