import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

export default class ApiService {
  protected axios: AxiosInstance;

  constructor() {
    this.axios = this.configureAxios();
  }

  private configureAxios(): AxiosInstance {
    if (!process.env.REACT_APP_US_API_BASE_URL)
      throw new Error('The environment variable REACT_APP_US_API_BASE_URL must be set');

    const axiosInstance = Axios.create({
      baseURL: process.env.REACT_APP_US_API_BASE_URL
    });

    axiosInstance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig<any>) => {
        let authSession = await fetchAuthSession();
        const idToken = authSession.tokens?.idToken?.toString();

        if (idToken) config.headers.Authorization = idToken;

        return config;
      },
      (error) => Promise.reject(error)
    );

    return axiosInstance;
  }
}
