import Grid from '@mui/material/Grid';
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  useContext,
  useRef,
  useState
} from 'react';
import SwipeableDrawerHeader from '../../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import FieldContainerExternal from '../../../components/field-container/FieldContainer';
import InputText from '../../../components/form/InputText';
import { ServiceRecordContext } from '../../../context/serviceRecordContext';
import { ReactComponent as ServiceRecordsIcon } from '../../../assets/images/service-records-wrapper.svg';
import { createServiceRecordInitialState } from '../../../context/reducers/serviceRecordReducer';
import InputStorableAsset from '../../../components/form/InputStorableAsset';
import { validateForm } from '../../../utils/form';
import { serviceRecordSchema } from './ServiceRecordSchema';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { CreateServiceRecordDto } from '../../../utils/types/serviceRecord';

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={10} sm={8} md={7} lg={7} xl={7} />;
};

type ServiceRecordFormProps = {
  vesselId: string;
  onClose: () => void;
};

const ServiceRecordForm: FC<ServiceRecordFormProps> = (props: ServiceRecordFormProps) => {
  const inputStorableAssetRef = useRef<any>(null);

  const query = useQueryParams();

  const { createServiceRecord, state: serviceRecordState } = useContext(ServiceRecordContext);

  const [vesselId] = useState<string>(query.get('vesselId') || '');
  const [form, setForm] = useState<CreateServiceRecordDto>({
    ...createServiceRecordInitialState,
    vessel_id: vesselId
  });
  const [validationErrors, setValidationErrors] = useState<CreateServiceRecordDto>(
    createServiceRecordInitialState
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setForm(createServiceRecordInitialState);
    setValidationErrors(createServiceRecordInitialState);
    props.onClose();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const { errorSummary } = validateForm(serviceRecordSchema, form);

    setValidationErrors(errorSummary);

    if (!Object.values(errorSummary).length) {
      createServiceRecord(form);
    }
  };

  return (
    <div className="container-service-record-form">
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}
      >
        <SwipeableDrawerHeader
          disableButtons={serviceRecordState.create.loading}
          onClickClose={handleClose}
          onClickSave={handleSubmit}
          title={'Add new service record'}
          icon={<ServiceRecordsIcon className="icon-title" />}
        />
        <FieldContainer>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputText
              name={'title'}
              label={'Title'}
              value={form.title}
              validationErrors={validationErrors.title}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5}>
            <InputStorableAsset
              ref={inputStorableAssetRef}
              label={'Select file'}
              name={'storeable_asset_id'}
              value={form.storeable_asset_id}
              assetType={'service-records'}
              vesselId={props.vesselId}
              visibility={'private'}
              validationErrors={validationErrors.storeable_asset_id}
              automaticUpload={true}
              onChange={handleInputChange}
            />
          </Grid>
        </FieldContainer>
      </Grid>
    </div>
  );
};

export default ServiceRecordForm;
