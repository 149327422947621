import { FC } from 'react';
import './StatusSlot.scss';

type statusSlotProps = {
  value: string;
  backgroundValue: string;
  backgroundSlot: string;
  fontSize?: 'small' | 'medium' | 'large' | 'inherit';
};

const StatusSlot: FC<statusSlotProps> = ({
  value,
  backgroundValue,
  backgroundSlot,
  fontSize = 'medium'
}) => {
  const fontSizesMap = {
    small: '10px',
    medium: '12px',
    large: '14px',
    inherit: 'inherit'
  };

  return (
    <div className="status-slot" style={{ backgroundColor: backgroundSlot, display: 'inline-block' }}>
      <span
        className="value-slot"
        style={{ color: backgroundValue, fontSize: fontSizesMap[fontSize] }}
      >
        {value}
      </span>
    </div>
  );
};

export default StatusSlot;
