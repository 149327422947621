import StorableAssetActionsTypes from '../actions/types/storableAsset';
import { ActionInterface, ErrorInterface } from '../../utils/types/common';
import {
  DownloadUrl,
  StorableAssetAction,
  StorableAssetInterface,
  StorableAssetStateInterface
} from '../../utils/types/storableAsset';

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

export const storableAssetInitialState: StorableAssetInterface = {
  id: '',
  asset_type: 'media',
  filename: '',
  extension: '',
  mime_type: '',
  size: 0,
  size_unit_type: '',
  key: '',
  download_url: '',
  createdAt: '',
  updatedAt: ''
};

export const initialState: StorableAssetStateInterface = {
  upload: actionInitialState,
  view: {
    ...actionInitialState,
    data: storableAssetInitialState
  },
  requestDownloadUrl: actionInitialState,
  delete: actionInitialState
};

export const reducer = (
  state = initialState,
  action: StorableAssetAction
): StorableAssetStateInterface => {
  switch (action.type) {
    case StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_IN_PROGRESS:
      return {
        ...state,
        upload: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_SUCCESS:
      return {
        ...state,
        upload: {
          loading: false,
          error: undefined,
          done: true,
          data: action.payload as StorableAssetInterface
        }
      };
    case StorableAssetActionsTypes.UPLOAD_STORABLE_ASSET_ERROR:
      return {
        ...state,
        upload: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case StorableAssetActionsTypes.VIEW_STORABLE_ASSET_IN_PROGRESS:
      return {
        ...state,
        view: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case StorableAssetActionsTypes.VIEW_STORABLE_ASSET_SUCCESS:
      return {
        ...state,
        view: {
          data: action.payload as any,
          loading: false,
          error: undefined,
          done: true
        }
      };
    case StorableAssetActionsTypes.VIEW_STORABLE_ASSET_ERROR:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_IN_PROGRESS:
      return {
        ...state,
        requestDownloadUrl: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_SUCCESS:
      return {
        ...state,
        requestDownloadUrl: {
          loading: false,
          error: undefined,
          done: true,
          data: action.payload as DownloadUrl
        }
      };
    case StorableAssetActionsTypes.REQUEST_STORABLE_ASSET_DOWNLOAD_URL_ERROR:
      return {
        ...state,
        requestDownloadUrl: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case StorableAssetActionsTypes.DELETE_STORABLE_ASSET_IN_PROGRESS:
      return {
        ...state,
        delete: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case StorableAssetActionsTypes.DELETE_STORABLE_ASSET_SUCCESS:
      return {
        ...state,
        delete: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case StorableAssetActionsTypes.DELETE_STORABLE_ASSET_ERROR:
      return {
        ...state,
        delete: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case StorableAssetActionsTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
