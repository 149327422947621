/* eslint-disable react/jsx-no-undef */
import { FC, useState } from 'react';
import { FormLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface InputPasswordArgs {
  label: string;
  name: string;
  value: string;
  validationErrors: any;
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputPassword: FC<InputPasswordArgs> = (args: InputPasswordArgs) => {
  const { label, name, value, setValue, validationErrors } = args;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <FormLabel
        component="label"
        className={'regular'}
        sx={{ display: 'block', marginBottom: '10px' }}
      >
        {label}
      </FormLabel>

      <TextField
        required
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={setValue}
        error={!!validationErrors}
        helperText={validationErrors}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon style={{ fill: '#A9B2CD' }} />
                ) : (
                  <VisibilityOffOutlinedIcon style={{ fill: '#A9B2CD' }} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </>
  );
};

export default InputPassword;
