import Grid from '@mui/material/Grid';
import { FC, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as VesselMediaIcon } from '../../../assets/images/clipboard-export-wrapper.svg';
import TitleGrid from '../../../components/grid/TitleGrid';
import BreadCrumb from '../../../components/title-page/BreadCrumb';
import TitlePage from '../../../components/title-page/TitlePage';
import TemplateBox from '../../form-templates/TemplateBox';
import VesselEmptyListService from '../VesselEmptyListService';
import { defaultPagination } from '../../../utils/constants/defaults';
import { Pagination } from '../../../utils/types/pagination';
import { VesselContext } from '../../../context/vesselContext';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { VesselMediaFileContext } from '../../../context/vesselMediaFileContext';
import { VesselMediaFileInterface } from '../../../utils/types/vesselMediaFile';
import VesselMediaFileItem from './VesselMediaFileItem';
import AlertDialog from '../../../components/dialog/AlertDialog';
import { StorableAssetContext } from '../../../context/storableAssetContext';
import { vesselMediaFileInitialState } from '../../../context/reducers/vesselMediaFileReducer';
import { NotificationContext } from '../../../context/notificationContext';
import VesselMediaFileForm from './VesselMediaFileForm';

const paginationParams = {
  ...defaultPagination,
  attributes: [],
  join: [],
  order: []
};

const VesselMediaFileList: FC = () => {
  const [searchParams] = useSearchParams();

  const {
    listVesselMediaFiles,
    deleteVesselMediaFile,
    resetVesselMediaFileState,
    state: vesselMediaFileState
  } = useContext(VesselMediaFileContext);
  const { viewVessel, state: vesselState } = useContext(VesselContext);
  const {
    deleteStorableAsset,
    requestStorableAssetDownloadUrl,
    state: storableAssetState
  } = useContext(StorableAssetContext);
  const { showAlert } = useContext(NotificationContext);

  const [vesselId, setVesselId] = useState<string | null>(searchParams.get('vesselId'));
  const [isFileFormOpen, setIsFileFormOpen] = useState<boolean>(false);
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState<boolean>(false);
  const [openDownloadFileDialog, setOpenDownloadFileDialog] = useState<boolean>(false);
  const [selectedVesselMediaFile, setSelectedVesselMediaFile] = useState<VesselMediaFileInterface>(
    vesselMediaFileInitialState
  );

  const pagination: Pagination = {
    ...paginationParams,
    filter: '',
    order: ['name:ASC']
  };

  useEffect(() => {
    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    setVesselId(searchParams.get('vesselId'));
  }, [searchParams]);

  useEffect(() => {
    if (!vesselState.view.data?.id && vesselId) viewVessel(vesselId);

    vesselId &&
      listVesselMediaFiles({
        ...pagination,
        filter: `vessel_id:${vesselId}`
      });
  }, [vesselId]);

  useEffect(() => {
    if (storableAssetState.requestDownloadUrl.data) {
      setOpenDownloadFileDialog(false);
      window.open(storableAssetState.requestDownloadUrl.data.downloadUrl, '_blank');
    }
  }, [storableAssetState.requestDownloadUrl]);

  useEffect(() => {
    if (vesselMediaFileState.createBulk.done) {
      showAlert({
        variant: 'success',
        title: 'Completed',
        description: 'File created!'
      });

      resetVesselMediaFileState();
      setIsFileFormOpen(false);
      vesselId &&
        listVesselMediaFiles({
          ...pagination,
          filter: `vessel_id:${vesselId}`
        });
    } else if (vesselMediaFileState.createBulk.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "File couldn't be created!"
      });

      setIsFileFormOpen(false);
    } else {
      console.log('Result', JSON.stringify(vesselMediaFileState.createBulk, null, 2));
    }
  }, [vesselMediaFileState.createBulk]);

  useEffect(() => {
    if (vesselMediaFileState.delete.done) {
      showAlert({
        variant: 'success',
        title: 'Completed',
        description: 'File deleted!'
      });

      deleteStorableAsset(selectedVesselMediaFile.storeable_asset_id);
      vesselId &&
        listVesselMediaFiles({
          ...pagination,
          filter: `vessel_id:${vesselId}`
        });
    } else if (vesselMediaFileState.delete.error) {
      showAlert({
        variant: 'error',
        title: 'Error',
        description: "File couldn't be deleted!"
      });
    }

    setOpenDeleteFileDialog(false);
  }, [vesselMediaFileState.delete]);

  const handleConfirmDeleteFile = () => {
    if (!selectedVesselMediaFile.storeable_asset_id) return;

    deleteVesselMediaFile(selectedVesselMediaFile.id);
  };

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsFileFormOpen(isOpen);
  };

  const onDownloadVesselMediaFile = (file: VesselMediaFileInterface) => {
    setSelectedVesselMediaFile(file);
    setOpenDownloadFileDialog(true);
  };

  const onConfirmDownloadFile = () => {
    if (!selectedVesselMediaFile.storeable_asset_id) return;

    requestStorableAssetDownloadUrl(selectedVesselMediaFile.storeable_asset_id);
  };

  const onDeleteFile = (file: VesselMediaFileInterface) => {
    setSelectedVesselMediaFile(file);
    setOpenDeleteFileDialog(true);
  };

  return (
    <>
      <TitlePage
        leftContainer={
          <BreadCrumb previous={vesselState.view.data?.name || 'Vessel'} middle="Vessel media" />
        }
        rightContainer={
          <>
            <Button
              variant="outlined"
              sx={{ marginRight: '20px' }}
              onClick={() => setIsFileFormOpen(true)}>
              New File
            </Button>
          </>
        }
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginTop: '71px', marginBottom: '20px' }}>
        <TemplateBox height={`calc(100vh - 240px)`}>
          <div style={{ marginBottom: '24px' }}>
            <TitleGrid icon={<VesselMediaIcon className="icon-title" />} title={'Vessel media'} />
          </div>

          {vesselId && (
            <>
              {vesselMediaFileState.list.data?.results?.length === 0 && (
                <VesselEmptyListService serviceName={'Files'} height="20%" />
              )}

              {vesselMediaFileState.list.data?.results?.map((file, i) => {
                return (
                  <VesselMediaFileItem
                    key={i}
                    file={file}
                    onClickDownload={onDownloadVesselMediaFile}
                    onClickDelete={onDeleteFile}
                  />
                );
              })}
            </>
          )}
        </TemplateBox>
      </Grid>

      {openDeleteFileDialog && (
        <AlertDialog
          disableButtons={vesselMediaFileState.delete.loading || storableAssetState.delete.loading}
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}>
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to delete this file? This action can not be undone
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteFileDialog}
          onClose={() => setOpenDeleteFileDialog(false)}
          onSave={handleConfirmDeleteFile}
        />
      )}

      {openDownloadFileDialog && (
        <AlertDialog
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}>
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>Are you sure you want to download this document?</span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={
            storableAssetState.requestDownloadUrl.loading ? 'Downloading...' : 'Yes, download'
          }
          disableButtons={storableAssetState.requestDownloadUrl.loading}
          open={openDownloadFileDialog}
          onClose={() => setOpenDownloadFileDialog(false)}
          onSave={onConfirmDownloadFile}
        />
      )}

      <SwipeableDrawer
        anchor={'bottom'}
        open={isFileFormOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={swipeableDrawerStyle}>
        {vesselId && isFileFormOpen && (
          <VesselMediaFileForm vesselId={vesselId} onClose={() => setIsFileFormOpen(false)} />
        )}
      </SwipeableDrawer>
    </>
  );
};

export default VesselMediaFileList;

const swipeableDrawerStyle = {
  maxHeight: 'calc(100% - 64px)',
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px 8px 0 0'
  }
};

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
