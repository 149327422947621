import { FC, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  leftContainer: ReactElement | string;
  rightContainer?: ReactElement;
}

const TitlePage: FC<Props> = ({ leftContainer, rightContainer }): ReactElement => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const rightContainerComp = (
    <Grid
      item
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      style={mobile ? { paddingTop: '20px' } : { paddingTop: '5px' }}
    >
      {rightContainer}
    </Grid>
  );

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        direction={mobile ? 'column' : 'row'}
        alignItems={mobile ? 'flex-start' : 'center'}
      >
        {leftContainer}
        {rightContainerComp}
      </Grid>
    </Grid>
  );
};

export default TitlePage;
